import { useNavigate } from "react-router-dom";
import { login, loginCompanies } from "../../api/services/UserServices";
import { encrypted } from "../../helpers/Common";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../store/ActionTypes";
import { postLoginLogic } from "./common";

export const INITIAL_STATE = {
  form: {
    username: "",
    password: "",
  },
  loading: false,
};
const Logic = (dispatch, state) => {
  const navigate = useNavigate();
  const { addToast } = UseAppToast();

  const actualLogin = (company_name) => {
    // Login logic duplicate in login-company Page
    login(
      async (res) => {
        await postLoginLogic(res, navigate, addToast);
      },
      (e) => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `loading`,
              value: false,
            },
          ],
        });

        addToast({
          type: "error",
          description: "username or password is incorrect",
          title: "Error",
        });
      },
      () => {},
      {
        username: state.form.username,
        password: state.form.password,
        company_name: company_name,
      }
    );
  };
  const loginHandler = () => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `loading`,
          value: true,
        },
      ],
    });

    loginCompanies(
      (res) => {
        if (res.data.response.length > 1) {
          navigate("/login-company", {
            state: {
              companies: res.data.response,
              username: state.form.username,
              password: state.form.password,
            },
            replace: false,
          });
        } else if (res.data.response.length == 1) {
          actualLogin(res.data.response[0].name);
        }
      },
      (e) => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `loading`,
              value: false,
            },
          ],
        });

        addToast({
          type: "error",
          description: "username or password is incorrect",
          title: "Error",
        });
      },
      () => {},
      {
        username: state.form.username,
        password: encrypted(state.form.password),
      }
    );
  };
  return { actions: { loginHandler } };
};

export default Logic;
