import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import AppButton from "../../../../components/general/app-button/AppButton";

export const RolesColumn = (
    actions,
    translate = (term) => {
        return term;
    }
) => {
    return [
        {
            Header: `${translate("name")}`,
            accessor: "name",
        },
        {
            Header: `${translate("description")}`,
            accessor: "description",
        },
        {
            Header: `${translate("actions")}`,
            // className: "frozen",
            // style: {
            //   textAlign: "center",
            // },
            Cell: ({ row: { values, original } }) => (
                <div style={{ display: "flex", justifyContent: "space-evenly", flex: 1 }}>
                    {/* <AppButton
            classes="buttonIcon"
            onClick={() => console.log(values)}
            value={`${translate("viewPermissions")}`}
            style={{
              // The green color
              // color: "#17c62e",
              color: "#c7c3c3",
              cursor: "not-allowed", // button not allowed
            }}
            icon={
              <FontAwesomeIcon icon={faEye} style={{ margin: "0px 5px" }} />
            }
          /> */}
                    <AppButton
                        classes="buttonIcon"
                        onClick={() => actions.navigate(`/portal/add-role/${original.id}`)}
                        value={`${translate("edit")}`}
                        style={{
                            color: original.is_admin === 1 ? "#c7c3c3" : "#2e5bff",
                        }}
                        icon={<FontAwesomeIcon icon={faEdit} style={{ margin: "0px 5px" }} />}
                        disabled={original.is_admin === 1}
                    />
                    <AppButton
                        classes="buttonIcon"
                        style={{ color: original.is_admin === 1 ? "#c7c3c3" : "#fb3737" }}
                        onClick={() => actions.delete(original.id)}
                        value={`${translate("delete")}`}
                        icon={<FontAwesomeIcon icon={faTrashAlt} style={{ margin: "0px 5px" }} />}
                        disabled={original.is_admin === 1}
                    />
                </div>
            ),
        },
    ];
};
