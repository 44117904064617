export const filterSelectStyles = {
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected && "var(--primary)",
      padding: 10,
      display: "flex",
      flex: 1,
      color: state.isSelected ? "white" : "var(--primary)",
      width: "100%",
    };
  },
  control: () => ({
    display: "flex",
    alignItems: "center",
    flex: 1,
  }),

  placeholder: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided, state) => {
    return { ...provided, color: "var(--primary)", padding: 5 };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  singleValue: (provided, state) => {
    return { ...provided, color: "var(--primary)" };
  },
  valueContainer: (provided, state) => {
    return { ...provided, padding: "0px" };
  },
  input: (provided, state) => {
    return {
      ...provided,
      margin: "0px",
      paddingBottom: "0px",
      paddingtop: "0px",
    };
  },
};
