import React, { useEffect, useState } from "react";
import styles from "./ChatThread.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import AttachmentIcon from "../../analytics/action-plan-new/action-plan-list/components/AttachmentIcon/AttachmentIcon";
import { useTranslation } from "react-i18next";

const ChatThread = ({ followup, onSendMessage, messagesLoading = false }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState([]);

  const handleSendMessage = () => {
    if (message.trim() || attachments.length > 0) {
      onSendMessage({ message, attachments });
      setMessage("");
      setAttachments([]);
    }
  };

  const handleAttachmentChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments((prev) => [...prev, ...files]);
  };

  const removeAttachment = (indexToRemove) => {
    setAttachments(attachments.filter((_, index) => index !== indexToRemove));
  };

  const getAttachmentURL = (attachment) => {
    if (attachment instanceof File || attachment instanceof Blob) {
      return URL.createObjectURL(attachment);
    }
    return attachment.url;
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const messagesEndRef = React.useRef(null);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [followup, messagesLoading]);

  return (
    <div className={styles.chatThreadContainer}>
      <div className={styles.chatThread}>
        {followup.length > 0 ? (
          followup.toReversed().map((entry, index) => (
            <>
              <div
                key={index}
                className={`${styles.chatEntry} ${
                  entry.type === "customer" ? styles.agent : styles.customer
                } ${entry.is_internal ? styles.internal : ""}`}
              >
                <div className={styles.chatMessage}>
                  <span className={styles.massegerName}>
                    {entry.updated_by}
                  </span>
                  <div>
                    <p>{entry.message}</p>
                  </div>
                  <div style={{ display: "flex" }}>
                    {entry.attachments &&
                      entry.attachments.map((attachment, i) => (
                        <div key={i} className={styles.chatAttachment}>
                          {attachment.name.endsWith(".jpg") ||
                          attachment.name.endsWith(".png") ||
                          attachment.name.endsWith(".jpeg") ? (
                            <img
                              src={getAttachmentURL(attachment)}
                              alt="attachment"
                              target="_blank"
                              className={styles.attachmentPreviewImage}
                            />
                          ) : (
                            <a
                              href={getAttachmentURL(attachment)}
                              download
                              target="_blank"
                              className={styles.pdfContainer}
                              rel="noreferrer"
                            >
                              {attachment.name.substring(
                                attachment.name.lastIndexOf(".") + 1
                              )}
                            </a>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
                <div className={styles.chatTimestamp}>
                  <small>{new Date(entry.created_at).toLocaleString()}</small>
                </div>
              </div>
            </>
          ))
        ) : (
          <div className={styles.message}>{t("noMessages")}</div>
        )}
        <div ref={messagesEndRef} />
      </div>
      {attachments.length > 0 && (
        <div className={styles.attachmentsPreview}>
          {attachments.map((attachment, index) => (
            <div key={index} className={styles.attachmentPreview}>
              <AttachmentIcon
                onDelete={() => {
                  removeAttachment(index);
                }}
                attachment={attachment}
              />
            </div>
          ))}
        </div>
      )}
      <div className={styles.chatInputArea}>
        <input
          id="attachment-input"
          type="file"
          multiple
          onChange={handleAttachmentChange}
          style={{ display: "none" }}
        />
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={t("typeAMesseages")}
          className={styles.chatInput}
          onKeyDown={handleKeyPress}
        />
        <button
          onClick={handleSendMessage}
          className={styles.chatSendButton}
          disabled={!(message.trim() || attachments.length > 0)}
        >
          {messagesLoading ? t("Sending") : t("send")}
        </button>
        <label htmlFor="attachment-input" className={styles.attachmentIcon}>
          <FontAwesomeIcon icon={faPaperclip} color="white" />
        </label>
      </div>
    </div>
  );
};

export default ChatThread;
