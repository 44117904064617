import AppButton from "../../../../components/general/app-button/AppButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/free-regular-svg-icons";

export const LocationColumn = (
    actions,
    translate = (term) => {
        return term;
    }
) => {
    return [
        {
            Header: `${translate("name")}`,
            accessor: "name",
        },
        {
            Header: `${translate("fullAddress")}`,
            accessor: "address",
        },
        {
            Header: `${translate("Area")}`,
            accessor: "area",
        },
        {
            Header: `${translate("actions")}`,
            // className: "frozen",
            style: {
                textAlign: "center",
            },
            Cell: ({ row: { values, original } }) => (
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    {console.log("location row", original)}
                    <AppButton
                        classes="buttonIcon"
                        onClick={() => actions.navigate(`/portal/add-location/${original.id}`)}
                        value={translate("edit")}
                        style={{
                            color: "#2e5bff",
                        }}
                        icon={<FontAwesomeIcon icon={faEdit} style={{ margin: "0px 5px" }} />}
                    />
                    <AppButton
                        classes="buttonIcon"
                        onClick={() =>
                            actions.navigate(`/portal/add-location/${original.id}?duplicate=true`)
                        }
                        value={translate("duplicate")}
                        style={{
                            color: "#2e5bff",
                        }}
                        icon={<FontAwesomeIcon icon={faClone} style={{ margin: "0px 5px" }} />}
                    />
                    <AppButton
                        classes="buttonIcon"
                        onClick={() => actions.bulkDelete([original])}
                        style={{ color: "#fb3737" }}
                        value={translate("delete")}
                        icon={<FontAwesomeIcon icon={faTrashAlt} style={{ margin: "0px 5px" }} />}
                    />
                </div>
            ),
        },
    ];
};
