import { useEffect, useRef } from "react";
import {
    fetchAuditsStats,
    fetchActiveAuditors,
    fetchActiveLocations,
    fetchAuditorsActivity,
    fetchAvgScoresByAudit,
    fetchAvgScoresByLocation,
    fetchHighScores,
    fetchLowScores,
    fetchActionPlanStats,
} from "../../../api/services/AnalyticsServices";
import { fetchAllAuditTemplates } from "../../../api/services/AuditServices";
import { getAllDepartments } from "../../../api/services/DepartmentServices";
import { fetchAllLocations } from "../../../api/services/LocationServices";
import { fetchAllAreas } from "../../../api/services/AreaServices";
import { fetchAllUsers } from "../../../api/services/UserServices";
import { UPDATE_PROP } from "../../../store/ActionTypes";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { ERROR } from "../../../components/general/app-toast/AppToast";
import { extractErrorMessage } from "../../../helpers/Common";

const Logic = (dispatch, state) => {
    const isFirstRender = useRef(true);
    const { addToast } = UseAppToast();
    const updateLoader = (prop, value) => {
        dispatch({
            payload: [{ type: UPDATE_PROP, prop, value }],
        });
    };
    const prepareFilterObject = () => {
        let filters = {};
        if (state.dateFrom != null && state.dateFrom != undefined) {
            filters.from = state.dateFrom;
        }
        if (state.dateTo != null && state.dateTo != undefined) {
            filters.to = state.dateTo;
        }
        return filters;
    };
    const getAuditsStats = () => {
        updateLoader("loading.auditStatsLoading", true);

        fetchAuditsStats(
            { filters: state.filters },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "auditStatus.scheduled",
                            value: response.response.total_audits,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: "auditStatus.pending",
                            value: response.response.total_pending,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: "auditStatus.completed",
                            value: response.response.total_completed,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: "auditStatus.missed",
                            value: response.response.total_missed,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.auditStatsLoading", false);
            }
        );
    };

    const getActiveAuditors = () => {
        updateLoader("loading.activeAuditorLoading", true);

        fetchActiveAuditors(
            { filters: state.filters },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "general.activeAuditors",
                            value: response.response.active_auditors,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.activeAuditorLoading", false);
            }
        );
    };

    const getActiveLocations = () => {
        updateLoader("loading.activeLocationLoading", true);

        fetchActiveLocations(
            { filters: state.filters },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "general.auditedLocations",
                            value: response.response.locations_audited,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.activeLocationLoading", false);
            }
        );
    };

    const getActionPlanStats = () => {
        updateLoader("loading.actionPlanStatsLoading", true);

        fetchActionPlanStats(
            { filters: state.filters },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "actionPlans.total",
                            value: response.response.total_actions,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: "actionPlans.open",
                            value: response.response.total_open,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: "actionPlans.closed",
                            value: response.response.total_closed,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: "actionPlans.overdue",
                            value: response.response.total_overdue,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.actionPlanStatsLoading", false);
            }
        );
    };

    const getAuditorsActivity = () => {
        updateLoader("loading.auiditorActivityLoading", true);

        fetchAuditorsActivity(
            { filters: state.filters },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "auiditorActivity",
                            value: response.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.auiditorActivityLoading", false);
            }
        );
    };

    const getAvgScoresByAudit = () => {
        updateLoader("loading.checklistAvgScoreLoading", true);

        fetchAvgScoresByAudit(
            { filters: state.filters },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "checklistAvgScore",
                            value: response.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.checklistAvgScoreLoading", false);
            }
        );
    };

    const getAvgScoresByLocation = () => {
        updateLoader("loading.locationAvgScoreLoading", true);

        fetchAvgScoresByLocation(
            { filters: state.filters },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "locationAvgScore",
                            value: response.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.locationAvgScoreLoading", false);
            }
        );
    };

    const getHighScores = () => {
        updateLoader("loading.highScoresLoading", true);

        fetchHighScores(
            { filters: state.filters },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "highScores",
                            value: response.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.highScoresLoading", false);
            }
        );
    };

    const getLowScores = () => {
        updateLoader("loading.lowScoresLoading", true);

        fetchLowScores(
            { filters: state.filters },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "lowScores",
                            value: response.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.lowScoresLoading", false);
            }
        );
    };

    const getAllData = () => {
        // fetch audit status
        getAuditsStats();
        // fetch general active
        getActiveAuditors();
        getActiveLocations();
        // fetch action plan stats
        getActionPlanStats();
        // fetch auditor activity
        getAuditorsActivity();
        // fetch check list avg score
        getAvgScoresByAudit();
        // fetch location svg score
        getAvgScoresByLocation();
        // fetch highest scores
        getHighScores();
        // fetch low scores
        getLowScores();
    };

    const filterData = () => {
        //in case of multiple values for a filter we need to use map

        const auditIds = state.selectedAudit == null ? [] : state.selectedAudit.map((e) => e.value);
        const locIds =
            state.selectedLocation == null ? [] : state.selectedLocation.map((e) => e.value);
        const auditroIds =
            state.selectedAuditor == null ? [] : state.selectedAuditor.map((e) => e.value);
        const areaIds = state.selectedArea == null ? [] : state.selectedArea.map((e) => e.value);
        const depIds =
            state.selectedDepartment == null ? [] : state.selectedDepartment.map((e) => e.value);

        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `filters.audit_ids`,
                    value: auditIds,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.department_ids`,
                    value: depIds,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.location_ids`,
                    value: locIds,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.area_ids`,
                    value: areaIds,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.auditor_ids`,
                    value: auditroIds,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.from`,
                    value: state.dateFrom,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.to`,
                    value: state.dateTo,
                },
            ],
        });
    };
    useEffect(() => {
        filterData();
        getAllDepartments(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `departments`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
        fetchAllAreas(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `area`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
        fetchAllLocations(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `locations`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
        fetchAllUsers(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `auditors`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
        fetchAllAuditTemplates(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `audits`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
    }, []);
    useEffect(() => {
        // skipping first render to give filter data a chnace to get filled. Then trigger API calls.
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        getAllData();
    }, [state.filters]);

    return { actions: { filterData } };
};

export default Logic;
