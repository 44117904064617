import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import styles from "./Success.module.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Spacer from "../../components/general/spacer/Spacer";
const MessagesPage = ({ navigation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loc = useLocation();
  useEffect(() => {
    if (loc?.state?.nextNavigation) {
      setTimeout(() => {
        navigate(loc?.state?.nextNavigation);
      }, 5000);
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.header}>
          <Logo classes={styles.logoSize} />

          <div className={styles.slogan}>{t("slogan")}</div>
          <Spacer height={50} />
          <div
            className={styles.slogan}
            style={{
              textAlign: "center",
              borderTop: "3px solid white",
              borderBottom: "3px solid white",
              padding: " 50px 0px",
              fontFamily: "RubikLight",
            }}
          >
            {/* Thank you for your subscription request! We’ve received it and will
            be in touch shortly. */}
            {loc?.state?.message}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MessagesPage;
