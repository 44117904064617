import { $securedAxios } from "../axios";
const addPageNPageSizeToURL = (url, pageSize, page) => {
    if (pageSize) {
        url = url + `?pageSize=${pageSize}`;
    }
    if (page) {
        url = url.includes("?") ? url + `&page=${page}` : url + `?page=${page}`;
    }
    return url;
};

export const getStackedBarChart = (payload, onSuccess, onError, onFinally) => {
    let url = `/audit/location/percentage`;
    // // url = addPageNPageSizeToURL(url, payload.pageSize, payload.page);
    $securedAxios
        .post(url, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const getLineChart = (payload, onSuccess, onError, onFinally) => {
    let url = `/audit/location/percentage/by/date`;
    // // url = addPageNPageSizeToURL(url, payload.pageSize, payload.page);
    $securedAxios
        .post(url, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const getHeatMap = (payload, onSuccess, onError, onFinally) => {
    let url = `/audit/heatmap/percentage`;
    // // url = addPageNPageSizeToURL(url, payload.pageSize, payload.page);
    $securedAxios
        .post(url, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};