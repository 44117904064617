import { useTranslation } from "react-i18next";
import AppButton from "../app-button/AppButton";
import AppModal from "../app-modal/AppModal";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";

const LoadingModal = ({ show, onHide, onSave, message }) => {
    const { t } = useTranslation();
    return (
        <AppModal
            show={show}
            onHide={onHide}
            size="sm"
            bodyStyle={{ paddingTop: 0 }}
            footerStyle={{ border: 0, paddingTop: 0 }}
            headerStyle={{ border: 0, paddingBottom: 0 }}
        >
            <LoadingSpinner isLoading={show} />
        </AppModal>
    );
};

export default LoadingModal;
