import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { fetchAllAuditTemplates } from "../../../../api/services/AuditServices";
import { getAllDepartments } from "../../../../api/services/DepartmentServices";
import { fetchAllLocations } from "../../../../api/services/LocationServices";
import {
    fetchAllPermissions,
    fetchSpecificPermission,
    updateOrCreatePermission,
} from "../../../../api/services/PermissionServices";
import { ERROR } from "../../../../components/general/app-toast/AppToast";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
import { extractErrorMessage } from "../../../../helpers/Common";

export const INITIAL_STATE = {
    form: {
        name: "",
        selectedPermissionType: "Group",
        groupsChecked: [],
        featuresChecked: {
            USERS: false,
            AUDITS: false,
            SCHEDULE: false,
            ANALYTICS: false,
            MASTER_DATA: false,
            ACTION_PLANS: false,
            AUDIT_TEMPLATES: false,
            ROLES_PERMISSIONS: false,
            APP: false,
            PORTAL: false,
        },
        selectedDepartment: [],
        locationsChecked: [],
        checklistChecked: [],
    },
    featuresList: [
        // { id: 4, name: "Admin" },
        // { id: 1, name: "Portal" },
        // { id: 2, name: "App" },
        // { id: 3, name: "Analytics" },
        { label: "App Access", value: "APP" },
        { label: "Portal Access", value: "PORTAL" },
        { label: "Audits", value: "AUDITS" },
        { label: "Schedule", value: "SCHEDULE" },
        { label: "Analytics", value: "ANALYTICS" },
        { label: "Users", value: "USERS" },
        { label: "Master Data", value: "MASTER_DATA" },
        { label: "Audit Templates", value: "AUDIT_TEMPLATES" },
        { label: "Action Plan", value: "ACTION_PLANS" },
        { label: "Roles & Permissions", value: "ROLES_PERMISSIONS" },
    ],
    departmentsList: [],
    groupsList: [],
    locationsList: [],
    checklistList: [],
    loading: false,
    saveLoading: false,
    // admin
};
const Logic = (dispatch, state) => {
    const { addToast } = UseAppToast();
    const { id } = useParams();
    let navigate = useNavigate();

    const handleCheck = (event, prop, arr) => {
        debugger;
        if (prop === "form.featuresChecked") {
            // copy the object
            let obj = { ...arr };
            console.log(obj);
            obj[event.target.id] = event.target.checked;

            return obj;

            // return arr;
        } else {
            if (arr.includes(event.target.id)) {
                return arr.filter((item) => item !== event.target.id);
            } else {
                return [...arr, event.target.id];
            }
        }
    };
    useEffect(() => {
        if (id) {
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: `loading`,
                        value: true,
                    },
                ],
            });
            fetchSpecificPermission(
                (res) => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `form.name`,
                                value: res.data.response.name,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.selectedPermissionType`,
                                value: res.data.response.type,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.groupsChecked`,
                                value: res.data.response.groups,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.featuresChecked`,
                                value: res.data.response.features,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.selectedDepartment`,
                                value: res.data.response.data.department,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.locationsChecked`,
                                value: res.data.response.data.locations,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.checklistChecked`,
                                value: res.data.response.data.checklists,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.is_admin`,
                                value: res.data.response.is_admin,
                            },
                        ],
                    });
                },
                (error) => {
                    addToast({
                        type: ERROR,
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                },
                () => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `loading`,
                                value: false,
                            },
                        ],
                    });
                },
                { id }
            );
        }
        fetchAllLocations(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `locationsList`,
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            { pageSize: 999 }
        );
        fetchAllAuditTemplates(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `checklistList`,
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            { pageSize: 999 }
        );
        getAllDepartments(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `departmentsList`,
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            { pageSize: 999 }
        );
        fetchAllPermissions(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `groupsList`,
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            { pageSize: 999 }
        );

        // if (!id) {
        //   dispatch({
        //     payload: [
        //       {
        //         type: UPDATE_PROP,
        //         prop: `form.featuresChecked`,
        //         value: INITIAL_STATE.form.featuresChecked,
        //       },
        //     ],
        //   });
        // }
    }, []);
    const AddPermission = () => {
        let data = {
            groups: [],
            data: {
                department: null,
                locations: [],
                checklists: [],
            },
            features: [],
        };
        if (state.form.selectedPermissionType === "Group") {
            data = { ...data, groups: state.form.groupsChecked };
        }
        if (state.form.selectedPermissionType === "Feature") {
            data = { ...data, features: state.form.featuresChecked };
        }
        if (state.form.selectedPermissionType === "Data") {
            data = {
                ...data,
                data: {
                    department: state.form.selectedDepartment,
                    locations: state.form.locationsChecked,
                    checklists: state.form.checklistChecked,
                },
            };
        }

        if (id) {
            data = { ...data, id: id };
        }
        console.log(data);
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "saveLoading",
                    value: true,
                },
            ],
        });
        updateOrCreatePermission(
            (res) => {
                addToast({
                    type: "success",
                    description: `${id ? "Update" : "Add"} Permission Successfully`,
                    title: "Success",
                });
                // console.log(INITIAL_STATE.form);
                // empty form
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "form",
                            value: INITIAL_STATE.form,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: "saveLoading",
                            value: false,
                        },
                        // {
                        //   // checked gr
                        // }
                    ],
                });
                navigate("/portal/permissions");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "saveLoading",
                            value: false,
                        },
                    ],
                });
            },

            (f) => {},
            {
                name: state.form.name,
                type: state.form.selectedPermissionType,
                row: "test",
                ...data,
            }
        );
    };

    return { actions: { handleCheck, AddPermission }, navigate };
};

export default Logic;
