import { useNavigate } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import styles from "./Splash.module.css";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetchLoggedinUser } from "../../api/services/UserServices";
import { AUTHENTICATION_TOKEN, LANGUAGE } from "../../helpers/constants/StaticKeys";
import { setUserToLS } from "../../helpers/Storage";
const Splash = ({ navigation }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            /* Check if Token is available. If not navigate to login */
            let token = localStorage.getItem(AUTHENTICATION_TOKEN);
            let language = localStorage.getItem(LANGUAGE);
            if (!language) {
                localStorage.setItem(LANGUAGE, "en");
            }
            if (!token) {
                navigate("/login");
            } else {
                fetchLoggedinUser(
                    async (res) => {
                        setUserToLS(res.data.response);
                        console.log(res.data.response);
                        // check subscription, if days left is less 0, if the usr is admin, navigate to subscription page
                        if (
                            res.data.response.subscription.days_left < 1 ||
                            res.data.response.subscription.status != "active"
                        ) {
                            if (res.data.response.is_admin === 1) {
                                navigate("/admin/subscriptions");
                            } else {
                                navigate("/success", {
                                    state: {
                                        message:
                                            "Your subscription has expired. Please contact your admin to renew your subscription.",
                                    },
                                });
                            }
                        } else {
                            navigate("/portal");
                        }
                    },
                    (e) => {},
                    () => {}
                );
            }
        }, 1000);
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <div className={styles.header}>
                    <Logo classes={styles.logoSize} />
                    {/* <div className={styles.logo}>GAWDA</div> */}
                    <div className={styles.slogan}>{t("slogan")}</div>
                </div>
            </div>
        </div>
    );
};
export default Splash;
