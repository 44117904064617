import { FILTER_TYPES } from "../../../components/general/simple-table/constants";

export const INITIAL_STATE = {
    departments: { data: [] },
    selectedDepartment: null,
    audits: { data: [] },
    selectedAudit: null,
    auditors: { data: [] },
    selectedAuditor: null,
    locations: { data: [] },
    area: { data: [] },
    selectedArea: null,
    selectedLocation: null,
    stackedBarChartLoading: true,
    lineChartLoading: true,
    heatMapLoading: true,
    getLineChart: {},
    getStackedBarChart: {},
    getHeatChart: {},
    filters: {
        from: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString(
            "fr-CA"
        ),
        to: new Date(new Date().getFullYear(), new Date().getMonth(), 0).toLocaleDateString(
            "fr-CA"
        ),
    },
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString(
        "fr-CA"
    ),
    dateTo: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString(
        "fr-CA"
    ),
    stackedBarChart: {
        series: [],
        options: {
            chart: {
                type: "bar",
                height: 400,
                stacked: true,
                toolbar: {
                    show: true,
                },
                zoom: {
                    enabled: true,
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: "bottom",
                            offsetX: -10,
                            offsetY: 0,
                        },
                    },
                },
            ],
            plotOptions: {
                bar: {
                    horizontal: true,
                    // horizontal: false,
                    borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: "13px",
                                fontWeight: 900,
                            },
                        },
                    },
                },
            },
            xaxis: {
                type: "category",
                categories: [],
                tickPlacement: "on",
                max: 100,
                title: {
                    text: "% Perfomance",
                },
            },
            yaxis: {
                title: {
                    text: "Score",
                },
            },
            legend: {
                position: "right",
                offsetY: 40,
            },
            fill: {
                opacity: 1,
            },
        },
    },
    // Line Chart
    lineChart: {
        series: [],
        options: {
            chart: {
                height: 350,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: true,
                },
                zoom: {
                    enabled: true,
                },
            },

            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: "smooth",
            },
            // title: {
            //     text: "Average High & Low Temperature",
            //     align: "left",
            // },
            grid: {
                borderColor: "#e7e7e7",
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5,
                },
            },
            markers: {
                size: 1,
            },
            xaxis: {
                type: "category",
                categories: [],
                title: {
                    text: "Month",
                },
            },
            yaxis: {
                title: {
                    text: "Avg Score",
                },
            },
            legend: {
                position: "right",
                // horizontalAlign: "right",
                // floating: true,
                // offsetY: -25,
                // offsetX: -5,
            },
        },
    },
    // Heatmap
    heatMap: {
        series: [],
        options: {
            chart: {
                height: 350,
                type: "heatmap",
            },
            plotOptions: {
                heatmap: {
                    shadeIntensity: 0.5,
                    radius: 0,
                    colorScale: {
                        ranges: [
                            {
                                from: 0,
                                to: 30,
                                name: "Below Avg",
                                color: "#FF0000",
                            },
                            {
                                from: 30,
                                to: 60,
                                name: "Avg",
                                color: "#FFB200",
                            },
                            {
                                from: 60,
                                to: 90,
                                name: "Good",

                                color: "#128FD9",
                            },
                            {
                                from: 90,
                                to: 100,
                                name: "Excellent",

                                color: "#00A100",
                            },
                        ],
                    },
                },
            },
            dataLabels: {
                enabled: true,
            },
            xaxis: {
                type: "category",
                categories: [],
                position: "top",
            },
        },
    },
};

export const filterSelectStyles = {
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: state.isSelected && "var(--primary)",
            padding: 10,
            display: "flex",
            flex: 1,
            color: state.isSelected ? "white" : "var(--primary)",
        };
    },
    control: () => ({
        display: "flex",
        alignItems: "center",
        // border: "1px solid var(--primary)",
        height: "100%",
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        overflow: "hidden",
        flex: 1,
    }),
    container: (provided, state) => {
        return { ...provided, display: "flex", flex: 1, height: "100%" };
    },
    placeholder: () => ({
        display: "none",
    }),
    dropdownIndicator: (provided, state) => {
        return { ...provided, color: "var(--primary)", padding: 5 };
    },
    indicatorSeparator: () => ({
        display: "none",
    }),
    singleValue: (provided, state) => {
        return { ...provided, color: "var(--primary)" };
    },
};
