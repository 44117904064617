import { FILTER_TYPES } from "../../../components/general/simple-table/constants";

export const filterSelectStyles = {
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: state.isSelected && "var(--primary)",
            padding: 10,
            display: "flex",
            flex: 1,
            color: state.isSelected ? "white" : "var(--primary)",
        };
    },
    control: () => ({
        display: "flex",
        alignItems: "center",
        // border: "1px solid var(--primary)",
        height: "100%",
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        overflow: "hidden",
        flex: 1,
    }),
    container: (provided, state) => {
        return { ...provided, display: "flex", flex: 1, height: "100%" };
    },
    placeholder: () => ({
        display: "none",
    }),
    dropdownIndicator: (provided, state) => {
        return { ...provided, color: "var(--primary)", padding: 5 };
    },
    indicatorSeparator: () => ({
        display: "none",
    }),
    singleValue: (provided, state) => {
        return { ...provided, color: "var(--primary)" };
    },
};

export const auditorActivityColumns = [
    {
        id: "name",
        header: "Name",
        accessorKey: "auditor_id",
        type: FILTER_TYPES.TEXT,
    },
    {
        id: "scheduled",
        header: "#Scheduled",
        accessorKey: "total_audits",
        type: FILTER_TYPES.NUMBER,
    },
    {
        id: "pending",
        header: "#Pending",
        accessorKey: "total_pending",
        type: FILTER_TYPES.NUMBER,
    },
    {
        id: "completed",
        header: "#Completed",
        accessorKey: "total_completed",
        type: FILTER_TYPES.NUMBER,
    },
    {
        id: "missed",
        header: "#Missed",
        accessorKey: "total_missed",
        type: FILTER_TYPES.NUMBER,
    },
    {
        id: "efficiency",
        header: "% Efficiency",
        accessorKey: "efficiency",
        type: FILTER_TYPES.NUMBER,
    },
];

export const checklistAvgScoreColumns = [
    {
        id: "name",
        header: "Name",
        accessorKey: "name",
        type: FILTER_TYPES.TEXT,
    },
    {
        id: "audits",
        header: "#Audits",
        accessorKey: "total_audits",
        type: FILTER_TYPES.NUMBER,
    },
    {
        id: "score",
        header: "Avg Score",
        accessorKey: "average_score",
        type: FILTER_TYPES.NUMBER,
    },
];

export const locationAvgScoreColumns = [
    {
        id: "name",
        header: "Name",
        accessorKey: "location_name",
        type: FILTER_TYPES.TEXT,
    },
    {
        id: "audits",
        header: "#Audits",
        accessorKey: "total_audits",
        type: FILTER_TYPES.NUMBER,
    },
    {
        id: "score",
        header: "Avg Score",
        accessorKey: "average_score",
        type: FILTER_TYPES.NUMBER,
    },
];

export const highScoreColumns = [
    {
        id: "date",
        header: "Date",
        accessorKey: "date",
        type: FILTER_TYPES.DATE,
    },
    {
        id: "location",
        header: "Location",
        accessorKey: "location_name",
        type: FILTER_TYPES.TEXT,
    },
    {
        id: "audit",
        header: "Audit",
        accessorKey: "audit_name",
        type: FILTER_TYPES.TEXT,
    },
    {
        id: "auditor",
        header: "Auditor",
        accessorKey: "auditor_name",
        type: FILTER_TYPES.TEXT,
    },
    {
        id: "score",
        header: "Score",
        accessorKey: "highest_score",
        type: FILTER_TYPES.NUMBER,
    },
];

export const lowScoreColumns = [
    {
        id: "date",
        header: "Date",
        accessorKey: "date",
        type: FILTER_TYPES.DATE,
    },
    {
        id: "location",
        header: "Location",
        accessorKey: "location_name",
        type: FILTER_TYPES.TEXT,
    },
    {
        id: "audit",
        header: "Audit",
        accessorKey: "audit_name",
        type: FILTER_TYPES.TEXT,
    },
    {
        id: "auditor",
        header: "Auditor",
        accessorKey: "auditor_name",
        type: FILTER_TYPES.TEXT,
    },
    {
        id: "score",
        header: "Score",
        accessorKey: "lowest_score",
        type: FILTER_TYPES.NUMBER,
    },
];

export const auditorActivityMockData = [
    { name: "Jhon Doe", scheduled: 50, pending: 50, completed: 50, missed: 50, efficiency: 50 },
    {
        name: "Jane Smith",
        scheduled: 60,
        pending: 40,
        completed: 70,
        missed: 30,
        efficiency: 65,
    },
    {
        name: "Alice Johnson",
        scheduled: 45,
        pending: 55,
        completed: 60,
        missed: 40,
        efficiency: 55,
    },
    {
        name: "Bob Brown",
        scheduled: 70,
        pending: 30,
        completed: 80,
        missed: 20,
        efficiency: 75,
    },
];

export const avgScoreMockData = [
    {
        name: "Topic A",
        audits: 10,
        score: 85,
    },
    {
        name: "Topic B",
        audits: 15,
        score: 72,
    },
    {
        name: "Topic C",
        audits: 8,
        score: 91,
    },
    {
        name: "Topic D",
        audits: 12,
        score: 68,
    },
];

export const avgScoreMockData2 = [
    {
        name: "Branch A",
        audits: 20,
        score: 78,
    },
    {
        name: "Branch B",
        audits: 15,
        score: 92,
    },
    {
        name: "Branch C",
        audits: 10,
        score: 85,
    },
    {
        name: "Branch D",
        audits: 25,
        score: 70,
    },
];

export const highMockData = [
    {
        date: "2024-03-20",
        location: "Branch A",
        audit: "Topic A",
        auditor: "John Doe",
        score: 85,
    },
    {
        date: "2024-03-21",
        location: "Branch B",
        audit: "Topic B",
        auditor: "Jane Smith",
        score: 92,
    },
    {
        date: "2024-03-22",
        location: "Branch C",
        audit: "Topic C",
        auditor: "Alice Johnson",
        score: 78,
    },
    {
        date: "2024-03-23",
        location: "Branch D",
        audit: "Topic D",
        auditor: "Bob Brown",
        score: 70,
    },
];

export const lowMockData = [
    {
        date: "2024-03-20",
        location: "Branch A",
        audit: "Topic A",
        auditor: "John Doe",
        score: 30,
    },
    {
        date: "2024-03-21",
        location: "Branch B",
        audit: "Topic B",
        auditor: "Jane Smith",
        score: 42,
    },
    {
        date: "2024-03-22",
        location: "Branch C",
        audit: "Topic C",
        auditor: "Alice Johnson",
        score: 18,
    },
    {
        date: "2024-03-23",
        location: "Branch D",
        audit: "Topic D",
        auditor: "Bob Brown",
        score: 25,
    },
];

export const INITIAL_STATE = {
    // Loading collection
    loading: {
        auditStatsLoading: false,
        activeAuditorLoading: false,
        activeLocationLoading: false,
        actionPlanStatsLoading: false,
        auiditorActivityLoading: false,
        checklistAvgScoreLoading: false,
        locationAvgScoreLoading: false,
        highScoresLoading: false,
        lowScoresLoading: false,
    },
    // Filters dropdown adn selected values
    departments: { data: [] },
    selectedDepartment: null,
    audits: { data: [] },
    selectedAudit: null,
    auditors: { data: [] },
    selectedAuditor: null,
    locations: { data: [] },
    selectedLocation: null,
    area: { data: [] },
    selectedArea:null,
    filters: {},
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString(
        "fr-CA"
    ),
    dateTo: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString(
        "fr-CA"
    ),
    // Dashboard data
    auditStatus: {
        scheduled: "",
        pending: "",
        completed: "",
        missed: "",
    },
    general: {
        activeAuditors: "",
        auditedLocations: "",
    },
    actionPlans: {
        total: "",
        open: "",
        closed: "",
        overdue: "",
    },
    auiditorActivity: auditorActivityMockData,
    checklistAvgScore: avgScoreMockData,
    locationAvgScore: avgScoreMockData2,
    highScores: highMockData,
    lowScores: lowMockData,
};
