import React from "react";
import Logo from "../../logo/Logo";
import { Navbar } from "react-bootstrap";

const HeaderLeft = () => {
  return (
    <Navbar.Brand href="/" style={{ flex: 1 }}>
      <Logo />
    </Navbar.Brand>
  );
};

export default HeaderLeft;
