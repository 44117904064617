import React from "react";
import classes from "./HeaderRight.module.css";
import { RightNavLinks } from "../NavLinks";
import { HeaderNav } from "../HeaderNav";
import { Nav } from "react-bootstrap";

const HeaderRight = ({ color = "black" }) => {
  return (
    <Nav
      id="header-right"
      style={{ flex: 1, padding: "0px 10px" }}
      className="justify-content-end align-items-center"
    >
      <HeaderNav
        items={RightNavLinks(color)}
        activeClass={classes.activeLink}
        defaultClass={classes.NavLink}
      />
    </Nav>
  );
};

export default HeaderRight;
