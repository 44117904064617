import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import AppButton from "../../../components/general/app-button/AppButton";
import AppFilters from "../../../components/general/app-filters/AppFilters";
import AppTable from "../../../components/general/app-table/AppTable";
import Card from "../../../components/general/card/Card";
import ConfirmModal from "../../../components/general/confirm-modal/ConfrimModal";
import TabsLayout from "../../../components/general/tabs-layout/TabsLayout";
import Title from "../../../components/general/title/Title";
import useLogic from "../../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";

const Schedule = () => {
    const {
        state,
        updateState,
        navigate,
        tabsList,
        handleCloseDelete,
        handleDelete,
        columns,
        allColumns,
    } = useLogic({
        INITIAL_STATE,
        Logic,
    });
    const { t } = useTranslation();
    const filterData = [
        {
            key: "audit_templates.name",
            label: t("audit"),
            sign: [{ value: "contains", label: t("contains") }],
        },
        {
            key: "locations.name",
            label: t("location"),
            sign: [{ value: "contains", label: t("contains") }],
        },
        {
            key: "audits.date",
            label: t("date"),
            sign: [{ value: "is_equal", label: t("equals") }],
        },
        {
            key: "users.short_name",
            label: t("assignee"),
            sign: [{ value: "contains", label: t("contains") }],
        },

        // ... other filters
    ];

    const filterKeys = {
        "audit_templates.name": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("contains") },
                { value: "is_equal", label: t("equals") },
                // ... other signs
            ],
        },
        "locations.name": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("contains") },
                { value: "is_equal", label: t("equals") },
                // ... other signs
            ],
        },
        "audits.date": {
            type: "date",
            dropdown: false,
            sign: [
                { value: "is_equal", label: t("equals") },
                { value: "is_between", label: t("isBetween") },
                // ... other signs
            ],
        },
        "users.short_name": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("contains") },
                { value: "is_equal", label: t("equals") },
                // ... other signs
            ],
        },
    };
    return (
        <>
            <TabsLayout
                tabsList={tabsList}
                title={t("schedule")}
                showTitle={true}
                renderButton={
                    <div style={{ display: "flex" }}>
                        <AppButton
                            className={"primaryButton"}
                            style={{
                                color: "white",
                                padding: "2px 6px",
                                margin: "0px 20px 3px 20px",
                            }}
                            onClick={() => navigate("/portal/add-schedule")}
                            value={t("new")}
                            icon={<FontAwesomeIcon icon={faPlus} style={{ margin: "0px 5px" }} />}
                        />
                        {state.active.selectedRows?.length !== 0 && (
                            <AppButton
                                className={"primaryButton"}
                                style={{
                                    color: "white",
                                    padding: "2px 6px",
                                    marginBottom: 3,
                                    backgroundColor: "var(--red)",
                                    borderColor: "var(--red)",
                                }}
                                onClick={() =>
                                    updateState({
                                        prop: "showDeleteAlert",
                                        value: true,
                                    })
                                }
                                value={`Delete Selected (${state.active.selectedRows?.length})`}
                                icon={
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        style={{ marginRight: "5px" }}
                                    />
                                }
                            />
                        )}
                    </div>
                }
                setActiveLabel={(value) => {
                    const index = tabsList.findIndex((x) => x.key === value);
                    updateState({
                        prop: "active",
                        value: tabsList[index].data,
                    });
                    updateState({
                        prop: "activeIndex",
                        value: index,
                    });
                }}
                renderContent={
                    <>
                        <Title
                            renderProp={
                                <AppFilters
                                    filterData={filterData}
                                    filterKeys={filterKeys}
                                    setData={tabsList[state.activeIndex].setData}
                                    filters={tabsList[state.activeIndex].data.params.filters}
                                />
                            }
                        />
                        <Card style={{ padding: "20px 10px" }}>
                            <AppTable
                                columns={
                                    tabsList[state.activeIndex].key == "all" ? allColumns : columns
                                }
                                data={tabsList[state.activeIndex].data}
                                setData={tabsList[state.activeIndex].setData}
                                error={tabsList[state.activeIndex].error}
                                showCheckbox
                            />
                        </Card>
                    </>
                }
            />
            <ConfirmModal
                show={state.showDeleteAlert}
                onHide={() => handleCloseDelete()}
                onSave={() => handleDelete()}
                isLoading={state.deleteLoading}
            />
        </>
    );
};
export default Schedule;
