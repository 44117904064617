import { $axios, $securedAxios } from "../axios";

export const getAllTickets = (onSuccess, onError, onFinally, params) => {
  $securedAxios
    .post(`tickets/get/settings?page=${params.page}`, {
      filters: params.filters,
    })
    .then((response) => {
      onSuccess(response.data.response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const getTicketDetails = (onSuccess, onError, onFinally, payload) => {
  $securedAxios

    .post("tickets/specific", payload)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
      console.log(payload);
    });
};
export const createTicket = (onSuccess, onError, onFinally, payload) => {
  $securedAxios

    .post("tickets/store", payload)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
      console.log(payload);
    });
};
export const getTicketThread = (onSuccess, onError, onFinally, payload) => {
  $securedAxios

    .post(`/ticket/activities/details?pageSize=${999}&page=${1}`, payload)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
      console.log(payload);
    });
};
export const sendTicketMessage = (onSuccess, onError, onFinally, payload) => {
  $securedAxios

    .post(`ticket/activities/store`, payload)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
      console.log(payload);
    });
};
export const fetchTicketsSummery = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post("tickets/get/settings/counts")
    .then((response) => {
      onSuccess({ ...response.data.response });
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const ticketsSummeryForSpecificCompany = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  $securedAxios
    .post("tickets/summary", payload)
    .then((response) => {
      onSuccess({ ...response.data.response });
      //       console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const deleteTickets = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/tickets/delete`, {
      ids: payload.ids,
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
