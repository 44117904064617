import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSort } from "@fortawesome/free-solid-svg-icons";
import AppButton from "../../../../../../../../components/general/app-button/AppButton";
import styles from "./Question.module.css";
import globalLogic from "../../../../globalLogic";
import React, { useState } from "react";
import AppTooltip from "../../../../../../../../components/general/app-tooltip/AppTooltip";
import ElementMaker from "../element-maker/ElementMaker";
import { UPDATE_PROP } from "../../../../../../../../store/ActionTypes";
import AppInput from "../../../../../../../../components/general/app-input/AppInput";
// import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
// import { faClone } from "@fortawesome/free-regular-svg-icons";

const Question = ({
  itemInfo,
  index,
  sectionindex,
  subsectionindex,
  sectionId,
  subSectionId,
}) => {
  const {
    state,
    deleteQuestion,
    setSelectedQuestion,
    updateQuestion,
    duplicateQuestion,
  } = globalLogic();

  console.log("======question==============================");
  console.log(state);

  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(itemInfo.name);

  const handleUpdateTask = (e) => {
    let text = e.target.value;
    if (e.target.value.trim().length < 1) {
      // text = value;
      setNewValue("");
    }
    setShowInput(false);
    updateQuestion([
      {
        type: UPDATE_PROP,
        prop: `name`,
        value: text,
      },
    ]);
  };

  return (
    <>
   
      {/* question */}
      <h1>{showInput}</h1>
      <div
       onClick={() => {
        setShowInput(true)
        setSelectedQuestion({
          ...itemInfo,
          sectionindex,
          subsectionindex,
          questionIndex: index,
          sectionId,
          subSectionId,
        })
      }}
       
        className={styles.question}
        style={{
          backgroundColor:
            state.selectedQuestion?.id === itemInfo.id && "#ebebeb",
        }}
      >
        <div className={styles.questionText}>
          <FontAwesomeIcon
            icon={faSort}
            color={"var(--lightGrey)"}
            className="column-drag-handle-question"
          />
          <div className={styles.questionAnswer}>
            <div
              className={styles.title}
              onBlur={() => setShowInput(false)}
              // onClick={() => {
              //   {
              //     // setSelectedQuestion({
              //     //   ...itemInfo,
              //     //   sectionindex,
              //     //   subsectionindex,
              //     //   questionIndex: index,
              //     //   sectionId,
              //     //   subSectionId,
              //     // });
              //   }
              // }}
            >
              {/* <ElementMaker
                value={itemInfo.name}
                handleChange={(text) =>
                  updateQuestion([
                    {
                      type: UPDATE_PROP,
                      prop: `name`,
                      value: text,
                    },
                  ])
                }
              /> */}

              <span>
                {showInput ? (
                  <AppInput
                    containerStyle={{
                      flexDirection: "row-reverse",
                      alignItems: "baseline",
                    }}
                    containerClassName={styles.inputContainer}
                    type="text"
                    className={styles.input}
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                   
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleUpdateTask(event);
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <span
                    onClick={() => setShowInput(true)}
                    style={{
                      display: "flex",
                      width:"100%" ,
                      
                    }}
                  >
                    {newValue}
                  </span>
                )}
              </span>
            </div>
            <div className={styles.response}>
              {itemInfo.type == "checkbox" || itemInfo.type == "radio" ? (
                itemInfo.response.map(
                  (item, index) =>
                    item.label && (
                      <div
                        style={{
                          backgroundColor: item.color,
                          borderColor: item.color,
                          borderRadius: 10,
                          borderWidth: 1,
                          margin: "7px",
                          lineHeight: 1,
                          padding: "1px 5px",
                          fontSize: "14px",
                          color: "#fff",
                        }}
                        key={index}
                      >
                        {item.label}
                      </div>
                    )
                )
              ) : itemInfo.type ? (
                <div
                  style={{
                    backgroundColor: "var(--primary)",
                    borderColor: "var(--primary)",
                    borderRadius: 10,
                    borderWidth: 1,
                    margin: "7px",
                    lineHeight: 1,
                    padding: "1px 5px",
                    color: "#fff",
                    fontSize: "14px",
                  }}
                  key={index}
                >
                  {itemInfo.type}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <AppTooltip
          placement="bottom-end"
          buttonValue={
            <FontAwesomeIcon
              icon={faEllipsisV}
              style={{ alignSelf: "center", color: "grey" }}
            />
          }
          buttonStyle={{ padding: "2px 3px", fontSize: 15 }}
          renderProp={({ setShow }) => (
            // <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div className={styles.tooltipContent}>
              <AppButton
                className="buttonIcon"
                onClick={() => {
                  duplicateQuestion(sectionindex, subsectionindex, index);
                  setShow(false);
                }}
                style={{
                  borderRadius: 0,
                  //   color: "black",
                  color: "#2e5bff",
                }}
                value="Duplicate"
              />

              <AppButton
                className="buttonIcon"
                onClick={() => {
                  deleteQuestion(sectionindex, subsectionindex, index);
                  setShow(false);
                }}
                style={{
                  borderRadius: 0,
                  //   color: "black",
                  color: "#fb3737",
                }}
                value="Delete"
              />
            </div>
          )}
        />
      </div>
      {/* end question */}
    </>
  );
};

export default Question;
