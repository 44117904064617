import { Spinner } from "react-bootstrap";
import Styles from "./LoadingSpinner.module.css";
import { useTranslation } from "react-i18next";
const LoadingSpinner = ({ isLoading, error, children, isOpacity, ...props }) => {
    const { t } = useTranslation();
    return (
        <>
            {isLoading ? (
                <>
                    <div className={Styles.container} {...props}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">{t("loading")}</span>
                        </Spinner>
                    </div>
                    {isOpacity && children}
                </>
            ) : error ? (
                <div className={Styles.container}>{error}</div>
            ) : (
                children
            )}
        </>
    );
};
export default LoadingSpinner;
