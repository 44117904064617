import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import ConfirmModal from "../../../../components/general/confirm-modal/ConfrimModal";
import TableLayout from "../../../../components/general/table-layout/TableLayout";
import useLogic from "../../../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";
const Permissions = () => {
  const { t } = useTranslation();
  const filterData = [
    {
      key: "name",
      label: t("name"),
      sign: [{ value: "contains", label: t("contains") }],
    },
    {
      key: "type",
      label: t("type"),
      sign: [{ value: "contains", label: t("contains") }],
    },

    // ... other filters
  ];

  const filterKeys = {
    name: {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: t("contains") },
        { value: "is_equal", label: t("equals") },
        // ... other signs
      ],
    },
    type: {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: t("contains") },
        { value: "is_equal", label: t("equals") },
        // ... other signs
      ],
    },
  };
  const {
    state,
    navigate,
    columns,
    data,
    setData,
    error,
    HandleCloseDelete,
    HandelDelete,
  } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  return (
    <>
      {console.log("data", data)}
      <TableLayout
        tableColumns={columns}
        setData={setData}
        title={t("permissions")}
        showButton={true}
        data={data}
        error={error}
        buttonValue={t("new")}
        buttonIcon={
          <FontAwesomeIcon icon={faPlus} style={{ margin: "0px 5px" }} />
        }
        buttonOnClick={() => navigate("/portal/add-permission")}
        buttonStyle={{ color: "white" }}
        buttonClassName="primaryButton"
        filterData={{ filterData }}
        filterKeys={{ filterKeys }}
        showFilter={true}
      />
      {state.showDeleteAlert && (
        <ConfirmModal
          show={state.showDeleteAlert}
          onHide={() => HandleCloseDelete()}
          onSave={() => HandelDelete()}
          isLoading={state.deleteLoading}
        />
      )}
    </>
  );
};
export default Permissions;
