import styles from "./ResetPassword.module.css";
import AppInput from "../../components/general/app-input/AppInput";
import AppButton from "../../components/general/app-button/AppButton";
import Logo from "../../components/logo/Logo";
import Logic, { INITIAL_STATE } from "./logic";
import useLogic from "../../helpers/hooks/use-logic";
import useValidator from "../../helpers/hooks/use-validator";
import ValidationProvider from "../../components/general/validation-provider/ValidationProvider";

const ResetPassword = () => {
    const { state, updateState, resetHandler } = useLogic({
        INITIAL_STATE,
        Logic,
    });
    const { email, invalid } = state;
    const { errors, onBlur, onChange, handleSubmit } = useValidator({
        defaultValues: {
            ...state.form,
        },
        setValue: (nextFormState) => {
            updateState({
                prop: "form",
                value: nextFormState,
            });
        },
        onSubmit: resetHandler,
        validations: {
            password: {
                required: true,
                validation_name: "Password",
                minLength: 6,
            },
            confirm_password: {
                required: true,
                exact: "password",
                validation_name: "Confirm password",
            },
        },
        watch: ["password"],
    });
    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <div className={styles.header}>
                    <Logo classes={styles.logoSize} />
                    <div className={styles.slogan}>Unleash Your Potential</div>
                </div>
                {invalid ? (
                    <div className={styles.loginForm}>
                        <div className={styles.errorTitle}>Invalid URL</div>
                        <div className={styles.errorMessage}>
                            The URL is invalid. Please use a valid URL. If the issue persists
                            contact your Gawda adminstartor
                        </div>
                    </div>
                ) : (
                    <div className={styles.loginForm}>
                        <div className={styles.inputContainer}>
                            <AppInput
                                containerStyle={{
                                    // marginBottom: 35,
                                    paddingLeft: 15,
                                    paddingRight: 20,
                                    height: 50,
                                    backgroundColor: "#ccc",
                                }}
                                inputStyle={{
                                    margin: "0px 7px",
                                    backgroundColor: "#ccc",
                                    color: "black",
                                }}
                                value={email}
                                disabled={true}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <ValidationProvider
                                error={errors.password}
                                render={({ requiredTitle }) => (
                                    <AppInput
                                        type="password"
                                        placeholder="Password"
                                        containerStyle={{
                                            // marginBottom: 35,
                                            paddingLeft: 15,
                                            paddingRight: 20,
                                            height: 50,
                                        }}
                                        inputStyle={{ margin: "0px 7px" }}
                                        value={state.form.password}
                                        onChange={(e) => onChange("password", e.target.value)}
                                        onBlur={(e) => onBlur("password")}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <ValidationProvider
                                error={errors.confirm_password}
                                render={({ requiredTitle }) => (
                                    <AppInput
                                        type="password"
                                        placeholder="Confirm password"
                                        containerStyle={{
                                            // marginBottom: 35,
                                            paddingLeft: 15,
                                            paddingRight: 20,
                                            height: 50,
                                        }}
                                        inputStyle={{ margin: "0px 7px" }}
                                        value={state.form.confirm_password}
                                        onChange={(e) =>
                                            onChange("confirm_password", e.target.value)
                                        }
                                        onBlur={(e) => onBlur("confirm_password")}
                                    />
                                )}
                            />
                        </div>

                        <AppButton
                            value="Submit"
                            className="rounded"
                            style={{
                                backgroundColor: "var(--darkPurble)",
                                color: "white",
                                // width: "30%",
                                height: 50,
                                fontSize: 22,
                                marginBottom: 20,
                                minWidth: 200,
                            }}
                            onClick={handleSubmit}
                            isLoading={state.saveLoading}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export default ResetPassword;
