import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppButton from "../../components/general/app-button/AppButton";
import AppInput from "../../components/general/app-input/AppInput";
import ValidationProvider from "../../components/general/validation-provider/ValidationProvider";
import Logo from "../../components/logo/Logo";
import useLogic from "../../helpers/hooks/use-logic";
import useValidator from "../../helpers/hooks/use-validator";
import Logic, { INITIAL_STATE } from "./logic";
import styles from "./SwitchCompany.module.css";
const SwitchCompany = ({ navigation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const user = useSelector((state) => state.user);
  // console.log(user);
  const { state, updateState, loginHandler } = useLogic({
    INITIAL_STATE,
    Logic,
  });

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      state.loading = true;
      handleSubmit(event);
      state.loading = false;
    }
  };

  const { errors, onBlur, onChange, handleSubmit } = useValidator({
    defaultValues: {
      ...state.form,
    },
    setValue: (nextFormState) => {
      updateState({
        prop: "form",
        value: nextFormState,
      });
    },
    onSubmit: loginHandler,
    validations: {
      username: { required: true, validation_name: "Username", isEmail: true },
      password: {
        required: true,
        validation_name: "Password",
      },
    },
  });
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.header}>
          <Logo classes={styles.logoSize} />
          {/* <div className={styles.logo}>GAWDA</div> */}
          <div className={styles.slogan}>{t("slogan")}</div>
          <h1 className={styles.slogan}>{`${state.name}, ${t(
            "Just making sure it's you"
          )}`}</h1>
        </div>

        <div className={styles.loginForm}>
          <div className={styles.inputContainer}>
            <AppInput
              type="text"
              placeholder={t("username")}
              containerStyle={{
                paddingLeft: 15,
                paddingRight: 20,
                height: 50,
              }}
              inputStyle={{ margin: "0px 7px" }}
              value={state.form.username}
              disabled
              onChange={() => {}}
            />
          </div>
          <div className={styles.inputContainer}>
            <ValidationProvider
              error={errors.password}
              render={({ requiredTitle }) => (
                <AppInput
                  type="password"
                  placeholder={t("password")}
                  containerStyle={{
                    paddingLeft: 15,
                    paddingRight: 20,
                    height: 50,
                  }}
                  inputStyle={{ margin: "0px 7px" }}
                  value={state.form.password}
                  onChange={(e) => onChange("password", e.target.value)}
                  onBlur={(e) => onBlur("password")}
                  onKeyDown={keyDownHandler}
                />
              )}
            />
          </div>

          <AppButton
            value={t("Proceed")}
            className="rounded"
            style={{
              backgroundColor: "var(--darkPurble)",
              color: "white",
              width: "30%",
              height: 50,
              fontSize: 22,
              marginBottom: 20,
            }}
            onClick={handleSubmit}
            isLoading={state.loading}
          />
        </div>
      </div>
    </div>
  );
};
export default SwitchCompany;
