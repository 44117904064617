import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../../../components/general/app-button/AppButton";
import { getUserFromLS } from "../../../../helpers/Storage";
const SizeColumn = { maxWidth: 200, minWidth: 150, width: 200 };

export const PermissionsColumn = (
  actions,
  translate = (term) => {
    return term;
  }
) => {
  const user = getUserFromLS();
  return [
    {
      Header: `${translate("name")}`,
      accessor: "name",
    },
    {
      Header: `${translate("type")}`,
      accessor: "type",
    },
    {
      Header: `${translate("actions")}`,
      // className: "frozen",
      // style: {
      //   textAlign: "center",
      // },
      Cell: ({ row: { values, original } }) => (
        <div
          style={{ display: "flex", justifyContent: "space-evenly", flex: 1 }}
        >
          {console.log("values", original.is_admin === 1)}
          <AppButton
            classes="buttonIcon"
            onClick={() =>
              actions.navigate(`/portal/add-permission/${original.id}`)
            }
            value={`${translate("edit")}`}
            style={{
              color: original.is_admin === 1 ? "#c7c3c3" : "#2e5bff",
            }}
            icon={
              <FontAwesomeIcon icon={faEdit} style={{ margin: "0px 5px" }} />
            }
            disabled={original.is_admin === 1}
          />
          <AppButton
            classes="buttonIcon"
            style={{ color: original.is_admin === 1 ? "#c7c3c3" : "#fb3737" }}
            onClick={() => actions.delete(original.id)}
            value={`${translate("delete")}`}
            icon={
              <FontAwesomeIcon
                icon={faTrashAlt}
                style={{ margin: "0px 5px" }}
              />
            }
            disabled={original.is_admin === 1}
          />
        </div>
      ),
    },
  ];
};
