import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { getAllPermissions } from "../../../../api/services/PermissionServices";
import { fetchSpecificRole, updateOrCreateRole } from "../../../../api/services/RoleServices";
import { ERROR } from "../../../../components/general/app-toast/AppToast";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import { INITIAL_STATE as INITIAL_STATE_TABLE } from "../../../../helpers/RtCommon";
import { DELETE_PROP, UPDATE_PROP } from "../../../../store/ActionTypes";
import { PermissionColumn } from "./PermissionColumn";
import { useTranslation } from "react-i18next";
import { extractErrorMessage } from "../../../../helpers/Common";

export const INITIAL_STATE = {
    form: {
        name: "",
        description: "",
        selectedPermission: [],
        deleteLoading: false,
    },
    permissions: [],
    addPermissionModal: false,

    showDeleteAlert: false,
    permissionId: null,
    saveLoading: false,
};
const Logic = (dispatch, state) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { addToast } = UseAppToast();
    // let is_admin_temp = false;
    const { id } = useParams();
    const permissions = {
        ...INITIAL_STATE_TABLE,
        results: state.form.selectedPermission,
    };

    useEffect(() => {
        getAllPermissions(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `permissions`,
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `loading`,
                            value: false,
                        },
                    ],
                });
            },
            { id }
        );
    }, []);
    useEffect(() => {
        if (id) {
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: `loading`,
                        value: true,
                    },
                ],
            });
            fetchSpecificRole(
                (res) => {
                    console.log(res.data.response);
                    // is_admin_temp = res.data.response.is_admin;
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `form.name`,
                                value: res.data.response.name,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.description`,
                                value: res.data.response.description,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.selectedPermission`,
                                value: res.data.response.permissions,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.is_admin`,
                                value: res.data.response.is_admin,
                            },
                        ],
                    });
                },
                (error) => {
                    addToast({
                        type: ERROR,
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                },
                () => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `loading`,
                                value: false,
                            },
                        ],
                    });
                },
                { id }
            );
        }
    }, []);
    const AddRole = () => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "saveLoading",
                    value: true,
                },
            ],
        });
        let data = {
            name: state.form.name,
            description: state.form.description,
            permissions: state.form.selectedPermission.map((v) => {
                return v.id;
            }),
        };

        if (id) {
            data = { ...data, id: id };
        }

        updateOrCreateRole(
            (res) => {
                addToast({
                    type: "success",
                    description: `${id ? "Update" : "Add"} Role Successfully`,
                    title: "Success",
                });
                navigate("/portal/roles");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },

            (f) => {},
            {
                ...data,
            }
        );
    };

    const HandleCloseDelete = () => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "showDeleteAlert",
                    value: false,
                },
            ],
        });
    };
    const HandelDelete = () => {
        // update modal loading
        dispatch({
            payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: true }],
        });
        const index = state.form.selectedPermission.findIndex((x) => {
            return x.id === state.permissionId;
        });
        dispatch({
            payload: [
                {
                    type: DELETE_PROP,
                    prop: `form.selectedPermission.${index}`,
                },
            ],
        });
        dispatch({
            payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: false }],
        });
        HandleCloseDelete();
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "permissionId",
                    value: null,
                },
            ],
        });
    };
    const deletePermission = (id) => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: "showDeleteAlert",
                    value: true,
                },
                {
                    type: UPDATE_PROP,
                    prop: "permissionId",
                    value: id,
                },
            ],
        });
    };
    const actions = () => {
        return {
            delete: deletePermission,
            navigate: navigate,
        };
    };
    let columns = useMemo(
        () => PermissionColumn(actions(), state.form.is_admin === 1, t),
        [state.form.is_admin]
    );

    return {
        actions: { AddRole, HandelDelete, HandleCloseDelete },
        navigate,
        columns,
        permissions,
    };
};

export default Logic;
