import { $axios, $securedAxios } from "../axios";
export const fetchLocations = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/location/get?page=${payload.page}`, {
      filters: payload.filters,
    })
    .then((response) => {
      onSuccess({ ...response.data.response });

      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
// export const duplicateLocation = (onSuccess, onError, onFinally, payload) => {
//   $securedAxios
//     .post(`/location/duplicate`, { ...payload })
//     .then((response) => {
//       onSuccess({ ...response.data.response });
//     })
//     .catch((error) => {
//       onError(error);
//     })
//     .finally(() => {
//       onFinally();
//     });
// };
export const fetchTimezones = (onSuccess, onError, onFinally) => {
  $securedAxios
    .get(`/timezone/get`)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const updateOrCreateLocation = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  $securedAxios
    .post(`/location/store`, {
      ...payload,
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const fetchSpecificLocation = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  $securedAxios
    .get(`/location/get/${payload.id}`)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const fetchAllLocations = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .get(`/location/get-all`, {
      params: {
        pageSize: payload?.pageSize,
      },
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const deleteSelectedLocation = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  $securedAxios
    .post(`/location/delete`, {
      ids: payload.ids,
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
