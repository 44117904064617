import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import {
    faChevronCircleDown,
    faChevronCircleRight,
    faEllipsisV,
    faSort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import AppButton from "../../../../../../../../components/general/app-button/AppButton";
import AppTooltip from "../../../../../../../../components/general/app-tooltip/AppTooltip";
import globalLogic from "../../../../globalLogic";
import AddNewItem from "../add-new-item/AddNewItem";
import ElementMaker from "../element-maker/ElementMaker";
import Question from "../questions/Question";
import styles from "./SubSection.module.css";

const SubSection = ({
    itemInfo,
    subSectionId,
    sectionId,
    subsectionindex,
    sectionindex,
    // state,
}) => {
    const { t } = useTranslation();
    const {
        state,
        addQuestion,
        deleteSubSection,
        toggleSubSection,
        onQuestionDrop,
        getPayload,
        updateSubsectionName,
        duplicateSubSection,
    } = globalLogic();

    return (
        <div>
            {/* sub section */}
            <div className={styles.subSection}>
                <div className={styles.subSectionText}>
                    <FontAwesomeIcon
                        icon={faSort}
                        color={"var(--lightGrey)"}
                        className="column-drag-handle-subsection"
                    />
                    <AppButton
                        className="buttonIcon"
                        onClick={() => {
                            console.log("collapsed");
                            toggleSubSection(sectionindex, subsectionindex);
                        }}
                        icon={
                            itemInfo.collapsed ? (
                                <FontAwesomeIcon
                                    icon={faChevronCircleRight}
                                    color={"var(--primary)"}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faChevronCircleDown}
                                    color={"var(--primary)"}
                                />
                            )
                        }
                        style={{
                            borderRadius: 0,
                            color: "black",
                        }}
                    />
                    {/* <p>{JSON.stringify(state.selectedQuestion)}</p> */}

                    {/* <p className={styles.title}>
            {subsectionindex}
            {sectionindex}
          </p> */}
                    <ElementMaker
                        value={itemInfo.name}
                        handleChange={(text) =>
                            updateSubsectionName(sectionindex, subsectionindex, text)
                        }
                    />
                </div>
                <AppTooltip
                    placement="bottom-end"
                    buttonValue={
                        <FontAwesomeIcon
                            icon={faEllipsisV}
                            style={{ alignSelf: "center", color: "grey" }}
                        />
                    }
                    buttonStyle={{ padding: "2px 3px", fontSize: 15 }}
                    renderProp={({ setShow }) => (
                        <div className={styles.tooltipContent}>
                            <AppButton
                                className="buttonIcon"
                                onClick={() => {
                                    duplicateSubSection(sectionindex, subsectionindex);
                                    setShow(false);
                                }}
                                style={{
                                    borderRadius: 0,
                                    color: "black",
                                }}
                                value={t("duplicate")}
                            />
                            <AppButton
                                className="buttonIcon"
                                onClick={() => {
                                    //   console.log("delete sub section");
                                    deleteSubSection(sectionindex, subsectionindex);
                                    setShow(false);
                                }}
                                style={{
                                    borderRadius: 0,
                                    color: "black",
                                }}
                                value={t("delete")}
                            />
                        </div>
                    )}
                />
            </div>
            {!itemInfo.collapsed ? (
                <>
                    {/* questions */}
                    <div className={styles.questionContainer}>
                        <div className={styles.questionContent}>
                            <Container
                                groupName="question"
                                onDragStart={(e) => console.log("drag started", e)}
                                onDragEnd={(e) => console.log("drag end", e)}
                                onDrop={(e) => {
                                    onQuestionDrop(sectionId, subSectionId, e);
                                }}
                                getChildPayload={(index) => getPayload(itemInfo.questions[index])}
                                dragHandleSelector=".column-drag-handle-question"
                                dragClass="card-ghost"
                                dropClass="card-ghost-drop"
                                className="card-container"
                                onDragEnter={() => {
                                    console.log("drag enter:", itemInfo.id);
                                }}
                                onDragLeave={() => {
                                    console.log("drag leave:", itemInfo.id);
                                }}
                                onDropReady={(p) => console.log("Drop ready: ", p)}
                                dropPlaceholder={{
                                    animationDuration: 150,
                                    showOnTop: true,
                                    className: styles.droppreview,
                                }}
                                dropPlaceholderAnimationDuration={200}
                            >
                                {itemInfo.questions.map((question, i) => (
                                    <Draggable key={question.id}>
                                        <Question
                                            itemInfo={question}
                                            key={question.id}
                                            index={i}
                                            subSectionId={subSectionId}
                                            sectionId={sectionId}
                                            sectionindex={sectionindex}
                                            subsectionindex={subsectionindex}
                                        />
                                    </Draggable>
                                ))}
                            </Container>
                        </div>
                        <div>
                            <AddNewItem
                                toggleButtonText={t("addQuestion")}
                                onAdd={(text) => {
                                    addQuestion(
                                        sectionindex,
                                        subsectionindex,
                                        text,
                                        sectionId,
                                        subSectionId
                                    );
                                }}
                                labelstyle={styles.questionInput}
                                textarea={true}
                            />
                        </div>
                    </div>
                    {/* end questions  */}
                </>
            ) : null}
            {/* end sub section */}
        </div>
    );
};

export default SubSection;
