import { useState } from "react";
import styles from "./CheckboxInput.module.css";

const CheckboxInput = ({
  name,
  label,
  isChecked = false,
  onChange,
  value,
  disabled = false,
  ...props
}) => {
  const { className, ...rest } = props;
  const allstyles = `${styles.container} ${
    className != undefined ? className : ""
  }`;

  return (
    <>
      <div className={allstyles} {...rest}>
        <input
          type="checkbox"
          className={styles.customRadio}
          name={name}
          id={name}
          checked={isChecked}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      </div>
    </>
  );
};

export default CheckboxInput;
