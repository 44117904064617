import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import React from "react";
import "react-color-palette/lib/css/styles.css";
import { useTranslation } from "react-i18next";
import ValidationProvider from "../../../../../../components/general/validation-provider/ValidationProvider";
import useLogic from "../../../../../../helpers/hooks/use-logic";
import globalLogic from "../../globalLogic";
import AddNewItem from "./components/add-new-item/AddNewItem";
import Options from "./components/options/Options";
import Section from "./components/section/Section";
import Logic, { INITIAL_STATE } from "./logic";
import styles from "./Structure.module.css";
const Structure = ({ id, errors, onBlur }) => {
  const { t } = useTranslation();
  const {
    state,
    addSection,
    updateQuestion,
    handleCheck,
    findItemIndexById,
    getQuestionInfo,
    onSectionDrop,
    setQuestionsImages,
  } = globalLogic();
  const { saveAuditTemplate, reduxState } = useLogic({
    INITIAL_STATE,
    Logic,
    prop: { id },
  });
  // console.log("======structure==============================");
  // console.log(state);
  return (
    <div className={styles.container}>
      {/* sections */}

      <div className={styles.sections}>
        {/* <h6>{JSON.stringify(state.selectedQuestion)}</h6> */}
        <ValidationProvider
          error={errors.sections}
          render={() => (
            <>
              <div className={styles.sectionContainer}>
                <Container
                  onDrop={(e) => onSectionDrop(e)}
                  dragHandleSelector=".column-drag-handle"
                  dropPlaceholder={{
                    animationDuration: 150,
                    showOnTop: true,
                    className: styles.cardsdroppreview,
                  }}
                  className={styles.sectionx}
                  getChildPayload={(index) => onSectionDrop(index)}
                >
                  {state.sections.map((section, sectionIndex) => (
                    <Draggable key={section.id}>
                      <Section
                        section={section}
                        sectionIndex={sectionIndex}
                        key={section.id}
                        findItemIndexById={findItemIndexById}
                      />
                    </Draggable>
                  ))}
                </Container>
              </div>
              <AddNewItem
                toggleButtonText={t("addSection")}
                onAdd={async (text) => {
                  addSection(text, () => onBlur("sections"));
                }}
              />
            </>
          )}
        />
      </div>
      {/* <h1>{JSON.stringify(state.selectedQuestion )?? 'g'}</h1> */}
      {/* end sections */}
      {state.selectedQuestion && (
        <>
          <div style={{ width: "20px" }}></div>

          <Options
            question={getQuestionInfo()}
            masterData={state.masterData}
            updateQuestion={updateQuestion}
            handleCheck={handleCheck}
            selectedQuestion={state.selectedQuestion}
            setQuestionsImages={setQuestionsImages}
            allUsers={reduxState.masterData.users}
          />
        </>
      )}
    </div>
  );
};

export default Structure;
