import { $axios, $securedAxios } from "../axios";
// fetch the area dropdowns
export const fetchAllAreas = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
      .post(`/area/get`, {
        params: {
          pageSize: payload?.pageSize,
        },
      })
      .then((response) => {
        onSuccess(response);
        // console.log(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinally();
      });
  };
  export const fetchAreas = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
      .post(`/area/get?page=${payload.page}`, {
        filters: payload.filters,
      })
      .then((response) => {
        onSuccess({ ...response.data.response });
        // console.log(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinally();
      });
  };
  export const fetchSpecificArea = (
    onSuccess,
    onError,
    onFinally,
    payload
  ) => {
    $securedAxios
      .get(`/area/get/${payload.id}`)
      .then((response) => {
        onSuccess(response);
        // console.log(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinally();
      });
  };

  export const updateOrCreateArea = (
    onSuccess,
    onError,
    onFinally,
    payload
  ) => {
    $securedAxios
      .post(`/area/store`, {
        ...payload,
      })
      .then((response) => {
        onSuccess(response);
        // console.log(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinally();
      });
  };

  export const deleteArea = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
      .post(`/area/delete`, {
        ids: payload.ids,
      })
      .then((response) => {
        onSuccess(response);
        // console.log(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinally();
      });
  };