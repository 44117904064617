import { useContext, useReducer } from "react";
import { DELETE_PROP, MERGE_PROP, UPDATE_PROP } from "../../store/ActionTypes";
import { reducer } from "../../store/reducer";

const useLogic = ({ INITIAL_STATE, Logic, prop = {} }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { ...props } = Logic(dispatch, state, prop);
  const logicActions = {};
  for (let key in props.actions) {
    logicActions[key] = (...args) => {
      return props.actions[key](...args);
    };
  }
  const updateState = ({ prop, value }) => {
    dispatch({
      payload: [{ type: UPDATE_PROP, prop, value }],
    });
  };
  const updateProps = (payload) => {
    dispatch({ payload });
  };

  const deleteState = ({ prop }) => {
    dispatch({
      payload: [{ type: DELETE_PROP, prop }],
    });
  };
  const mergeState = ({ prop, value }) => {
    dispatch({
      payload: [{ type: MERGE_PROP, prop, value }],
    });
  };
  return {
    state,
    updateState,
    deleteState,
    mergeState,
    updateProps,
    ...props,
    ...logicActions,
  };
};

export default useLogic;
