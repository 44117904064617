import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { deleteAudits, getAuditPdf, getAudits } from "../../../api/services/AuditServices";
import { ERROR } from "../../../components/general/app-toast/AppToast";
import UseAppTable from "../../../helpers/hooks/use-app-table";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../store/ActionTypes";
import { FullListColumn, ScheduleColumn } from "./ScheduleColumn";
import { extractErrorMessage } from "../../../helpers/Common";
export const INITIAL_STATE = {
    active: {},
    activeIndex: 0,
    showDeleteAlert: false,
    filters: [
        {
            "audit_templates.name": { sign: "contains", value: "" },
            "locations.name": { sign: "contains", value: "" },
            date: { sign: "contains", value: "" },
            assignee: { sign: "contains", value: "" },
        },
    ],
    pdfLoaders: {},
};
const Logic = (dispatch, state) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { addToast } = UseAppToast();

    const {
        data: all_data,
        setData: setAll_data,
        error: all_data_error,
    } = UseAppTable({
        fetchTableData: getAudits,
    });
    const {
        data: scheduled_data,
        setData: setScheduled_data,
        error: scheduled_data_error,
    } = UseAppTable({
        fetchTableData: getAudits,
        payload: { schedule_status: 0 },
    });
    const {
        data: missed_data,
        setData: setMissed_data,
        error: missed_data_error,
    } = UseAppTable({
        fetchTableData: getAudits,
        payload: { schedule_status: 1 },
    });
    const {
        data: completed_data,
        setData: setCompleted_data,
        error: completed_data_error,
    } = UseAppTable({
        fetchTableData: getAudits,
        payload: { schedule_status: 2 },
    });
    let tabsList = [
        {
            label: t("all"),
            key: "all",
            data: all_data,
            setData: setAll_data,
            error: all_data_error,
            //   renderContent: (
            //     <Card style={{ padding: "20px 10px" }}>
            //       <AppTable
            //         columns={columns}
            //         data={scheduled_data}
            //         setData={setScheduled_data}
            //         error={scheduled_data_error}
            //         showCheckbox
            //       />
            //     </Card>
            //   ),
        },
        {
            label: t("scheduled"),
            key: "scheduled",
            data: scheduled_data,
            setData: setScheduled_data,
            error: scheduled_data_error,
            //   renderContent: (
            //     <Card style={{ padding: "20px 10px" }}>
            //       <AppTable
            //         columns={columns}
            //         data={scheduled_data}
            //         setData={setScheduled_data}
            //         error={scheduled_data_error}
            //         showCheckbox
            //       />
            //     </Card>
            //   ),
        },
        {
            label: t("missed"),
            key: "missed",
            data: missed_data,
            setData: setMissed_data,
            error: missed_data_error,
            //   renderContent: (
            //     <Card style={{ padding: "20px 10px" }}>
            //       <AppTable
            //         columns={columns}
            //         data={missed_data}
            //         setData={setMissed_data}
            //         error={missed_data_error}
            //         showCheckbox
            //       />
            //     </Card>
            //   ),
        },
        {
            label: t("completed"),
            key: "completed",
            data: completed_data,
            setData: setCompleted_data,
            error: completed_data_error,
            //   renderContent: (
            //     <Card style={{ padding: "20px 10px" }}>
            //       <AppTable
            //         columns={columns}
            //         data={completed_data}
            //         setData={setCompleted_data}
            //         error={completed_data_error}
            //         showCheckbox
            //       />
            //     </Card>
            //   ),
        },
    ];
    const getPdf = (data) => {
        // update download id with data

        dispatch({
            payload: [{ type: UPDATE_PROP, prop: `pdfLoaders.${data}`, value: true }],
        });

        getAuditPdf(
            (res) => {
                const url = window.URL.createObjectURL(
                    new Blob([res.data], { type: "application/pdf" })
                );
                const link = document.createElement("a");
                link.setAttribute("href", url);
                // download file with its name
                link.setAttribute("download", `audit.pdf`);
                document.body.appendChild(link);
                link.click();
            },
            (e) => {
                addToast({
                    type: ERROR,
                    description: e.message,
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: `pdfLoaders.${data}`, value: false }],
                });
            },
            { audit_id: data }
        );
    };
    const rowDelete = (selectedRow) => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `showDeleteAlert`,
                    value: true,
                },
            ],
        });

        tabsList[state.activeIndex].setData((prevState) => {
            return {
                ...prevState,
                selectedRows: selectedRow,
            };
        });
    };
    const actions = () => {
        return {
            rowDelete: rowDelete,
            getPdf,
            navigate,
        };
    };
    let columns = useMemo(() => ScheduleColumn(actions(), t), [state.activeIndex, i18n.language]);
    let allColumns = useMemo(
        () => FullListColumn(actions(), state.pdfLoaders, t),
        [state.activeIndex, i18n.language, state.pdfLoaders]
    );

    useEffect(() => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `active`,
                    value: tabsList[state.activeIndex].data,
                },
            ],
        });
    }, [tabsList[state.activeIndex].data]);

    const handleDelete = () => {
        // update modal loading
        dispatch({
            payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: true }],
        });
        const selectedArray = state.active.selectedRows.map((d) => d.id);

        deleteAudits(
            (res) => {
                let arrResults = [...state.active.results];
                selectedArray.map((d) => {
                    const index = arrResults.findIndex((x) => {
                        return x.id === d;
                    });
                    arrResults.splice(index, 1);
                });
                tabsList[state.activeIndex].setData((prevState) => {
                    let newData = {
                        results: [...arrResults],
                        total: prevState.total - 1,
                    };
                    if (arrResults.length == 0) {
                        newData = {
                            ...newData,
                            params: {
                                ...prevState.params,
                                page: prevState.params.page - 1,
                            },
                        };
                    }
                    return {
                        ...prevState,
                        ...newData,
                    };
                });
                handleCloseDelete();
                addToast({
                    type: "success",
                    description: "Schedule deleted successfully.",
                    title: "Success",
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                // update modal loading
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: false }],
                });
            },
            { ids: selectedArray }
        );
    };
    const handleCloseDelete = () => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `showDeleteAlert`,
                    value: false,
                },
            ],
        });
    };

    return {
        actions: { navigate, handleCloseDelete, handleDelete },
        columns,
        allColumns,
        tabsList,
    };
};

export default Logic;
