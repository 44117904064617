import { useTranslation } from "react-i18next";
import AppButton from "../../../../components/general/app-button/AppButton";
import AppInput from "../../../../components/general/app-input/AppInput";
import Card from "../../../../components/general/card/Card";
import CheckboxInput from "../../../../components/general/checkbox-input/CheckboxInput";
import Label from "../../../../components/general/label/Label";
import LoadingSpinner from "../../../../components/general/loading-spinner/LoadingSpinner";
import RadioInput from "../../../../components/general/radio-input/RadioInput";
import Title from "../../../../components/general/title/Title";
import ValidationProvider from "../../../../components/general/validation-provider/ValidationProvider";
import useLogic from "../../../../helpers/hooks/use-logic";
import useValidator from "../../../../helpers/hooks/use-validator";
import { getUserFromLS } from "../../../../helpers/Storage";
import styles from "./AddPermission.module.css";
import Logic, { INITIAL_STATE } from "./logic";
import React from "react";

const AddPermission = () => {
  const { t } = useTranslation();
  const { state, updateState, handleCheck, AddPermission, navigate } = useLogic(
    {
      INITIAL_STATE,
      Logic,
    }
  );

  const user = getUserFromLS();
  const { form } = state;

  const companyFeatures = user.subscription.plan_features;

  const { errors, onBlur, onChange, handleSubmit } = useValidator({
    defaultValues: {
      ...state.form,
    },
    setValue: (nextFormState) => {
      updateState({
        prop: "form",
        value: nextFormState,
      });
    },
    onSubmit: AddPermission,
    validations: {
      name: {
        required: true,
        validation_name: "Name",
      },
      selectedPermissionType: {
        required: true,
        validation_name: "Type",
      },
      groupsChecked: {
        required: form.selectedPermissionType === "Group",
        validation_name: "Permissions",
      },
      featuresChecked: {
        required: form.selectedPermissionType === "Feature",
        validation_name: "Features",
      },
    },
  });
  return (
    <>
      <div className={styles.title}>
        {/* {JSON.stringify(state)} */}
        <Title title={t("newPermission")}></Title>
      </div>
      <Card style={{ padding: 20 }}>
        <LoadingSpinner isLoading={state.loading}>
          <div className={`${styles.container} ${styles.form}`}>
            <div className={styles.groupControl} style={{ width: "45%" }}>
              <ValidationProvider
                error={errors.name}
                render={({ requiredTitle }) => (
                  <Label
                    name={requiredTitle(t("name"))}
                    className={styles.label}
                  >
                    <div className={styles.inputContainer}>
                      <AppInput
                        type="text"
                        className={styles.input}
                        value={form.name}
                        onChange={(e) => onChange("name", e.target.value)}
                        onBlur={(e) => onBlur("name")}
                        disabled={form.is_admin === 1}
                      />
                    </div>
                  </Label>
                )}
              />
            </div>
            <div className={styles.groupControl} style={{ width: "50%" }}>
              <ValidationProvider
                error={errors.selectedPermissionType}
                render={({ requiredTitle }) => (
                  <Label
                    name={requiredTitle(t("type"))}
                    className={styles.label}
                  >
                    <div className={styles.radioButtonGroup}>
                      <RadioInput
                        name="Group"
                        value="Group"
                        label={t("permissionGroup")}
                        isChecked={form.selectedPermissionType === "Group"}
                        handleChange={(value) =>
                          onChange("selectedPermissionType", value)
                        }
                      />
                      <RadioInput
                        name="Feature"
                        value="Feature"
                        label={t("permissionListOfFeatures")}
                        isChecked={form.selectedPermissionType === "Feature"}
                        handleChange={(value) =>
                          onChange("selectedPermissionType", value)
                        }
                      />
                      <RadioInput
                        name="Data"
                        value="Data"
                        label={t("permissionListData")}
                        isChecked={form.selectedPermissionType === "Data"}
                        handleChange={(value) =>
                          onChange("selectedPermissionType", value)
                        }
                      />
                    </div>
                  </Label>
                )}
              />
            </div>
          </div>
          <div className={styles.container}>
            {form.selectedPermissionType == "Feature" && (
              <ValidationProvider
                error={errors.featuresChecked}
                render={({ requiredTitle }) => (
                  <div className={styles.optionsContainer}>
                    <Title
                      title={requiredTitle(t("selectPermissions"))}
                      border={true}
                      style={{ color: "var(--primary)", fontSize: "16px" }}
                    />
                    <div className={styles.checklistContainer}>
                      {state.featuresList.map(
                        (item, index) =>
                          companyFeatures[item.value] && (
                            <CheckboxInput
                              name={item.value}
                              label={item.label}
                              isChecked={form.featuresChecked[`${item.value}`]}
                              onChange={(e) => {
                                let x = state;
                                debugger;
                                onChange(
                                  "featuresChecked",
                                  handleCheck(
                                    e,
                                    "form.featuresChecked",
                                    state.form.featuresChecked
                                  )
                                );
                              }}
                              value={item.id}
                              key={index}
                              className={styles.singleInput}
                              disabled={form.is_admin === 1}
                            />
                          )
                      )}
                    </div>
                  </div>
                )}
              />
            )}
            {form.selectedPermissionType == "Group" && (
              <ValidationProvider
                error={errors.groupsChecked}
                render={({ requiredTitle }) => (
                  <div className={styles.optionsContainer}>
                    <Title
                      title={requiredTitle(t("selectPermissions"))}
                      border={true}
                      style={{ color: "var(--primary)", fontSize: "16px" }}
                    />
                    <div className={styles.checklistContainer}>
                      {state.groupsList.map((item, index) => (
                        <CheckboxInput
                          name={item.id}
                          label={item.name}
                          isChecked={form?.groupsChecked?.includes(
                            `${item.id}`
                          )}
                          onChange={(e) =>
                            onChange(
                              "groupsChecked",
                              handleCheck(
                                e,
                                "form.groupsChecked",
                                state.form.groupsChecked
                              )
                            )
                          }
                          value={item.id}
                          key={index}
                          className={styles.singleInput}
                          disabled={form.is_admin === 1}
                        />
                      ))}
                    </div>
                  </div>
                )}
              />
            )}
            {form.selectedPermissionType == "Data" && (
              <>
                <div className={styles.optionsContainer}>
                  {/* <ValidationProvider
                    error={errors.selectedDepartment}
                    render={({ requiredTitle }) => (
                      <> */}
                  <Title
                    title={"Select Department"}
                    border={true}
                    style={{ color: "var(--primary)", fontSize: "16px" }}
                  />
                  <div className={styles.checklistContainer}>
                    {/* {state.departmentsList.map((item, index) => (
                                            <RadioInput
                                                name={item.id}
                                                value={item.id}
                                                label={item.name}
                                                isChecked={form.selectedDepartment == item.id}
                                                key={item.id}
                                                handleChange={(value) =>
                                                    onChange("selectedDepartment", value)
                                                }
                                                className={styles.singleInput}
                                            />
                                        ))} */}

                    {state.departmentsList.map((item, index) => (
                      <CheckboxInput
                        name={item.id}
                        label={item.name}
                        isChecked={form?.selectedDepartment?.includes(
                          `${item.id}`
                        )}
                        onChange={(e) => {
                          onChange(
                            "selectedDepartment",
                            handleCheck(
                              e,
                              "form.selectedDepartment",
                              state.form.selectedDepartment
                            )
                          );
                        }}
                        value={item.id}
                        key={index}
                        className={styles.singleInput}
                        disabled={form.is_admin === 1}
                      />
                    ))}
                  </div>
                  {/* </>
                    )}
                  /> */}
                </div>
                <div className={styles.optionsContainer}>
                  {/* <ValidationProvider
                    error={errors.locationsChecked}
                    render={({ requiredTitle }) => (
                      <> */}
                  <Title
                    title={"Select Locations"}
                    border={true}
                    style={{ color: "var(--primary)", fontSize: "16px" }}
                  />
                  <div className={styles.checklistContainer}>
                    {state.locationsList.map((item, index) => (
                      <CheckboxInput
                        name={item.id}
                        label={item.name}
                        isChecked={form.locationsChecked.includes(`${item.id}`)}
                        onChange={(e) => {
                          onChange(
                            "locationsChecked",
                            handleCheck(
                              e,
                              "form.locationsChecked",
                              state.form.locationsChecked
                            )
                          );
                        }}
                        value={item.id}
                        key={index}
                        className={styles.singleInput}
                      />
                    ))}
                  </div>
                  {/* </>
                    )}
                  /> */}
                </div>
                <div className={styles.optionsContainer}>
                  {/* <ValidationProvider
                                        error={errors.checklistChecked}
                                        render={({ requiredTitle }) => (
                                            <> */}
                  <Title
                    title={"Select Checklists"}
                    border={true}
                    style={{
                      color: "var(--primary)",
                      fontSize: "16px",
                    }}
                  />
                  <div className={styles.checklistContainer}>
                    {state.checklistList.map((item, index) => (
                      <CheckboxInput
                        name={item.id}
                        label={item.name}
                        isChecked={form.checklistChecked.includes(`${item.id}`)}
                        onChange={(e) =>
                          onChange(
                            "checklistChecked",
                            handleCheck(
                              e,
                              "form.checklistChecked",
                              state.form.checklistChecked
                            )
                          )
                        }
                        value={item.id}
                        key={index}
                        className={styles.singleInput}
                      />
                    ))}
                  </div>
                  {/* </>
                                        )}
                                    /> */}
                </div>
              </>
            )}
          </div>
          <div className={styles.actionButton}>
            <AppButton
              className="primaryButton"
              onClick={handleSubmit}
              value={t("save")}
              isLoading={state.saveLoading}
              type="button"
            />
            <AppButton
              className="whiteButton"
              onClick={() => navigate("/portal/permissions")}
              value={t("cancel")}
              style={{ margin: "0px 15px" }}
            />
          </div>
        </LoadingSpinner>
      </Card>
    </>
  );
};

export default AddPermission;
