import React from "react";
import styles from "./CustomMessage.module.css";

const CustomMessage = ({ senderName, message, timestamp, attachments }) => {
  return (
    <div className={styles.message}>
      <div className={styles.header}>
        <strong>{senderName}</strong> <span>{timestamp}</span>
      </div>
      <div className={styles.body}>{message}</div>
      <div className={styles.attachments}>
        {attachments.map((attachment) => (
          <div key={attachment.id} className={styles.attachment}>
            {attachment.url && attachment.url.url && attachment.url.name ? (
              attachment.url.name.endsWith(".jpg") || attachment.url.name.endsWith(".png") ? (
                <a href={attachment.url.url} download={attachment.url.name} rel="noreferrer" target="_blank">
                  <img
                    src={attachment.url.url}
                    alt={attachment.url.name}
                    className={styles.imageAttachment}
                  />
                </a>
              ) : (
                <a href={attachment.url.url} download={attachment.url.name} className={styles.pdfAttachment} rel="noreferrer" target="_blank">
                  {/* <p>{attachment.url.name}</p> */}
                  <p>{attachment.url.name.substring(attachment.url.name.lastIndexOf(".") + 1)}</p>
                </a>
              )
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomMessage;