import { $securedAxios } from "../axios";
export const getAudits = (onSuccess, onError, onFinally, pay) => {
  let payload = JSON.parse(JSON.stringify(pay));
  /* Small fix to handle status "missed" in audits */
  if (payload?.filters?.length != 0) {
    let index = payload.filters.findIndex((f) => {
      return f.key == "audits.status";
    });
    if (index != -1 && payload.filters[index].value == 2) {
      payload.filters[index].value = 0;

      payload.filters.push({
        key: "audits.date",
        label: "Date",
        sign: "less_than",
        signLabel: "less_than",
        value: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`,
        value2: "",
      });
    }
  }
  $securedAxios
    .post(`/audit/get?page=${payload.page}`, {
      filters: payload.filters,
      schedule_status: payload.schedule_status,
    })
    .then((response) => {
      onSuccess({ ...response.data.response });
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const getAuditPdf = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/audit/get-pdf`, payload, {
      responseType: "blob",
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const currentassessments = (onSuccess, onError, onFinally, payload) => {
  console.log("currentassessments called");
  $securedAxios
    .post(`/audit/get?page=${payload.page}`, {
      filters: payload.filters,
      schedule_status: payload.schedule_status,
    })
    .then((response) => {
      onSuccess({ ...response.data.response });
      // // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const getAuditTemplates = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/audit-template/get?page=${payload.page}`, {
      filters: payload.filters,
    })
    .then((response) => {
      onSuccess({ ...response.data.response });
      // // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const updateOrCreateAuditTemplates = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  // // console.log(payload.formData);
  $securedAxios
    .post(`/audit-template/store`, payload.formData)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const deleteAudits = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/audit/delete`, {
      ids: payload.ids,
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const deleteAuditTemplate = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/audit-template/delete/${payload.id}`)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const fetchAllAuditTemplates = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  $securedAxios
    .get(`/audit-template/get-all`, {
      params: {
        pageSize: payload?.pageSize,
      },
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const storeAudit = (onSuccess, onError, onFinally, payload) => {
  // console.log(payload);
  $securedAxios
    .post(`/audit/store`, { ...payload })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

// export const duplicateAudit = (onSuccess, onError, onFinally, payload) => {
//   $securedAxios
//     .post(`/audit-template/duplicate`, { ...payload })
//     .then((response) => {
//       onSuccess(response);
//       // console.log(response);
//     })
//     .catch((error) => {
//       onError(error);
//     })
//     .finally(() => {
//       onFinally();
//     });
// };
export const fetchSpecificAudit = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .get(`/audit/get/${payload.id}`)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const updateAudit = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/audit/update`, { ...payload })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const updateAuditTemplate = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/audit-template/updateAuditTemplate`, payload.formData)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const fetchAllAudits = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .get(`/audit/get-all`)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const getSpecificAuditTemplate = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  $securedAxios
    .get(`/audit-template/get/${payload.id}`)
    .then((response) => {
      onSuccess(response);
      // // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const getTags = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .get(`/tag/get-all`)
    .then((response) => {
      onSuccess(response);
      // // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const storeTag = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/tag/store`, payload)
    .then((response) => {
      onSuccess(response);
      // // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
