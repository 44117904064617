import { userDecryption, userEncryption } from "./Common";
import { USER } from "./constants/StaticKeys";

export default Storage = {
  getItem: function (key) {
    let item = localStorage.getItem(key);
    return JSON.parse(item);
  },
  setItem: function (key, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  },
  removeItem: function (key) {
    return localStorage.removeItem(key);
  },
  clear: function () {
    return localStorage.clear();
  },
};

export const getUserFromLS = () => {
  // get the user object from local storage and decrypt it
  let user = Storage.getItem(USER);
  return user ? JSON.parse(userDecryption(user)) : null;
};

export const setUserToLS = (user) => {
  // encrypt the user object and save it to local storage
  let encryptedUser = userEncryption(JSON.stringify(user));
  Storage.setItem(USER, encryptedUser);
};
