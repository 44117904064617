import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  createTicket,
  getTicketDetails,
  getTicketThread,
  sendTicketMessage,
} from "../../../api/services/TicketsServices";
import { ERROR } from "../../../components/general/app-toast/AppToast";
import { extractErrorMessage } from "../../../helpers/Common";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { getUserFromLS } from "../../../helpers/Storage";
import { UPDATE_PROP } from "../../../store/ActionTypes";

export const INITIAL_STATE = {
  response: "",
  statuses: [
    {
      id: 1,
      name: "Open",
      value: "open",
    },
    {
      id: 2,
      name: "In Progress",
      value: "in_progress",
    },
    {
      id: 3,
      name: "Awaiting Feedback",
      value: "awaiting_feedback",
    },
    {
      id: 4,
      name: "Solved",
      value: "solved",
    },
    {
      id: 5,
      name: "Closed",
      value: "closed",
    },
  ],
  followup: [],
  ticketsLoading: false,
  messagesLoading: false,
  ticketDetails: {},
};

const Logic = (dispatch, state) => {
  const { ticketId } = useParams();
  const { addToast } = UseAppToast();
  const { t, i18n } = useTranslation();
  // const user = useSelector((state) => state.user);
  const user = getUserFromLS();

  const handleSendMessage = (data) => {
    const id = state.followup[0]?.id === undefined ? "" : state.followup[0]?.id;

    const formData = new FormData();
    formData.append("ticket_id", ticketId);
    formData.append("type", "customer");
    formData.append("reference_id", id);
    formData.append("name", user);
    formData.append("message", data.message);
    data.attachments.forEach((attachment, index) => {
      formData.append(`attachments[]`, attachment);
    });
    console.log("FormData Contents:");
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `messagesLoading`,
          value: true,
        },
      ],
    });
    sendTicketMessage(
      (res) => {
        console.log(res.data.response);
        // dispatch({
        //   payload: [
        //     {
        //       type: UPDATE_PROP,
        //       prop: `followup`,
        //       value: [...state.followup,res.data.response.data],
        //     },
        //   ],
        // });
        addToast({
          type: "success",
          description: "Message sent successfully",
          title: "Success",
        });
      },
      (error) => {
        console.log(error);
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `error`,
              value: "cannot load this page",
            },
          ],
        });
        addToast({
          type: ERROR,
          description: extractErrorMessage(error),
          title: "Error",
        });
      },
      () => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `messagesLoading`,
              value: false,
            },
          ],
        });
      },
      formData
    );
  };

  const handleStatusClick = (status) => {
    console.log("status", status);
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `ticketsLoading`,
          value: true,
        },
      ],
    });
    createTicket(
      (res) => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `ticketDetails`,
              value: {
                ...state.ticketDetails,
                activeStatus: status.name,
              },
            },
          ],
        });
        addToast({
          type: "success",
          description: "Status updated successfully",
          title: "Success",
        });
      },
      (error) => {
        console.log(error);
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `error`,
              value: "cannot load this page",
            },
          ],
        });
        addToast({
          type: ERROR,
          description: extractErrorMessage(error),
          title: "Error",
        });
      },
      () => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `ticketsLoading`,
              value: false,
            },
          ],
        });
      },
      {
        ...state.ticketDetails,
        status: status.value,
      }
    );
  };

  useEffect(() => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `ticketsLoading`,
          value: true,
        },
      ],
    });
    getTicketDetails(
      (res) => {
        // get status object
        const status = state.statuses.find(
          (status) => status.value === res.data.response.status
        );

        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `ticketDetails`,
              value: {
                // id: res.data.response.ticket_number,
                id: res.data.response.id,
                companyName: res.data.response.company_name,
                ticketDate: res.data.response.created_at,
                assignee: res.data.response.name,
                activeStatus: status.name,
                issue: res.data.response.description,
                ticketNumber: res.data.response.ticket_number,
                name: res.data.response.name,
                type: res.data.response.type,
                description: res.data.response.description,
              },
            },
          ],
        });
      },
      (error) => {
        console.log(error);
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `error`,
              value: "cannot load this page",
            },
          ],
        });
        addToast({
          type: ERROR,
          description: extractErrorMessage(error),
          title: "Error",
        });
      },
      () => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `ticketsLoading`,
              value: false,
            },
          ],
        });
      },
      { id: ticketId }
    );

    getTicketThread(
      (res) => {
        console.log("thread", res.data.response.data);
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `followup`,
              value: res.data.response.data,
            },
          ],
        });
      },
      (error) => {
        console.log(error);
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `error`,
              value: "cannot load this page",
            },
          ],
        });
        addToast({
          type: ERROR,
          description: extractErrorMessage(error),
          title: "Error",
        });
      },
      () => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `ticketsLoading`,
              value: false,
            },
          ],
        });
      },
      { ticket_id: ticketId }
    );
  }, [state.messagesLoading]);

  return { actions: {}, handleSendMessage, handleStatusClick };
};

export default Logic;
