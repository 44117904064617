import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { deleteAuditTemplate, getAuditTemplates } from "../../../../api/services/AuditServices";
import { ERROR } from "../../../../components/general/app-toast/AppToast";
import UseAppTable from "../../../../helpers/hooks/use-app-table";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
import { ChecklistsColumn } from "./columns";
import { extractErrorMessage } from "../../../../helpers/Common";

export const INITIAL_STATE = { showDeleteAlert: false, id: null };
const Logic = (dispatch, state) => {
    const { t, i18n } = useTranslation();
    const { addToast } = UseAppToast();
    const { data, setData, error } = UseAppTable({
        fetchTableData: getAuditTemplates,
    });
    let navigate = useNavigate();

    const showDeleteAlert = (id) => {
        dispatch({
            payload: [
                { type: UPDATE_PROP, prop: "showDeleteAlert", value: true },
                { type: UPDATE_PROP, prop: "id", value: id },
            ],
        });
    };
    const HandleCloseDelete = () => {
        dispatch({
            payload: [{ type: UPDATE_PROP, prop: "showDeleteAlert", value: false }],
        });
    };
    const HandelDelete = () => {
        // update modal loading
        dispatch({
            payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: true }],
        });
        deleteAuditTemplate(
            (res) => {
                let arrResults = [...data.results];
                const index = arrResults.findIndex((x) => {
                    return x.id === state.id;
                });

                arrResults.splice(index, 1);
                console.log("arrResults");
                console.log(arrResults);
                setData((prevState) => {
                    let newData = {
                        results: [...arrResults],
                        total: prevState.total - 1,
                    };
                    if (arrResults.length == 0) {
                        newData = {
                            ...newData,
                            params: {
                                ...prevState.params,
                                page: prevState.params.page - 1,
                            },
                        };
                    }
                    return {
                        ...prevState,
                        ...newData,
                    };
                });
                HandleCloseDelete();
                addToast({
                    type: "success",
                    description: "Checklist Deleted Successfully.",
                    title: "Success",
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                // update modal loading
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: false }],
                });
            },
            { id: state.id }
        );
    };

    const actions = () => {
        return {
            delete: showDeleteAlert,
            navigate: navigate,
        };
    };

    let columns = useMemo(() => ChecklistsColumn(actions(), t), [i18n.language]);

    useEffect(() => {}, []);
    return {
        actions: {
            setData,
            HandleCloseDelete,
            HandelDelete,
        },
        data,
        error,
        columns,
    };
};

export default Logic;
