import { useState } from "react";
import Styles from "./MultiEmail.module.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppInput from "../../../components/general/app-input/AppInput";

const MultiEmail = ({ emails, setEmails, ...rest }) => {
    const [currentValue, setCurrentValue] = useState("");
    const [error, setError] = useState("");

    const handleLogic = (e) => {
        let value = e.target.value.trim().replace(/,\s*$/, "");
        // let re =
        //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let arrEmails = value.split(",").filter((email) => email != "" && !emails.includes(email));
        if (arrEmails.length > 0) {
            setEmails(emails.concat(arrEmails));
            setCurrentValue("");
            // setError("");
        }
        //else {
        // setError("something went wrong");
        // }
    };

    const handlekeyPress = (e) => {
        // console.log(e);
        // e.preventDefault();
        if (e.code == "Comma") {
            debugger;
            handleLogic(e);
        }
        if (e.code == "Enter") {
            handleLogic(e);
        }
    };
    const handleDelete = (index) => {
        let arrEmails = [...emails];
        arrEmails.splice(index, 1);
        setEmails(arrEmails);
    };
    const handleOnchange = (e) => {
        let value = e.target.value;
        if (value.includes(",")) {
            handleLogic(e);
        } else {
            setCurrentValue(value);
        }
        setError("");
    };
    const handleKeyDown = (e) => {
        if (e.keyCode == 8 && !currentValue) {
            handleDelete(emails.length - 1);
        }
    };
    return (
        <>
            <div className={Styles.container}>
                {emails.map((item, index) => (
                    <div key={index} className={Styles.emailContainer}>
                        {item}
                        <FontAwesomeIcon
                            icon={faTimes}
                            className={Styles.emailIcon}
                            style={{ color: "var(--primary)", fontSize: "10px" }}
                            onClick={() => {
                                handleDelete(index);
                            }}
                        />
                    </div>
                ))}
                <AppInput
                    type="text"
                    value={currentValue}
                    onChange={handleOnchange}
                    onKeyPress={handlekeyPress}
                    onKeyDown={handleKeyDown}
                    style={{ margin: "0px 2px" }}
                    containerStyle={{ margin: 0, flex: 1, padding: 0 }}
                    onBlur={(e) => {
                        handleLogic(e);
                    }}
                    {...rest}
                ></AppInput>
            </div>
            <div className={Styles.error}>{error}</div>
        </>
    );
};
export default MultiEmail;
