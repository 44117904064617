import React from "react";
import { useTranslation } from "react-i18next";
import AppButton from "../../../components/general/app-button/AppButton";
import AppInput from "../../../components/general/app-input/AppInput";
import AppSelect from "../../../components/general/app-select/AppSelect";
import Card from "../../../components/general/card/Card";
import Label from "../../../components/general/label/Label";
import LoadingSpinner from "../../../components/general/loading-spinner/LoadingSpinner";
import SwitchButton from "../../../components/general/switch-button/SwitchButton";
import Title from "../../../components/general/title/Title";
import ValidationProvider from "../../../components/general/validation-provider/ValidationProvider";
import useLogic from "../../../helpers/hooks/use-logic";
import useValidator from "../../../helpers/hooks/use-validator";
import Styles from "./AddUser.module.css";
import { filterSelectStyles } from "./Constants";
import Logic, { INITIAL_STATE } from "./logic";

const AddUser = () => {
  const { state, updateState, handleUpdateOrCreate, navigate, id } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  const { t } = useTranslation();
  const { form } = state;
  const { errors, onBlur, onChange, handleSubmit } = useValidator({
    defaultValues: {
      ...state.form,
    },
    setValue: (nextFormState) => {
      updateState({
        prop: "form",
        value: nextFormState,
      });
    },
    onSubmit: handleUpdateOrCreate,
    validations: {
      first_name: {
        required: true,
        validation_name: "First name",
      },
      last_name: { required: true, validation_name: "Last name" },
      short_name: { required: false, validation_name: "Short name" },
      job_title: { required: false, validation_name: "Job title" },
      email: { required: true, validation_name: "Email", isEmail: true },
      selectedRole: { required: true, validation_name: "Role" },
    },
  });

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>
        <Title title={t("addUser")}></Title>
      </div>
      <Card style={{ padding: "20px 10px" }}>
        <LoadingSpinner isLoading={state.pageLoading} error={state.error}>
          {/* <form> */}
          <div className={Styles.formContainer}>
            <div className={Styles.sectionContainer}>
              <div style={{ marginTop: "25px" }}>
                <div className={Styles.profilePictureContainer}>
                  <div style={{ width: "27%" }}>
                    <img
                      alt="user"
                      src={require("../../../assets/images/defaultProfilePicture.png")}
                      width={"100%"}
                      height={"auto"}
                      style={{ borderRadius: "70px" }}
                    />
                  </div>
                  <div style={{ width: "70%" }}>
                    <div className={Styles.groupControl}>
                      <ValidationProvider
                        error={errors.first_name}
                        render={({ requiredTitle }) => (
                          <Label
                            name={requiredTitle(t("firstName"))}
                            className={Styles.label}
                          >
                            <AppInput
                              type="text"
                              className={Styles.input}
                              value={form.first_name}
                              onChange={(e) =>
                                onChange("first_name", e.target.value)
                              }
                              // disabled={id ? true : false}
                              onBlur={(e) => onBlur("first_name")}
                            />
                          </Label>
                        )}
                      />
                    </div>

                    <div className={Styles.groupControl}>
                      <ValidationProvider
                        error={errors.last_name}
                        render={({ requiredTitle }) => (
                          <Label
                            name={requiredTitle(t("lastName"))}
                            className={Styles.label}
                          >
                            <AppInput
                              type="text"
                              className={Styles.input}
                              value={form.last_name}
                              onChange={(e) =>
                                onChange("last_name", e.target.value)
                              }
                              onBlur={(e) => onBlur("last_name")}
                              // disabled={id ? true : false}
                            />
                          </Label>
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className={Styles.groupControl}>
                  <ValidationProvider
                    error={errors.email}
                    render={({ requiredTitle }) => (
                      <Label
                        name={requiredTitle(t("email"))}
                        className={Styles.label}
                      >
                        <AppInput
                          type="email"
                          className={Styles.input}
                          value={form.email}
                          onChange={(e) => onChange("email", e.target.value)}
                          onBlur={(e) => onBlur("email")}
                          disabled={id ? true : false}
                        />
                      </Label>
                    )}
                  />
                </div>
                <div className={Styles.groupControl}>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.job_title}
                      render={({ requiredTitle }) => (
                        <Label name={t("jobTitle")} className={Styles.label}>
                          <AppInput
                            type="text"
                            className={Styles.input}
                            value={form.job_title}
                            onChange={(e) =>
                              onChange("job_title", e.target.value)
                            }
                            onBlur={(e) => onBlur("job_title")}
                          />
                        </Label>
                      )}
                    />
                  </div>
                  <div className={Styles.inputControl}>
                    <ValidationProvider
                      error={errors.short_name}
                      render={({ requiredTitle }) => (
                        <Label name={"Short Name"} className={Styles.label}>
                          <AppInput
                            type="text"
                            className={Styles.input}
                            value={form.short_name}
                            onChange={(e) =>
                              onChange("short_name", e.target.value)
                            }
                            onBlur={(e) => onBlur("short_name")}
                          />
                        </Label>
                      )}
                    />
                  </div>
                </div>
              </div>
              {!id && (
                <div className={Styles.switchControl}>
                  <SwitchButton
                    label={t("sendWelcomeEmail")}
                    checked={state.form.sendWelcomeEmail}
                    onChange={(e) => {
                      console.log(e);
                      updateState({
                        prop: "form.sendWelcomeEmail",
                        value: e,
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div className={Styles.sectionContainer}>
              <Title title={t("accessRights")} border={true} />
              <div style={{ marginTop: "15px", padding: "0px 20px" }}>
                <div className={Styles.groupControl}>
                  <ValidationProvider
                    error={errors.selectedRole}
                    render={({ requiredTitle }) => (
                      <Label
                        name={requiredTitle(t("role"))}
                        className={Styles.label}
                      >
                        <AppSelect
                          options={state.roles
                            .filter(
                              (role) =>
                                !form.is_admin === 1 || role.is_admin !== 1
                            )
                            .map((element, index) => ({
                              value: element.id,
                              label: element.name,
                              id: element.id,
                            }))}
                          styles={filterSelectStyles}
                          value={form.selectedRole
                            .filter(
                              (role) => form.is_admin || role.is_admin !== 1
                            )
                            .map((p) => {
                              return {
                                value: p.id,
                                label: p.name,
                                ...p,
                              };
                            })}
                          onChange={(e) => onChange("selectedRole", e)}
                          onBlur={(e) => onBlur("selectedRole")}
                          isMulti
                          isDisabled={form.is_admin}
                        />
                      </Label>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={Styles.actionButton}>
            <AppButton
              className="primaryButton"
              onClick={handleSubmit}
              value={t("save")}
              isLoading={state.saveLoading}
              type="button"
            />
            <AppButton
              className="whiteButton"
              onClick={() => navigate("/portal/users")}
              value={t("cancel")}
              style={{ margin: "0px 15px" }}
            />
          </div>
        </LoadingSpinner>
      </Card>
    </div>
  );
};
export default AddUser;
