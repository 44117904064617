import {
  faCheckCircle,
  faExclamationCircle,
  faExternalLinkAlt,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../../../components/general/app-button/AppButton";
import { FILTER_TYPES } from "../../../../components/general/simple-table/constants";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
export const INITIAL_STATE = {
  filters: [],
  sorting: [],
  isAPPopupOpen: false,
  isNewProgOpen: false,
  loading: {
    actionPlansLoading: false,
    newAPProgLoading: false,
    actionPlanProgLoading: false,
    closeAPLoading: false,
  },
  actionPlans: [],
  selectedAP: {},
  forwardingAPProg: {},
  progressData: [],
  // myMessages:{data:[]}
  myMessages: [],
  att: [],
  newAPProg: { assignee_id: {}, description: "" },
  allUsers: { data: [] },
  isConfirmOpen: false,
  closingRemarks: "",
};

export const actionPlanColumns = (
  actions,
  translate = (term) => {
    return term;
  }
) => {
  return [
    {
      id: "priority",
      header: "Priority",
      // accessorKey: "priority",
      type: FILTER_TYPES.TEXT,
      cell: ({ cell, row }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color={
                row.original.priority == "High"
                  ? "red"
                  : row.original.priority == "Medium"
                  ? "yellow"
                  : "cyan"
              }
            />
          </div>
        );
      },
    },
    {
      id: "action",
      header: "Action",
      accessorKey: "action_text",
      type: FILTER_TYPES.TEXT,
    },
    {
      id: "location",
      header: "Location",
      accessorKey: "location",
      type: FILTER_TYPES.TEXT,
    },
    {
      id: "audit",
      header: "Audit",
      accessorKey: "audit_name",
      type: FILTER_TYPES.TEXT,
    },
    {
      id: "auditor",
      header: "Auditor",
      accessorKey: "auditor_name",
      type: FILTER_TYPES.TEXT,
    },
    {
      id: "createDate",
      header: "Create Date",
      accessorKey: "created_at",
      type: FILTER_TYPES.DATE,
    },
    {
      id: "dueDate",
      header: "Due Date",
      accessorKey: "due_date",
      type: FILTER_TYPES.DATE,
    },
    {
      id: "timeElapsed",
      header: "Time Elapsed",
      accessorKey: "timeElapsed",
      // type: FILTER_TYPES.TEXT,
    },
    {
      id: "status",
      header: "Status",
      accessorKey: "status",
      type: FILTER_TYPES.NUMBER,
    },

    {
      id: "hops",
      header: "Hops",
      accessorKey: "hops",
      // type: FILTER_TYPES.NUMBER,
    },
    {
      id: "buttons",
      header: "Actions",
      cell: ({ row: { original } }) => {
        return (
          <AppButton
            classes="buttonIcon"
            onClick={() => actions.openActionPlanDetails(original)}
            // style={{
            //     color: "#2e5bff",
            // }}
            icon={
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                style={{ marginRight: "5px", marginLeft: "5px" }}
              />
            }
          />
        );
      },
    },
  ];
};

export const actionPlanStepsColumns = (
  actions,
  selectedAP,
  loggedInEmail,
  translate = (term) => {
    return term;
  }
) => {
  let apClosed;
  selectedAP?.status == 0 ? (apClosed = false) : (apClosed = true);
  return [
    {
      id: "description",
      header: "Description",
      accessorKey: "description",
      // type: FILTER_TYPES.DATE,
    },
    {
      id: "assignee",
      header: "Assignee",
      accessorKey: "assignee_id.name",
      // type: FILTER_TYPES.NUMBER,
    },
    {
      id: "assignedDate",
      header: "Assigned Date",
      accessorKey: "assigneed_date",
      // type: FILTER_TYPES.NUMBER,
      cell: ({ row: { original } }) => {
        // original.assigneed_date;
        return (
          new Date(original.assigneed_date).toLocaleDateString("fr-CA") +
          " " +
          new Date(original.assigneed_date).toLocaleTimeString("it-IT")
        );
      },
    },
    {
      id: "endDate",
      header: "End Date",
      accessorKey: "end_date",
      // type: FILTER_TYPES.NUMBER,
    },

    {
      id: "duration",
      header: "Duration",
      accessorKey: "duration",
      // type: FILTER_TYPES.NUMBER,
    },
    {
      id: "status",
      header: "Status",
      accessorKey: "status",
      // type: FILTER_TYPES.NUMBER,
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row: { original } }) => {
        // console.log("original", original);
        return (
          !original.end_date &&
          !apClosed &&
          loggedInEmail === original?.assignee_id?.email && (
            <>
              <AppButton
                classes="primaryButton"
                onClick={() => actions.forwardProgress(original)}
                icon={
                  <FontAwesomeIcon
                    icon={faForward}
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                  />
                }
                value={translate("forward")}
              />
              <AppButton
                classes="primaryButton"
                onClick={() =>
                  actions.dispatch({
                    payload: [
                      {
                        type: UPDATE_PROP,
                        prop: "isConfirmOpen",
                        value: true,
                      },
                    ],
                  })
                }
                icon={
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                  />
                }
                value={translate("close")}
              />
            </>
          )
        );
      },
    },
  ];
};
