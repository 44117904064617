import styles from "./Plans.module.css";
import Logo from "../../components/logo/Logo";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { AUTHENTICATION_TOKEN } from "../../helpers/constants/StaticKeys";
import { fetchLoggedinUser } from "../../api/services/UserServices";
import { useDispatch } from "react-redux";
import { loginAction } from "../../store/Actions";
import { setUserToLS } from "../../helpers/Storage";
import PlanCard from "./components/planCard";
import Spacer from "../../components/general/spacer/Spacer";
import useLogic from "../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";
import { UPDATE_PROP } from "../../store/ActionTypes";
import LoadingModal from "../../components/general/loading-modal/LoadingModal";
const Plans = ({ navigation }) => {
    const { t } = useTranslation();
    const { state, updateProps, updateState, handleSubmit } = useLogic({
        INITIAL_STATE,
        Logic,
    });

    const { data, selectedPeriod, selectedPlan, loading } = state;
    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <div className={styles.header}>
                    {/* <Logo classes={styles.logoSize} />
                    <div className={styles.slogan}>{t("slogan")}</div> */}
                    <div style={{ marginBottom: 25 }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                // backgroundColor: "white",
                                borderRadius: 5,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {data?.map((d, i) => {
                                return (
                                    <div
                                        onClick={() => {
                                            // updateState({ prop: "selectedPeriod", value: i });
                                            updateProps([
                                                {
                                                    prop: "selectedPeriod",
                                                    value: i,
                                                    type: UPDATE_PROP,
                                                },
                                                {
                                                    prop: "selectedPlan",
                                                    value: null,
                                                    type: UPDATE_PROP,
                                                },
                                            ]);
                                        }}
                                        style={{
                                            border: "1px var(--primary) solid",
                                            borderTopLeftRadius: i == 0 ? 5 : 0,
                                            borderBottomLeftRadius: i == 0 ? 5 : 0,
                                            borderTopRightRadius: i == data.length - 1 ? 5 : 0,
                                            borderBottomRightRadius: i == data.length - 1 ? 5 : 0,
                                            padding: "5px 10px",
                                            backgroundColor:
                                                selectedPeriod == i ? "var(--primary)" : "white",
                                            color: selectedPeriod == i ? "white" : "var(--primary)",
                                        }}
                                    >
                                        {d.name}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                    >
                        {data[selectedPeriod]?.plans?.map((d, i) => {
                            return (
                                <>
                                    <PlanCard
                                        plan={d}
                                        selected={selectedPlan == i}
                                        onPlanClick={() => {
                                            updateProps([
                                                {
                                                    type: UPDATE_PROP,
                                                    prop: "selectedPlan",
                                                    value: i,
                                                },
                                                {
                                                    type: UPDATE_PROP,
                                                    prop: "users",
                                                    value: 1,
                                                },
                                            ]);
                                        }}
                                        onChangeUser={(e) => {
                                            let x = parseInt(e.target.value);
                                            x = isNaN(x) ? 1 : x;
                                            x = Math.max(1, Math.min(x, 9999));
                                            updateProps([
                                                {
                                                    type: UPDATE_PROP,
                                                    prop: "users",
                                                    value: x,
                                                },
                                            ]);
                                        }}
                                        users={state.users}
                                        total={state.users * data[selectedPeriod].factor * d.price}
                                        totalPerText={data[selectedPeriod].name}
                                        handleSubmit={handleSubmit}
                                    />
                                    {i != data.length - 1 && <Spacer width={20} />}
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
            <LoadingModal show={loading.loadingSubmit || loading.loadingPlans} />
        </div>
    );
};
export default Plans;
