import { $authAxios, $securedAxios } from "../axios";

export const fetchSubscription = (onSuccess, onError, onFinally, payload) => {
    $authAxios
        .post(`subscriptions/get/settings?page=${payload.page}`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data.response });
            //       console.log(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const fetchAllSettingsData = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .get("company/settings")
        .then((response) => {
            onSuccess({ ...response.data.response });
            //       console.log(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const fetchTicketsSummery = (onSuccess, onError, onFinally, payload) => {
    $authAxios
        .post("tickets/get/settings/counts")
        .then((response) => {
            onSuccess({ ...response.data.response });
            //       console.log(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const updateContactPeson = (onSuccess, onError, onFinally, payload) => {
    $authAxios
        .post(`/login/user/company/update`, {
            ...payload,
        })
        .then((response) => {
            onSuccess(response);
            //       console.log(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const fecthPlans = (onSuccess, onError, onFinally, payload) => {
    $authAxios
        .post(`plan/pricing/get`, {})
        .then((response) => {
            onSuccess({ ...response.data.response });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const submitRenewSubscription = (onSuccess, onError, onFinally, payload) => {
    $authAxios
        .post(`plan/pricing/store`, payload)
        .then((response) => {
            onSuccess({ ...response.data.response });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
