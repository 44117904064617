import React, { useRef, useState, useEffect } from "react";
import { Overlay } from "react-bootstrap";
import AppButton from "../app-button/AppButton";
import styles from "./AppTooltip.module.css";

const AppTooltip = ({
  contentstyle,
  placement = "right",
  buttonValue,
  buttonStyle,
  renderProp,
  buttonClassName,
}) => {
  const target = useRef(null);
  const myref = useRef(null);
  const [show, setShow] = useState(false);
  //   console.log(target.current);
  const handleClickOutside = (e) => {
    // console.log(myref.current.contains(e.target));
    if (!myref.current?.contains(e.target)) {
      setShow(false);
    }
  };
  const handleClickInside = () => setShow(!show);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  return (
    <div>
      <AppButton
        ref={target}
        onClick={handleClickInside}
        value={buttonValue}
        style={buttonStyle}
        className={buttonClassName}
      />
      <Overlay
        target={target.current}
        show={show}
        placement={placement}
        ref={myref}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              position: "absolute",
              padding: "2px 0px",
              color: "white",
              borderRadius: 3,
              ...props.style,
              ...contentstyle,
            }}
          >
            {renderProp({ setShow })}
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default AppTooltip;
