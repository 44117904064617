import { $axios, $securedAxios } from "../axios";
export const fetchDepartments = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/department/get?page=${payload.page}`, {
      filters: payload.filters,
    })
    .then((response) => {
      onSuccess({ ...response.data.response });
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const deleteDepartment = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/department/delete`, {
      ids: payload.ids,
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const updateOrCreateDepartment = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  $securedAxios
    .post(`/department/store`, {
      ...payload,
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const fetchSpecificDepartment = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  $securedAxios
    .get(`/department/get/${payload.id}`)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const getAllDepartments = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .get(`department/get-all`, {
      params: {
        pageSize: payload?.pageSize,
      },
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const getAuditTrends = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/analytics/audit-trends`, {
      ...payload,
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
