import { useSelector, useDispatch } from "react-redux";
import { updateToasts } from "../../store/Actions";
const UseAppToast = () => {
  const toasts = useSelector((state) => state.toasts);
  const dispatch = useDispatch();

  const uid = () => {
    return (Date.now() + "-" + Math.random()).toString(16).replace(/\./g, "");
  };
  const addToast = (toast) => {
    dispatch(
      updateToasts([...toasts, { ...toast, id: uid(), start_time: Date.now() }])
    );
  };
  const removeToast = (id) => {
    dispatch(updateToasts(toasts.filter((t) => t.id !== id)));
  };

  return {
    toasts,
    addToast,
    removeToast,
  };
};
export default UseAppToast;
