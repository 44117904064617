import React, { useState, useEffect, useRef, useCallback } from "react";
import { Toast } from "react-bootstrap";
import { faCheckCircle, faTimesCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Styles from "./AppToast.module.css";

export const ERROR = "error";
export const SUCCESS = "success";
export const INFO = "info";

const AppToast = ({ autoClose, autoCloseTime, onClose, toast }) => {
    let headerStyle;
    let icon;
    let iconColor;
    if (toast.type === ERROR) {
        headerStyle = Styles.toastContentError;
        icon = faTimesCircle;
        iconColor = Styles.errorIcon;
    } else if (toast.type === SUCCESS) {
        headerStyle = Styles.toastContentSuccess;
        icon = faCheckCircle;
        iconColor = Styles.successIcon;
    } else if (toast.type === INFO) {
        headerStyle = Styles.toastContentInfo;
        icon = faInfoCircle;
        iconColor = Styles.infoIcon;
    } else {
    }

    return (
        <Toast onClose={onClose} delay={autoCloseTime} autohide={autoClose} className={headerStyle}>
            <Toast.Header>
                {toast.type && (
                    <FontAwesomeIcon icon={icon} className={`${Styles.toastIcon} ${iconColor}`} />
                )}
                {toast.title && (
                    <strong className={`me-auto ${Styles.toastTitle}`}>{toast.title}</strong>
                )}
                {toast.time && <small>{toast.time}</small>}
            </Toast.Header>
            <Toast.Body>
                <div>{toast.description}</div>
            </Toast.Body>
        </Toast>
    );
};
export default AppToast;
