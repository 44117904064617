import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import AppButton from "../../../../../../../../components/general/app-button/AppButton";
import styles from "./Option.module.css";

const Option = ({ title, children }) => {
    const [collapsed, setCollapsed] = useState(true);
    return (
        <>
            <div
                className={styles.option}
                // ****VERY BAD WORK AROUND ****/
                onClick={() => {
                    setCollapsed(!collapsed);
                }}
            >
                {title && <p className={styles.optionText}>{title}</p>}

                <AppButton
                    className="buttonIcon"
                    onClick={() => {
                        setCollapsed(!collapsed);
                    }}
                    icon={
                        collapsed ? (
                            <FontAwesomeIcon icon={faChevronRight} color={"var(--primary)"} />
                        ) : (
                            <FontAwesomeIcon icon={faChevronDown} color={"var(--primary)"} />
                        )
                    }
                    style={{
                        borderRadius: 0,
                        color: "black",
                    }}
                />
            </div>
            {!collapsed && <div className={styles.reponses}>{children}</div>}
        </>
    );
};

export default Option;
