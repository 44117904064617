import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    addMessages,
    fetchActionPlanProgress,
    fetchActionPlans,
    fetchMessages,
    submitActionPlanProgress,
    updateActionPlans,
} from "../../../../api/services/ActionPlanServices";
import { fetchAllUsers } from "../../../../api/services/UserServices";
import { ERROR, SUCCESS } from "../../../../components/general/app-toast/AppToast";
import {
    extractErrorMessage,
    translateFilterArray,
    useEffectSkipFirst,
} from "../../../../helpers/Common";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import { getUserFromLS } from "../../../../helpers/Storage";
import { DELETE_PROP, MERGE_PROP, UPDATE_PROP } from "../../../../store/ActionTypes";
import { actionPlanColumns, actionPlanStepsColumns } from "./constants";
const Logic = (dispatch, state) => {
    const { t, i18n } = useTranslation();
    const { addToast } = UseAppToast();

    // const user = useSelector((state) => state.user);
    const user = getUserFromLS();

    const setFilters = (filters) => {
        dispatch({
            payload: [{ type: UPDATE_PROP, prop: "filters", value: filters }],
        });
    };
    const setSorting = (sorting) => {
        dispatch({
            payload: [{ type: UPDATE_PROP, prop: "sorting", value: sorting }],
        });
    };
    const updateLoader = (prop, value) => {
        dispatch({
            payload: [{ type: UPDATE_PROP, prop, value }],
        });
    };

    const updateState = (prop, value) => {
        dispatch({
            payload: [{ type: UPDATE_PROP, prop, value }],
        });
    };

    const getAllUsers = () => {
        fetchAllUsers(
            (res) => {
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: "allUsers", value: res.data.response }],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            { pageSize: 99999 }
        );
    };

    const openActionPlanDetails = (actionPlan) => {
        // alert(actionPlan.id)
        console.log("actionPlan", actionPlan);
        // debugger;
        dispatch({
            payload: [
                { type: UPDATE_PROP, prop: "isAPPopupOpen", value: true },
                { type: UPDATE_PROP, prop: "selectedAP", value: actionPlan },
            ],
        });
    };

    const getActionPlans = (props) => {
        updateLoader("loading.actionPlansLoading", true);

        fetchActionPlans(
            {
                filters: translateFilterArray(state.filters),
                page: props.page,
                pageSize: props.pageSize,
            },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "actionPlans",
                            value: response.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.actionPlansLoading", false);
            }
        );
    };

    const getActionPlanProgress = (props) => {
        updateLoader("loading.actionPlanProgLoading", true);
        // debugger;
        fetchActionPlanProgress(
            {
                filters: translateFilterArray(state.filters),
                page: props.page,
                pageSize: props.pageSize,
                id: state.selectedAP.id,
            },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "progressData",
                            value: response.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.actionPlanProgLoading", false);
            }
        );
    };

    const getMessages = (props) => {
        console.log("checklist_actions_id", state.selectedAP.id);
        fetchMessages(
            {
                filters: [
                    {
                        key: "checklist_actions_id",
                        sign: "is_equal",
                        value: state.selectedAP.id,
                    },
                ],
                sort_column: "created_at",
                sort_by: "asc",
                pageSize: 999,
            },
            (response) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "actionPlanMessages",
                            value: response.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {}
        );
    };

    const submitMessage = (props) => {
        const { formData } = props;
        addMessages(
            formData,
            (response) => {
                // getMessages();
                dispatch({
                    payload: [
                        {
                            type: MERGE_PROP,
                            prop: "actionPlanMessages.data",
                            value: response.data.response,
                        },
                    ],
                });
                props.setAttachments([]);
                props.setInputValue("");
                props.setIsLoading(false);
                addToast({
                    type: "success",
                    description: "Message sent successfully",
                    title: "Success",
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                props.setIsLoading(false);
            }
        );
    };

    const forwardProgress = (forwardingAPProg) => {
        // Save object of actionplan progress so its id can be used to update the end date
        // open popup on new progress.
        dispatch({
            payload: [
                { type: UPDATE_PROP, prop: "isNewProgOpen", value: true },
                {
                    type: UPDATE_PROP,
                    prop: "forwardingAPProg",
                    value: forwardingAPProg,
                },
            ],
        });
    };
    const submitNewProgress = () => {
        // call submit API to create new action plan progress
        // make sure to update end date of current progress which initiated this new progress (inititated forward)
        updateLoader("loading.newAPProgLoading", true);
        // Update the forwarding AP to have end date
        let forwardProg = { ...state.forwardingAPProg };
        console.log("forwardProg", forwardProg);
        const date = new Date();
        forwardProg.end_date = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
            2,
            "0"
        )}-${String(date.getDate()).padStart(2, "0")} ${String(date.getHours()).padStart(
            2,
            "0"
        )}:${String(date.getMinutes()).padStart(2, "0")}:${String(date.getSeconds()).padStart(
            2,
            "0"
        )}`;
        let diff = new Date(forwardProg.end_date) - new Date(forwardProg.assigneed_date);

        const diffInMinutes = Math.floor(diff / (1000 * 60)) % 60;
        const diffInHours = Math.floor(diff / (1000 * 60 * 60)) % 24;
        const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
        forwardProg.duration = `${diffInDays} D ${diffInHours} H ${diffInMinutes} M`;
        // Changing key names so that backend works
        let newProg = { ...state.newAPProg };
        newProg.checklist_actions_id = state.forwardingAPProg.checklist_actions_id;
        newProg.assignee_id.id = newProg.assignee_id.value;
        newProg.assigneed_date = forwardProg.end_date;
        let param = [{ ...newProg }, { ...forwardProg, status: "completed" }];
        console.log("===========paraaam==========");
        console.log(JSON.stringify(param));
        submitActionPlanProgress(
            (res) => {
                addToast({
                    type: SUCCESS,
                    description: res.message,
                    title: "Success",
                });
                dispatch({
                    payload: [
                        { type: UPDATE_PROP, prop: "isNewProgOpen", value: false },
                        { type: UPDATE_PROP, prop: "forwardingAPProg", value: {} },

                        {
                            type: UPDATE_PROP,
                            prop: "newAPProg",
                            value: { assignee: {}, description: "" },
                        },
                    ],
                });
                getActionPlanProgress({ page: 1 });
            },
            (err) => {
                addToast({
                    type: ERROR,
                    description: err.message,
                    title: "Error",
                });
            },
            (f) => {
                updateLoader("loading.newAPProgLoading", false);
            },
            param
        );
    };

    const closeActionPlan = () => {
        updateLoader("loading.newAPProgLoading", true);
        let closingAP = { ...state.selectedAP };
        closingAP.status = 1;
        closingAP.action_taken_text = state.closingRemarks;
        updateActionPlans(
            (response) => {
                dispatch({
                    payload: [
                        { type: UPDATE_PROP, prop: "isAPPopupOpen", value: false },
                        { type: UPDATE_PROP, prop: "isConfirmOpen", value: false },
                        { type: UPDATE_PROP, prop: "selectedAP", value: {} },
                        { type: UPDATE_PROP, prop: "progressData", value: [] },
                        { type: UPDATE_PROP, prop: "progressData", value: [] },
                    ],
                });
                getActionPlans({ page: 1, pageSize: 30 });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                updateLoader("loading.newAPProgLoading", false);
            },
            { param: closingAP }
        );
    };

    const removeFilter = (index) => {
        dispatch({
            payload: [{ type: DELETE_PROP, prop: `filters.${index}` }],
        });
    };
    const removeSort = (index) => {
        dispatch({
            payload: [{ type: DELETE_PROP, prop: `sorting.${index}` }],
        });
    };

    useEffectSkipFirst(() => {
        getActionPlans({ page: 1, pageSize: 30 });
    }, [state.filters, state.sorting]);

    useEffectSkipFirst(() => {
        if (state.selectedAP.id != undefined) {
            getActionPlanProgress({ page: 1 });
            getMessages({ id: state.selectedAP.id });
        }
    }, [state.selectedAP]);

    useEffect(() => {
        getAllUsers();
    }, []);

    const actions = () => {
        return { openActionPlanDetails };
    };
    const progActions = () => {
        return { forwardProgress, dispatch };
    };

    let columns = useMemo(() => actionPlanColumns(actions(), t), [i18n.language]);

    let progColumns = useMemo(
        () => actionPlanStepsColumns(progActions(), state.selectedAP, user?.email, t),
        [i18n.language, state.selectedAP]
    );
    return {
        actions: {
            setFilters,
            setSorting,
            getActionPlans,
            removeFilter,
            removeSort,
            // getMessages,
            submitMessage,
            submitNewProgress,
            getActionPlanProgress,
            closeActionPlan,
        },
        columns,
        progColumns,
        updateState,
    };
};

export default Logic;
