import React, { useCallback, useState } from "react";
import AppInput from "../../../../../../../../components/general/app-input/AppInput";
import styles from "./ElementMaker.module.css";

const areEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps.value) == JSON.stringify(nextProps.value)) {
    return true;
  }
  return false;
};
const ElementMaker = ({ value, handleChange }) => {
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const handleUpdateTask = useCallback(
    (e) => {
      let text = e.target.value;
      if (e.target.value.trim().length < 1) {
        text = value;
        setNewValue(value);
      }
      setShowInput(false);
      handleChange(text);
    },
    [handleChange, newValue]
  );

  const handleInputKeyPress = useCallback(
    (event) => {
      if (event.key == "Enter") {
        handleUpdateTask(event);
      }
    },
    [handleUpdateTask]
  );
  return (
    <span>
      {showInput ? (
        <AppInput
          containerStyle={{
            flexDirection: "row-reverse",
            alignItems: "baseline",
          }}
          containerClassName={styles.inputContainer}
          type="text"
          className={styles.input}
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onBlur={handleUpdateTask}
          onKeyPress={handleInputKeyPress}
          autoFocus
        />
      ) : (
        <span
          onClick={() => setShowInput(true)}
          style={{
            display: "flex",
          }}
        >
          {value}
        </span>
      )}
    </span>
  );
};

export default React.memo(ElementMaker, areEqual);
