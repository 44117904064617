import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { requestOtp, verifyOtp } from "../../api/services/UserServices";
import { extractErrorMessage, useEffectSkipFirst } from "../../helpers/Common";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../store/ActionTypes";

export const INITIAL_STATE = {
    otp: "",
    otpValues: [],
    incompleteOtpValue: true,
    loading: false,
    resend: false,
    timer: 59,
    resendLoading: false,
};
const Logic = (dispatch, state) => {
    const navigate = useNavigate();
    const { addToast } = UseAppToast();
    const [resetPasswordObj, setResetPasswordObj] = useState({});

    const { state: propsInput } = useLocation();
    // console.log(propsInput);
    let [params, setParams] = useSearchParams();
    var CryptoJS = require("crypto-js");
    const handleVerify = () => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `loading`,
                    value: true,
                },
            ],
        });
        // debugger;
        // // debugger;
        verifyOtp(
            async (res) => {
                // debugger;
                //navigate with search params
                navigate(`/reset-password`, {
                    state: propsInput ? propsInput : resetPasswordObj,
                });
            },
            (error) => {
                addToast({
                    type: "error",
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `loading`,
                            value: false,
                        },
                    ],
                });
            },
            {
                otp: state.otp,
                email: propsInput?.email ?? resetPasswordObj.email,
            }
        );
    };
    const handleResend = () => {
        // // debugger;
        // console.log(params)
        // debugger;
        // if (params.get("uid")) {
        // const email = CryptoJS.enc.Base64.parse(params.get("uid")).toString(CryptoJS.enc.Utf8);
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `resendLoading`,
                    value: true,
                },
            ],
        });
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(propsInput.email)) {
            requestOtp(
                async (res) => {
                    addToast({
                        type: "success",
                        description: "Verification code has been sent to your email",
                        title: "Success",
                    });
                    setParams({
                        ...Object.fromEntries([...params]),
                        token: res.data.response.password_reset_token,
                    });

                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `resend`,
                                value: false,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `timer`,
                                value: 30,
                            },
                        ],
                    });
                },
                (error) => {
                    addToast({
                        type: "error",
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                },
                () => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `resendLoading`,
                                value: false,
                            },
                        ],
                    });
                },
                {
                    email: propsInput.email ? propsInput.email : resetPasswordObj.email,
                }
            );
        } else {
            addToast({
                type: "error",
                description: "Email Address not Valid",
                title: "Error",
            });
        }
        // } else {
        //     addToast({
        //         type: "error",
        //         description: "Cannot resend otp , Try again later",
        //         title: "Error",
        //     });
        // }
    };
    useEffect(() => {
        if (!propsInput) {
            setResetPasswordObj({
                token: params.get("token"),
                email: params.get("email"),
            });
        }
    }, []);
    useEffect(() => {
        //  if(!propsInput){
        //   propsInput= {token : params.get("token"), email: params.get("email")};
        //  }
        // alert(JSON.stringify(propsInput));
        let myInterval = setInterval(() => {
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: `timer`,
                        value: state.timer - 1,
                    },
                ],
            });
        }, 1000);
        if (state.timer <= 0) {
            clearInterval(myInterval);
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: `resend`,
                        value: true,
                    },
                    {
                        type: UPDATE_PROP,
                        prop: `timer`,
                        value: false,
                    },
                ],
            });
        }
        return () => clearInterval(myInterval);
    }, [state.timer]);

    useEffectSkipFirst(() => {
        // setIncompleteOtpValue(true);
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `incompleteOtpValue`,
                    value: true,
                },
            ],
        });
        const allNotEmpty = state.otpValues.every((e) => e !== "");
        const otp = state.otpValues.join("");
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `otp`,
                    value: otp,
                },
            ],
        });
        if (allNotEmpty) {
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: `incompleteOtpValue`,
                        value: false,
                    },
                ],
            });
        }
    }, [state.otpValues]);

    return { actions: { handleVerify, handleResend } };
};

export default Logic;
