import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { deleteAudits, getAuditPdf, getAudits } from "../../api/services/AuditServices";
import { ERROR } from "../../components/general/app-toast/AppToast";
import UseAppTable from "../../helpers/hooks/use-app-table";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { AuditColumn } from "./AuditColumn";
import { useTranslation } from "react-i18next";
import { UPDATE_PROP } from "../../store/ActionTypes";
import { extractErrorMessage } from "../../helpers/Common";

export const INITIAL_STATE = {
    deleteLoading: false,
    // downloadId: null,
};
const Logic = (dispatch, state) => {
    const { t, i18n } = useTranslation();
    const { addToast } = UseAppToast();
    const { data, setData, error } = UseAppTable({
        fetchTableData: getAudits,
    });
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [downloadId, setDownloadId] = useState(null);
    const bulkDelete = (selectedRow) => {
        setShowDeleteAlert(true);

        setData((prevState) => {
            return {
                ...prevState,
                selectedRows: selectedRow,
            };
        });
    };

    const getPdf = (data) => {
        // update download id with data

        dispatch({
            payload: [{ type: UPDATE_PROP, prop: "downloadId", value: data }],
        });

        getAuditPdf(
            (res) => {
                const url = window.URL.createObjectURL(
                    new Blob([res.data], { type: "application/pdf" })
                );
                const link = document.createElement("a");
                link.setAttribute("href", url);
                // download file with its name
                link.setAttribute("download", `audit.pdf`);
                document.body.appendChild(link);
                link.click();
            },
            (e) => {
                addToast({
                    type: ERROR,
                    description: e.message,
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: "downloadId", value: null }],
                });
            },
            { audit_id: data }
        );
    };
    const HandleCloseDelete = () => {
        setShowDeleteAlert(false);
    };

    const actions = () => {
        return {
            bulkDelete: bulkDelete,
            getPdf: getPdf,
        };
    };

    const HandelDelete = () => {
        const selectedArray = data.selectedRows.map((d) => d.id);
        // update modal loading
        dispatch({
            payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: true }],
        });

        deleteAudits(
            (res) => {
                let arrResults = [...data.results];
                selectedArray.map((d) => {
                    const index = arrResults.findIndex((x) => {
                        console.log(x.id);
                        return x.id === d;
                    });
                    arrResults.splice(index, 1);
                });
                setData((prevState) => {
                    let newData = {
                        results: [...arrResults],
                        total: prevState.total - 1,
                    };
                    if (arrResults.length == 0) {
                        newData = {
                            ...newData,
                            params: {
                                ...prevState.params,
                                page: prevState.params.page - 1,
                            },
                        };
                    }
                    return {
                        ...prevState,
                        ...newData,
                    };
                });
                HandleCloseDelete();
                addToast({
                    type: "success",
                    description: "Audits Deleted Successfully.",
                    title: "Success",
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: false }],
                });
            },
            { ids: selectedArray }
        );
    };
    let columns = useMemo(
        () => AuditColumn(actions(), state.downloadId, t),
        [i18n.language, state.downloadId]
    );

    return {
        actions: {
            setData,
            setShowDeleteAlert,
            HandleCloseDelete,
            HandelDelete,
        },
        data,
        error,
        showDeleteAlert,
        columns,
    };
};

export default Logic;
