import React from "react";
import { useTranslation } from "react-i18next";
import TableLayout from "../../components/general/table-layout/TableLayout";
import useLogic from "../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";
import HelpForm from "../HelpForm/HelpForm ";

const Subscriptions = () => {
    const { columns, data, setData } = useLogic({
        INITIAL_STATE,
        Logic,
    });
    const { t } = useTranslation();
    const filterData = [
        {
            key: "plans.name",
            label: t("plan"),
            sign: [{ value: "contains", label: t("Contains") }],
        },
        {
            key: "subscriptions.status",
            label: t("Status"),
            sign: [{ value: "contains", label: t("Contains") }],
        },
        {
            key: "subscriptions.number_of_users",
            label: t("Users"),
            sign: [{ value: "contains", label: t("Contains") }],
        },
        {
            key: "subscriptions.subscription_end_date",
            label: t("Expiry date"),
            sign: [{ value: "is_equal", label: t("Equals") }],
        },

        // ... other filters
    ];

    const filterKeys = {
        "plans.name": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("Contains") },
                { value: "is_equal", label: t("Equals") },
                // ... other signs
            ],
        },
        "subscriptions.status": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("Contains") },
                { value: "is_equal", label: t("Equals") },
                // ... other signs
            ],
        },
        "subscriptions.number_of_users": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("Contains") },
                { value: "is_equal", label: t("Equals") },
                // ... other signs
            ],
        },
        "subscriptions.subscription_end_date": {
            type: "date",
            dropdown: false,
            sign: [
                { value: "is_equal", label: t("Equals") },
                { value: "is_not_equal", label: t("Not Equals") },
                { value: "is_between", label: t("Is Between") },
                { value: "greater_than", label: t("Greater Than") },
                { value: "less_than", label: t("Less Than") },
                {
                    value: "greater_than_or_equal_to",
                    label: t("Greater Than Or Equal"),
                },
                { value: "less_than_or_equal_to", label: t("Less Than Or Equal") },

                // ... other signs
            ],
        },
    };

    return (
        <div style={{ marginTop: 25 }}>
            <TableLayout
                tableColumns={columns}
                setData={setData}
                title={`${t("subscriptions")} (${data.total})`}
                showButton={false}
                data={data}
                filterData={{ filterData }}
                filterKeys={{ filterKeys }}
                showFilter={true}
            />
            {<HelpForm />}
        </div>
    );
};
export default Subscriptions;
