import { $authAxios, $axios, $securedAxios } from "../axios";
export const loginCompanies = (onSuccess, onError, onFinally, payload) => {
  $axios
    .post(`/login/companies`, {
      username: payload.username,
      password: payload.password,
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const login = (onSuccess, onError, onFinally, payload) => {
  $axios
    .post(`/login`, {
      // client_id: "9802a821-c958-48b7-bb4b-89bd55c9b41c",
      // client_secret: "B5S1AgSFOXmpiXai8VRgnDuYEeCBt3OM3SJy27cf",
      // grant_type: "password",
      username: payload.username,
      password: payload.password,
      company_name: payload.company_name,
    })
    .then((response) => {
      onSuccess(response);
      //       console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const resetPassword = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/user/changepassword`, {
      email: payload.email,
      password: payload.password,
      password_reset_token: payload.password_reset_token,
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const register = (onSuccess, onError, onFinally, payload) => {
  $authAxios
    .post(`/registercompany`, {
      ...payload,
    })
    .then((response) => {
      onSuccess(response);
      //       console.log(response);
    })
    .catch((error) => {
      onError(error);
      // console.log(error);
    })
    .finally(() => {
      onFinally();
    });
};
// export const fetchfilterAssetHistory = (
//   onSuccess,
//   onError,
//   onFinally,
//   payload
// ) => {
//   $securedAxios
//     .get(`/admin/asset-histories/get/filtercolumns`)
//     .then((response) => {
//       onSuccess(response);
//     })
//     .catch((error) => {
//       onError(error);
//     })
//     .finally(() => {
//       onFinally();
//     });
// };

export const requestOtp = (onSuccess, onError, onFinally, payload) => {
  $axios
    .post(`/user/request-otp`, {
      email: payload.email,
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const verifyOtp = (onSuccess, onError, onFinally, payload) => {
  // console.log(payload);
  $axios
    .post(`/user/verify-otp`, {
      otp: payload.otp,
      email: payload.email,
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const checkValidUrl = (onSuccess, onError, onFinally, payload) => {
  $axios
    .get(`/auth/check_token_uid`, { params: { ...payload } })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const fetchAllUsers = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .get(`/user/get-all`, {
      params: {
        pageSize: payload?.pageSize,
      },
    })
    .then((response) => {
      onSuccess(response);
      //       console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const fetchAllUsersSchedule = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  $securedAxios
    .post(`/user/get/schedule`, 
      payload
    )
    .then((response) => {
      onSuccess(response);
      //       console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const fetchUsers = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/user/get?page=${payload.page}`, {
      filters: payload.filters,
    })
    .then((response) => {
      onSuccess({
        ...response.data.response,
      });
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const deleteUser = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/user/delete/${payload.id}`)
    .then((response) => {
      onSuccess(response);
      //       console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const fetchSpecificUser = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .get(`/user/get/${payload.id}`)
    .then((response) => {
      onSuccess(response);
      //       console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const updateOrCreateUser = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/user/store`, {
      ...payload,
    })
    .then((response) => {
      onSuccess(response);
      //       console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const changePassword = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/user/changepassword`, {
      ...payload,
    })
    .then((response) => {
      onSuccess(response);
      //       console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const fetchLoggedinUser = (onSuccess, onError, onFinally) => {
  $securedAxios
    .get(`/loggedin/userinfo`)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
      // console.log(error)
    })
    .finally(() => {
      onFinally();
    });
};
