import { useNavigate } from "react-router-dom";
import { login, loginCompanies } from "../../api/services/UserServices";
import { encrypted, extractErrorMessage } from "../../helpers/Common";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../store/ActionTypes";
import { fecthPlans, submitRenewSubscription } from "../../api/services/SettingsServices";
import { useEffect } from "react";
import { ERROR } from "../../components/general/app-toast/AppToast";

export const INITIAL_STATE = {
    loading: {
        loadingPlans: false,
    },
    selectedPeriod: 0,
    users: 0,
    data: [],
    loading: false,
};
const Logic = (dispatch, state) => {
    const navigate = useNavigate();
    const { addToast } = UseAppToast();

    const toggleLoading = (prop, value) => {
        dispatch({
            payload: [{ type: UPDATE_PROP, prop, value }],
        });
    };
    const getPlans = () => {
        toggleLoading("loading.loadingPlans", true);
        fecthPlans(
            (response) => {
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: "data", value: response.data }],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                toggleLoading("loading.loadingPlans", false);
            },
            {}
        );
    };

    const handleSubmit = () => {
        toggleLoading("loading.loadingSubmit", true);
        let param = {
            plan_id: state.data[state.selectedPeriod].plans[state.selectedPlan].plan_id,
            plan_pricing_id:
                state.data[state.selectedPeriod].plans[state.selectedPlan].plan_pricing_id,
            number_of_users: state.users,
        };
        submitRenewSubscription(
            (response) => {
                navigate("/success", {
                    state: {
                        message:
                            "Thank you for your subscription request! We’ve received it and will be in touch shortly. ",
                        nextNavigation: "/",
                    },
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                toggleLoading("loading.loadingSubmit", false);
            },
            param
        );
    };

    useEffect(() => {
        getPlans();
    }, []);
    return { actions: { handleSubmit } };
};

export default Logic;
