import React from "react";
import { useTranslation } from "react-i18next";
import AppButton from "../../components/general/app-button/AppButton";
import AppInput from "../../components/general/app-input/AppInput";
import Card from "../../components/general/card/Card";
import Label from "../../components/general/label/Label";
import LoadingSpinner from "../../components/general/loading-spinner/LoadingSpinner";
import NumberWithLabel from "../../components/general/number-with-label/NumberWithLabel";
import TableLayout from "../../components/general/table-layout/TableLayout";
import Title from "../../components/general/title/Title";
import TotalContainer from "../../components/general/total-container/TotalContainer";
import ValidationProvider from "../../components/general/validation-provider/ValidationProvider";
import useLogic from "../../helpers/hooks/use-logic";
import useValidator from "../../helpers/hooks/use-validator";
import Logic, { INITIAL_STATE } from "./logic";
import Styles from "./settings.module.css";

const Settings = () => {
    const { state, updateState, handleUpdate, columns, data, setData } = useLogic({
        INITIAL_STATE,
        Logic,
    });
    const { t } = useTranslation();
    const filterData = [
        {
            key: "plans.name",
            label: t("Plan"),
            sign: [{ value: "contains", label: t("Contains") }],
        },
        {
            key: "subscriptions.status",
            label: t("Status"),
            sign: [{ value: "contains", label: t("Contains") }],
        },
        {
            key: "subscriptions.number_of_users",
            label: t("Users"),
            sign: [{ value: "contains", label: t("Contains") }],
        },
        {
            key: "subscriptions.subscription_end_date",
            label: t("Expiry date"),
            sign: [{ value: "is_equal", label: t("Equals") }],
        },

        // ... other filters
    ];

    const filterKeys = {
        "plans.name": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("Contains") },
                { value: "is_equal", label: t("Equals") },
                // ... other signs
            ],
        },
        "subscriptions.status": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("Contains") },
                { value: "is_equal", label: t("Equals") },
                // ... other signs
            ],
        },
        "subscriptions.number_of_users": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("Contains") },
                { value: "is_equal", label: t("Equals") },
                // ... other signs
            ],
        },
        "subscriptions.subscription_end_date": {
            type: "date",
            dropdown: false,
            sign: [
                { value: "is_equal", label: t("Equals") },
                { value: "is_not_equal", label: t("Not Equals") },
                { value: "is_between", label: t("Is Between") },
                { value: "greater_than", label: t("Greater Than") },
                { value: "less_than", label: t("Less Than") },
                {
                    value: "greater_than_or_equal_to",
                    label: t("Greater Than Or Equal"),
                },
                { value: "less_than_or_equal_to", label: t("Less Than Or Equal") },

                // ... other signs
            ],
        },
    };

    const { form } = state;
    const { errors, onBlur, onChange, handleSubmit } = useValidator({
        defaultValues: {
            ...state.form,
        },
        setValue: (nextFormState) => {
            updateState({
                prop: "form",
                value: nextFormState,
            });
        },
        onSubmit: handleUpdate,
        validations: {
            contact_person_name: { required: true, validation_name: "Number" },
            job_title: { required: true, validation_name: "Postion" },
            contact_person_email: {
                required: true,
                validation_name: "Email",
                isEmail: true,
            },
            contact_person_mobile: { required: true, validation_name: "Mobile" },
            contact_person_whatsapp: { required: false, validation_name: "WhatsApp" },
        },
    });

    return (
        <div className={Styles.container}>
            <div className={Styles.title}>
                <Title title={t("settings")}></Title>
            </div>
            <Card style={{ padding: "20px 10px" }}>
                <LoadingSpinner isLoading={state.pageLoading} error={state.error}>
                    {/* <form> */}
                    <div className={Styles.formContainer}>
                        <div className={Styles.sectionContainer}>
                            <Title title={t("contactPerson")} border={true} />
                            <div style={{ marginTop: "25px" }}>
                                <div className={Styles.groupControl}>
                                    <div className={Styles.inputControl}>
                                        <ValidationProvider
                                            error={errors.contact_person_name}
                                            render={({ requiredTitle }) => (
                                                <Label
                                                    name={requiredTitle(t("name"))}
                                                    className={Styles.label}
                                                >
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        value={form?.contact_person_name}
                                                        onChange={(e) =>
                                                            onChange(
                                                                "contact_person_name",
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={(e) =>
                                                            onBlur("contact_person_name")
                                                        }
                                                    />
                                                </Label>
                                            )}
                                        />
                                    </div>
                                    <div className={Styles.inputControl}>
                                        <ValidationProvider
                                            error={errors.contact_person_email}
                                            render={({ requiredTitle }) => (
                                                <Label
                                                    name={requiredTitle(t("email"))}
                                                    className={Styles.label}
                                                >
                                                    <AppInput
                                                        type="email"
                                                        className={Styles.input}
                                                        value={form?.contact_person_email}
                                                        onChange={(e) =>
                                                            onChange(
                                                                "contact_person_email",
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={(e) =>
                                                            onBlur("contact_person_email")
                                                        }
                                                    />
                                                </Label>
                                            )}
                                        />
                                    </div>
                                    <div className={Styles.inputControl}>
                                        <ValidationProvider
                                            error={errors.job_title}
                                            render={({ requiredTitle }) => (
                                                <Label name={t("position")} className={Styles.label}>
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        value={form?.job_title}
                                                        onChange={(e) =>
                                                            onChange("job_title", e.target.value)
                                                        }
                                                        onBlur={(e) => onBlur("job_title")}
                                                    />
                                                </Label>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className={Styles.groupControl}>
                                    <div className={Styles.inputControl}>
                                        <ValidationProvider
                                            error={errors.contact_person_mobile}
                                            render={({ requiredTitle }) => (
                                                <Label
                                                    name={requiredTitle(t("mobile"))}
                                                    className={Styles.label}
                                                >
                                                    <AppInput
                                                        type="number"
                                                        className={Styles.input}
                                                        value={form?.contact_person_mobile}
                                                        onChange={(e) =>
                                                            onChange(
                                                                "contact_person_mobile",
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={(e) =>
                                                            onBlur("contact_person_mobile")
                                                        }
                                                    />
                                                </Label>
                                            )}
                                        />
                                    </div>

                                    <div className={Styles.inputControl}>
                                        <ValidationProvider
                                            error={errors.contact_person_whatsapp}
                                            render={({ requiredTitle }) => (
                                                <Label
                                                    name={t("whatsApp")}
                                                    className={Styles.label}
                                                >
                                                    <AppInput
                                                        type="number"
                                                        className={Styles.input}
                                                        value={form?.contact_person_whatsapp}
                                                        onChange={(e) =>
                                                            onChange(
                                                                "contact_person_whatsapp",
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={(e) =>
                                                            onBlur("contact_person_whatsapp")
                                                        }
                                                    />
                                                </Label>
                                            )}
                                        />
                                    </div>
                                    <div className={Styles.inputControl}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={Styles.actionButton}>
                        <AppButton
                            className="primaryButton"
                            onClick={handleSubmit}
                            value={t("save")}
                            isLoading={state.saveLoading}
                            type="button"
                        />
                        <AppButton
                            className="whiteButton"
                            onClick={() => {}}
                            value={t("cancel")}
                            style={{ margin: "0px 15px" }}
                        />
                    </div>
                    <br />
                    {/* </form> */}
                    <div className={Styles.formContainer}>
                        <div
                            className={Styles.section}
                            style={{
                                flex: "1",
                            }}
                        >
                            <div className={Styles.sectionContainer}>
                                <Title title={t("companyDetails")} border={true} />
                                {!state.customerLoading ? (
                                    <div>
                                        <div className={Styles.groupControl}>
                                            <div className={Styles.inputControl}>
                                                <Label name={t("name")} className={Styles.label}>
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        onChange={(e) => {}}
                                                        value={state.settingsData?.name}
                                                        disabled={true}
                                                    />
                                                </Label>
                                            </div>
                                            <div className={Styles.inputControl}>
                                                <Label name={t("commReg")} className={Styles.label}>
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        onChange={(e) => {}}
                                                        value={
                                                            state.settingsData
                                                                ?.commercial_registration
                                                        }
                                                        disabled={true}
                                                    />
                                                </Label>
                                            </div>
                                            <div className={Styles.inputControl}>
                                                <Label name={t("taxCard")} className={Styles.label}>
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        onChange={(e) => {}}
                                                        value={state.settingsData?.tax_registration}
                                                        disabled={true}
                                                    />
                                                </Label>
                                            </div>
                                        </div>

                                        <div className={Styles.groupControl}>
                                            <div className={Styles.inputControl}>
                                                <Label
                                                    name={t("numberofEmployees")}
                                                    className={Styles.label}
                                                >
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        onChange={(e) => {}}
                                                        value={
                                                            state.settingsData?.number_of_employees
                                                        }
                                                        disabled={true}
                                                    />
                                                </Label>
                                            </div>
                                            <div className={Styles.inputControl}>
                                                <Label name={t("sector" )}className={Styles.label}>
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        onChange={(e) => {}}
                                                        value={state.settingsData?.sector}
                                                        disabled={true}
                                                    />
                                                </Label>
                                            </div>
                                            <div className={Styles.inputControl}>
                                                <Label name={t("industry")} className={Styles.label}>
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        onChange={(e) => {}}
                                                        value={state.settingsData?.industry}
                                                        disabled={true}
                                                    />
                                                </Label>
                                            </div>
                                        </div>
                                        {/* <div className={Styles.groupControl}> */}
                                        {/* comment active users for now  */}
                                        {/* <div className={Styles.inputControl}>
                        <Label name="Active Users" className={Styles.label}>
                          <AppInput
                            type="text"
                            className={Styles.input}
                            onChange={(e) => {}}
                            value={state.settingsData.activeUsers}
                            disabled={true}
                          />
                        </Label>
                      </div> */}
                                        {/* </div> */}

                                        <div className={Styles.groupControl}>
                                            <Label name={t("address") }className={Styles.label}>
                                                <AppInput
                                                    onChange={() => {}}
                                                    renderProp={({ onChange }) => (
                                                        <textarea
                                                            onChange={(e) => {}}
                                                            value={state.settingsData?.address}
                                                            className={Styles.input}
                                                            style={{ minHeight: "73px" }}
                                                            disabled={true}
                                                        ></textarea>
                                                    )}
                                                />
                                            </Label>
                                        </div>
                                    </div>
                                ) : (
                                    <div>Loading...</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <br />
                </LoadingSpinner>
            </Card>
        </div>
    );
};
export default Settings;
