import { useContext, useEffect, useReducer } from "react";
import { requestOtp } from "../../api/services/UserServices";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../store/ActionTypes";
import { useNavigate } from "react-router-dom";
import { extractErrorMessage } from "../../helpers/Common";
export const INITIAL_STATE = {
    username: "",
    loading: false,
};
const Logic = (dispatch, state) => {
    const { addToast } = UseAppToast();
    const navigate = useNavigate();
    const handleSubmit = () => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `loading`,
                    value: true,
                },
            ],
        });
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(state.username)) {
            requestOtp(
                async (res) => {
                    addToast({
                        type: "success",
                        description: "Verification code has been sent to your email",
                        title: "Success",
                    });
                    navigate(
                        // `/verify-email?uid=${res.data.response.uid}&token=${res.data.response.token}`
                        // `/verify-email?token=${res.data.response.password_reset_token}`
                        "/verify-email",
                        {
                            state: {
                                token: res.data.response.password_reset_token,
                                email: state.username,
                            },
                        }
                    );
                },
                (error) => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `loading`,
                                value: false,
                            },
                        ],
                    });
                    addToast({
                        type: "error",
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                },
                () => {},
                {
                    email: state.username,
                }
            );
        } else {
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: `loading`,
                        value: false,
                    },
                ],
            });
            addToast({
                type: "error",
                description: "Email Address not Valid",
                title: "Error",
            });
        }
    };
    return { actions: { handleSubmit } };
};

export default Logic;
