import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    fetchSpecificDepartment,
    updateOrCreateDepartment,
} from "../../../../api/services/DepartmentServices";
import { ERROR, SUCCESS } from "../../../../components/general/app-toast/AppToast";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
import { extractErrorMessage } from "../../../../helpers/Common";

export const INITIAL_STATE = {
    form: {
        name: "",
        shortName: "",
    },
    saveLoading: false,
    pageLoading: false,
    error: null,
};
const Logic = (dispatch, state) => {
    const navigate = useNavigate();
    let [params, setParams] = useSearchParams();
    const { addToast } = UseAppToast();

    const handleUpdateOrCreate = () => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `saveLoading`,
                    value: true,
                },
            ],
        });
        let payload = { name: state.form.name, short_name: state.form.shortName };
        if (params.get("id")) {
            payload = { ...payload, id: params.get("id") };
        }
        updateOrCreateDepartment(
            (res) => {
                let message = "Department is added successfully";
                if (params.get("id")) {
                    message = "Department is updated successfully";
                }
                addToast({
                    type: SUCCESS,
                    description: message,
                    title: "Success",
                });
                navigate("/portal/departments");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                // dispatch({
                //   payload: [
                //     {
                //       type: UPDATE_PROP,
                //       prop: `saveLoading`,
                //       value: false,
                //     },
                //   ],
                // });
            },
            {
                ...payload,
            }
        );
    };
    useEffect(() => {
        if (params.get("id")) {
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: `pageLoading`,
                        value: true,
                    },
                ],
            });

            fetchSpecificDepartment(
                (res) => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `form.name`,
                                value: res.data.response.name,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.shortName`,
                                value: res.data.response.short_name,
                            },
                        ],
                    });
                },
                (error) => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `error`,
                                value: "cannot load this page",
                            },
                        ],
                    });
                    addToast({
                        type: ERROR,
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                },
                () => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `pageLoading`,
                                value: false,
                            },
                        ],
                    });
                },
                { id: params.get("id") }
            );
        }
    }, []);
    return { actions: { handleUpdateOrCreate, navigate }, params };
};

export default Logic;
