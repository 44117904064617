import React from "react";
import Pagination from "react-bootstrap/Pagination";
import AppInput from "../../general/app-input/AppInput";
import { useTranslation } from "react-i18next";
const TablePagination = ({
  dataLength,
  page,
  gotoPage,
  canPreviousPage,
  pageOptions,
  pageSize,
  pageIndex,
  previousPage,
  nextPage,
  canNextPage,
  setPageSize,
  manualPageSize,
  pageCount,
}) => {
  const { t } = useTranslation();
  const arrayPageIndex =
    pageIndex - 2 < 0
      ? pageOptions.slice(0, pageIndex + 3)
      : pageOptions.slice(pageIndex - 2, pageIndex + 3);
  // console.log(pageIndex);
  // console.log(pageSize);
  // console.log(arrayPageIndex);
  return (
    <>
      <Pagination>
        <Pagination.First
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        />
        <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

        {arrayPageIndex.map((i) => (
          <Pagination.Item
            className="pagination__item"
            active={pageIndex === i}
            key={i}
            onClick={() => gotoPage(i)}
          >
            {i + 1}
          </Pagination.Item>
        ))}

        <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
        <Pagination.Last
          onClick={() => gotoPage(pageOptions.length - 1)}
          disabled={!canNextPage}
        />
        <Pagination.Item>
          {`${t("showing")} ${pageSize * pageIndex + 1} ${t("to")} ${Math.min(
            Math.max(pageSize * pageIndex + 1, pageSize * pageIndex + pageSize),
            dataLength
          )} ${t("of")} ${dataLength}`}
        </Pagination.Item>
        {manualPageSize.length > 1 && (
          <Pagination.Item className="pagination__item">
            {/* <FormGroup className="pagination__select-form "> */}
            <AppInput
              className="pagination__item pagination-info"
              type="select"
              name="select"
              id="exampleSelect"
              value={pageSize}
              onChange={(event) => {
                setPageSize(Number(event.target.value));
              }}
            >
              {manualPageSize.map((item) => (
                <option
                  className="pagination__item pagination__item-option"
                  key={item}
                  value={item}
                >
                  Show {item}
                </option>
              ))}
            </AppInput>
            {/* </FormGroup> */}
          </Pagination.Item>
        )}
      </Pagination>
    </>
  );
};

export default TablePagination;
