import SingleInput from "../single-input/SingleInput";
import useOtpInput from "../../../../helpers/hooks/use-otp-input";

const OTPInput = ({
  length = 4,
  isNumberInput = false,
  autoFocus = true,
  disabled = false,
  onChangeOTP,
  inputClassName,
  inputStyle,
  ...rest
}) => {
  const {
    otpValues,
    activeInput,
    handleOnFocus,
    handleOnChange,
    handleOnKeyDown,
    handleOnKeyPress,
    onBlur,
    handleOnPaste,
  } = useOtpInput({ onChangeOTP, isNumberInput, length });

  return (
    <div {...rest}>
      {Array(length)
        .fill("")
        .map((_, index) => (
          <SingleInput
            key={`SingleInput-${index}`}
            type={isNumberInput ? "number" : "text"}
            focus={activeInput === index}
            value={otpValues && otpValues[index]}
            autoFocus={autoFocus}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onKeyPress={handleOnKeyPress}
            onBlur={onBlur}
            onPaste={handleOnPaste}
            style={inputStyle}
            className={inputClassName}
            disabled={disabled}
          />
        ))}
    </div>
  );
};
export default OTPInput;
