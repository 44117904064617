import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Styles from "./DisplayFilters.module.css";
import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const DisplayFilters = ({
  filters,
  handleDelete,
  filterContainerStyle = {},
  signLabelAccessor = "signLabel",
}) => {
  return (
    <>
      {filters.map((item, index) => (
        <div
          className={Styles.filterContainer}
          style={{ marginLeft: index > 0 ? 3 : 0, ...filterContainerStyle }}
          key={index}
        >
          {console.log(item)}
          {item.label} {item[signLabelAccessor].replace("_", " ")}{" "}
          {item.dropdownLabel
            ? item.dropdownLabel
            : item.value
            ? ` "${item.value}"`
            : ""}
          {item.value2 && ` "${item.value2}"`}
          <FontAwesomeIcon
            icon={faTimes}
            className={Styles.filterIcon}
            style={{ color: "white", fontSize: "16px", marginLeft: 5 }}
            onClick={() => {
              handleDelete(index);
            }}
          />
        </div>
      ))}
    </>
  );
};

export default DisplayFilters;
