import { useState } from "react";

export function SlidingForm(steps) {
  const [step, setStep] = useState(0);

  const nextStep = () => setStep(step + 1 < steps.length ? step + 1 : step);
  const prevStep = () => setStep(step - 1 > -1 ? step - 1 : step);
  const goTo = (index) => setStep(index);

  //   const Step = steps[step];

  return {
    step,
    currentStep: steps[step],
    nextStep,
    prevStep,
    goTo,
    steps
  };
}
