import classes from "./Label.module.css";

const Label = ({ children, name, classText, className, ...rest }) => {
  return (
    <div className={`${classes.label} ${className}`} {...rest}>
      {name && <div className={`${classes.name} ${classText}`}>{name}</div>}
      {children}
    </div>
  );
};

export default Label;
