export const INITIAL_STATE = {
  results: [],
  total: 0,
  perPage: 15,
  selectedRows: [],
  params: {
    page: 0,
    filters: [],
    groupBy: [],
  },
  loading: false,
};
