export const requiredValidator = (value, field) => {
    if (
        !value ||
        (typeof value === "object" && Object.keys(value).length == 0) ||
        (typeof value === "string" && !value.trim())
    ) {
        return `${field} is required`;
    }

    return "";
};
export const emailValidator = (value, field) => {
    if (
        !new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(value)
    ) {
        return `${field} is invalid email`;
    }
    return "";
};
export const patternValidator = (pattern, value, field) => {
    if (!new RegExp(pattern).test(value)) {
        return `${field} is invalid format`;
    }
    return "";
};
export const maxLengthValidator = (length, value, field) => {
    if (value.length > length) {
        return `Max length is ${length}`;
    }
    return "";
};
export const minLengthValidator = (length, value, field) => {
    if (value.length < length) {
        return `Min length is ${length}`;
    }
    return "";
};
export const numericValidator = (value, field) => {
    if (isNaN(parseFloat(value))) {
        return `${field} is not a valid number`;
    }
    return "";
};
export const minValidator = (min = 0, value, field) => {
    if (value < min) {
        return `Min value is ${min} `;
    }
    return "";
};
export const maxValidator = (max = 0, value, field) => {
    if (value > max) {
        return `Max value is ${max} `;
    }
    return "";
};
export const customValidate = (fn, value, field) => {
    if (fn(value)) {
        return `${field} is inValid`;
    }
    return "";
};

export const imageValidator = (image, field, required = false) => {
    if (required) {
        if (!image) {
            return `${field} is required`;
        }
    }

    if (typeof image === "object" && image != null) {
        if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            return `${field} is inValid `;
        }
        if (image.size < 10000) {
            return `The minimum file size is 10KB `;
        }
        if (image.size > 500000) {
            return `The maximum file size is 500KB `;
        }
    }

    return "";
};
export const exactValidator = (value1, field1, value2, field2) => {
    if (value1 != value2) {
        return `${field1} doesn't match ${field2}  `;
    }
    return "";
};
