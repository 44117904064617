import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { fetchAllRoles } from "../../../api/services/RoleServices";
import { fetchSpecificUser, updateOrCreateUser } from "../../../api/services/UserServices";
import { ERROR, SUCCESS } from "../../../components/general/app-toast/AppToast";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../store/ActionTypes";
import { extractErrorMessage } from "../../../helpers/Common";

export const INITIAL_STATE = {
    form: {
        first_name: "",
        last_name: "",
        short_name: "",
        job_title: "",
        email: "",
        selectedRole: [],
        sendWelcomeEmail: false,
        is_admin: false,
    },
    saveLoading: false,
    pageLoading: false,
    roles: [],
    error: null,
};
const Logic = (dispatch, state) => {
    const navigate = useNavigate();
    const { addToast } = UseAppToast();
    const { id } = useParams();
    const handleUpdateOrCreate = () => {
        console.log("update");
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `saveLoading`,
                    value: true,
                },
            ],
        });
        const { selectedRole, ...rest } = state.form;
        let payload = {
            ...rest,
            roles: selectedRole,
            name: `${rest.first_name} ${rest.last_name}`,
        };
        if (id) {
            const { password, ...rest } = payload;
            payload = { ...rest, id: id };
        }
        updateOrCreateUser(
            (res) => {
                let message = "User is added successfully";
                if (id) {
                    message = "User is updated successfully";
                }
                addToast({
                    type: SUCCESS,
                    description: message,
                    title: "Success",
                });
                navigate("/portal/users");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `saveLoading`,
                            value: false,
                        },
                    ],
                });
            },
            () => {},
            {
                ...payload,
            }
        );
    };
    useEffect(() => {
        if (id) {
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: `pageLoading`,
                        value: true,
                    },
                ],
            });

            fetchSpecificUser(
                (res) => {
                    console.log(res);
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `form.first_name`,
                                value: res.data.response.first_name,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.last_name`,
                                value: res.data.response.last_name,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.short_name`,
                                value: res.data.response.short_name,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.job_title`,
                                value: res.data.response.job_title,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.email`,
                                value: res.data.response.email,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.selectedRole`,
                                value: res.data.response.roles,
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.is_admin`,
                                value: res.data.response.is_admin,
                            },
                        ],
                    });
                },
                (error) => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `error`,
                                value: "cannot load this page",
                            },
                        ],
                    });
                    addToast({
                        type: ERROR,
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                },
                () => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `pageLoading`,
                                value: false,
                            },
                        ],
                    });
                },
                { id }
            );
        }
        fetchAllRoles(
            (res) => {
                console.log(res);
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `roles`,
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `error`,
                            value: "cannot load this page",
                        },
                    ],
                });
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {}
        );
    }, []);
    return { actions: { handleUpdateOrCreate, navigate }, id };
};

export default Logic;
