import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiSelect from "react-select";
import AppInput from "../../../components/general/app-input/AppInput";
import Card from "../../../components/general/card/Card";
import Label from "../../../components/general/label/Label";
import SimpleTable from "../../../components/general/simple-table/SimpleTable";
import Spacer from "../../../components/general/spacer/Spacer";
import Title from "../../../components/general/title/Title";
import useLogic from "../../../helpers/hooks/use-logic";
import Layout from "../components/layout/Layout";
import ScoreCard from "../components/score-card/ScoreCard";
import styles from "./OverviewNew.module.css";
import { useTranslation } from "react-i18next";

import {
  auditorActivityColumns,
  checklistAvgScoreColumns,
  filterSelectStyles,
  highScoreColumns,
  INITIAL_STATE,
  locationAvgScoreColumns,
  lowScoreColumns,
} from "./constants";
import Logic from "./logic";

const OverviewN = () => {
  const { state, updateState, actions } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
          }}
        >
          <Layout.Filter
            onClick={() => {
              actions?.filterData();
            }}
            filters={[
              {
                key: "From",
                value: state.dateFrom,
              },
              {
                key: "To",
                value: state.dateTo,
              },
              {
                key: "Audit Name",
                value: state.selectedAudit,
              },
              {
                key: "Location",
                value: state.selectedLocation,
              },
              {
                key: "Auditor",
                value: state.selectedAuditor,
              },
              {
                key: "Department",
                value: state.selectedDepartment,
              },
              {
                key: "Area",
                value: state.selectedArea,
              },
            ]}
          >
            <div className={styles.groupControl}>
              <Label
                name={t("from")}
                className={styles.label}
                classText={styles.labelText}
              >
                <div className={styles.inputContainer}>
                  <AppInput
                    onChange={(e) => {
                      updateState({ prop: "dateFrom", value: e.target.value });
                    }}
                    value={state.dateFrom}
                    onBlur={(e) => {
                      console.log(e);
                    }}
                    type="date"
                    containerStyle={{
                      height: "100%",
                      padding: "0px 10px",
                      position: "relative",
                    }}
                    className={styles.inputDate}
                  >
                    <span className={styles.openbutton}>
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        style={{ fontSize: 20, color: "var(--primary)" }}
                      />
                    </span>
                  </AppInput>
                </div>
              </Label>
            </div>
            <div className={styles.groupControl}>
              <Label
                name={t("to")}
                className={styles.label}
                classText={styles.labelText}
              >
                <div className={styles.inputContainer}>
                  <AppInput
                    onChange={(e) =>
                      updateState({ prop: "dateTo", value: e.target.value })
                    }
                    type="date"
                    value={state.dateTo}
                    containerStyle={{
                      height: "100%",
                      padding: "0px 10px",
                      position: "relative",
                    }}
                    className={styles.inputDate}
                  >
                    <span className={styles.openbutton}>
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        style={{ fontSize: 20, color: "var(--primary)" }}
                      />
                    </span>
                  </AppInput>
                </div>
              </Label>
            </div>
            <div className={styles.groupControl}>
              <Label
                name={t("auditName")}
                className={styles.label}
                classText={styles.labelText}
              >
                <div className={styles.inputContainer}>
                  <MultiSelect
                    options={state.audits.data.map((element, index) => ({
                      value: element.id,
                      label: element.name ?? "Audit " + index,
                    }))}
                    styles={filterSelectStyles}
                    value={state.selectedAudit}
                    onChange={(e) => {
                      console.log(e);
                      updateState({ prop: "selectedAudit", value: e });
                    }}
                    isMulti
                  />
                </div>
              </Label>
            </div>
            <div className={styles.groupControl}>
              <Label
                name={t("location")}
                className={styles.label}
                classText={styles.labelText}
              >
                <div className={styles.inputContainer}>
                  <MultiSelect
                    options={state.locations.data.map((element) => ({
                      value: element.id,
                      label: element.name,
                    }))}
                    styles={filterSelectStyles}
                    value={state.selectedLocation}
                    onChange={(e) => {
                      console.log(e);
                      updateState({ prop: "selectedLocation", value: e });
                    }}
                    isMulti
                  />
                </div>
              </Label>
            </div>
            <div className={styles.groupControl}>
              <Label
                name={t("auditor")}
                className={styles.label}
                classText={styles.labelText}
              >
                <div className={styles.inputContainer}>
                  <MultiSelect
                    options={state.auditors.data.map((element) => ({
                      value: element.id,
                      label: element.name,
                    }))}
                    styles={filterSelectStyles}
                    value={state.selectedAuditor}
                    onChange={(e) => {
                      console.log(e);
                      updateState({ prop: "selectedAuditor", value: e });
                    }}
                    isMulti
                  />
                </div>
              </Label>
            </div>
            <div className={styles.groupControl}>
              <Label
                name={t("department")}
                className={styles.label}
                classText={styles.labelText}
              >
                <div className={styles.inputContainer}>
                  <MultiSelect
                    options={state.departments.data.map((element) => ({
                      value: element.id,
                      label: element.name,
                    }))}
                    styles={filterSelectStyles}
                    value={state.selectedDepartment}
                    onChange={(e) => {
                      console.log(e);
                      updateState({ prop: "selectedDepartment", value: e });
                    }}
                    isMulti
                  />
                </div>
              </Label>
            </div>
            <div className={styles.groupControl}>
              <Label
                name={t("area")}
                className={styles.label}
                classText={styles.labelText}
              >
                <div className={styles.inputContainer}>
                  <MultiSelect
                    options={state.area.data.map((element) => ({
                      value: element.id,
                      label: element.name,
                    }))}
                    styles={filterSelectStyles}
                    value={state.selectedArea}
                    onChange={(e) => {
                      console.log(e);
                      updateState({ prop: "selectedArea", value: e });
                    }}
                    isMulti
                  />
                </div>
              </Label>
            </div>
          </Layout.Filter>
          <Layout.Body>
            <div className={styles.content}>
              <Card className={styles.filterButtons}>
                <Label
                  name={t("auditStatus")}
                  className={styles.label}
                  classText={styles.labelText}
                  style={{
                    minWidth: "10%",
                    display: "flex",
                    flexDirection: "row",
                    width: "auto",
                  }}
                >
                  <ScoreCard
                    text={t("scheduled")}
                    count={state.auditStatus.scheduled}
                    className="whiteButton"
                    highlighted
                    loading={state.loading.auditStatsLoading}
                  />
                  <Spacer width={7} />
                  <ScoreCard
                    text={t("pending")}
                    count={state.auditStatus.pending}
                    className="whiteButton"
                    loading={state.loading.auditStatsLoading}
                  />
                  <Spacer width={7} />
                  <ScoreCard
                    text={t("completed")}
                    count={state.auditStatus.completed}
                    className="whiteButton"
                    loading={state.loading.auditStatsLoading}
                  />
                  <Spacer width={7} />
                  <ScoreCard
                    text={t("missed")}
                    count={state.auditStatus.missed}
                    className="whiteButton"
                    loading={state.loading.auditStatsLoading}
                  />
                </Label>
                <Spacer width={7} />
                <Label
                  name={t("general")}
                  className={styles.label}
                  classText={styles.labelText}
                  style={{
                    minWidth: "10%",
                    display: "flex",
                    flexDirection: "row",
                    width: "auto",
                  }}
                >
                  <ScoreCard
                    text={t("activeAuditors")}
                    count={state.general.activeAuditors}
                    className="whiteButton"
                    loading={state.loading.activeAuditorLoading}
                  />
                  <Spacer width={7} />
                  <ScoreCard
                    text={t("Loc. Audited")}
                    count={state.general.auditedLocations}
                    className="whiteButton"
                    loading={state.loading.activeLocationLoading}
                  />
                </Label>
                <Spacer width={7} />
                <Label
                  name={t("actionPlans")}
                  className={styles.label}
                  classText={styles.labelText}
                  style={{
                    minWidth: "10%",
                    display: "flex",
                    flexDirection: "row",
                    width: "auto",
                  }}
                >
                  <ScoreCard
                    text={t("total")}
                    count={state.actionPlans.total}
                    className="whiteButton"
                    highlighted
                    loading={state.loading.actionPlanStatsLoading}
                  />
                  <Spacer width={7} />
                  <ScoreCard
                    text={t("open")}
                    count={state.actionPlans.open}
                    className="whiteButton"
                    loading={state.loading.actionPlanStatsLoading}
                  />
                  <Spacer width={7} />
                  <ScoreCard
                    text={t("overdue")}
                    count={state.actionPlans.overdue}
                    className="whiteButton"
                    loading={state.loading.actionPlanStatsLoading}
                  />
                  <Spacer width={7} />
                  <ScoreCard
                    text={t("closed")}
                    count={state.actionPlans.closed}
                    className="whiteButton"
                    loading={state.loading.actionPlanStatsLoading}
                  />
                </Label>
              </Card>
              <Spacer height={10} />
              <Card>
                <Title title={t("auditorsActivity")} />
                <SimpleTable
                  data={state.auiditorActivity}
                  columns={auditorActivityColumns}
                  total={state.auiditorActivity.length}
                  loading={state.loading.auiditorActivityLoading}
                />
              </Card>
              <Spacer height={10} />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <Card>
                    <Title title={t("checklistAvgScore")} />
                    <SimpleTable
                      data={state.checklistAvgScore}
                      columns={checklistAvgScoreColumns}
                      total={state.checklistAvgScore.length}
                      loading={state.loading.checklistAvgScoreLoading}
                    />
                  </Card>
                </div>
                <Spacer width={10} />
                <div style={{ flex: 1 }}>
                  <Card>
                    <Title title={t("locationsAvgScore")} />
                    <SimpleTable
                      data={state.locationAvgScore}
                      columns={locationAvgScoreColumns}
                      total={state.locationAvgScore.length}
                      loading={state.loading.locationAvgScoreLoading}
                    />
                  </Card>
                </div>
              </div>
              <Spacer height={10} />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <Card>
                    <Title title={t("highest10Scores")} />
                    <SimpleTable
                      data={state.highScores}
                      columns={highScoreColumns}
                      total={state.highScores.length}
                      loading={state.loading.highScoresLoading}
                    />
                  </Card>
                </div>
                <Spacer width={10} />
                <div style={{ flex: 1 }}>
                  <Card>
                    <Title title={t("lowest10Scores")} />
                    <SimpleTable
                      data={state.lowScores}
                      columns={lowScoreColumns}
                      total={state.lowScores.length}
                      loading={state.loading.lowScoresLoading}
                    />
                  </Card>
                </div>
              </div>
            </div>
          </Layout.Body>
        </div>
      </Layout>
    </>
  );
};
export default OverviewN;
