import styles from "./PlanCard.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import AppInput from "../../../../components/general/app-input/AppInput";
import Label from "../../../../components/general/label/Label";
import Spacer from "../../../../components/general/spacer/Spacer";
import AppButton from "../../../../components/general/app-button/AppButton";

const PlanCard = ({
    selected,
    plan,
    onPlanClick,
    onChangeUser,
    users,
    total,
    totalPerText,
    handleSubmit,
}) => {
    const { t } = useTranslation();
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: 15,
                boxShadow: "1px 1px 1px 1px #ccc",
                minWidth: 450,
                flex: 1,
                maxWidth: 450,
                // border: selected ? "5px  solid var(--primary)" : "",
            }}
        >
            {/* Card header */}
            <div
                style={{
                    display: "flex",
                    backgroundColor: "var(--primary)",
                    borderBottomWidth: 1,
                    borderBottomColor: "var(--lightGrey)",
                    borderBottomStyle: "solid",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    minHeight: 100,
                }}
            >
                <div style={{ fontSize: 45, fontFamily: "RubikBold", color: "white" }}>
                    {plan.name}
                </div>
            </div>
            {/* Card Body */}
            <div
                style={{
                    flexDirection: "column",
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    padding: 5,
                    display: "flex",
                    flex: 1,
                }}
            >
                {/* Price Section */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: 25,
                        paddingTop: 25,
                    }}
                >
                    <div style={{ fontSize: 40, fontFamily: "RubikMedium" }}>
                        {plan.price}
                        <span style={{ fontSize: 20, fontFamily: "RubikLight" }}>/mo</span>
                    </div>
                </div>
                {/* Features Section */}
                <div style={{ flex: 1 }}>
                    <div style={{ paddingBottom: 20 }}>
                        {plan.featuresList.map((f) => {
                            return <FeatureItem text={f} />;
                        })}
                    </div>
                </div>
                {/* User Input Section */}
                {selected ? (
                    <div
                        style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginBottom: 10,
                            justifySelf: "flex-end",
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <AppInput
                                    containerStyle={{ flex: 3 }}
                                    inputStyle={{
                                        border: "1px solid #ccc",
                                        borderRadius: 5,
                                        fontSize: 25,
                                        textAlign: "center",
                                    }}
                                    type={"number"}
                                    value={users}
                                    onChange={onChangeUser}
                                />
                                <Spacer style={{ flex: 0.5 }} />
                                <div style={{ flex: 1 }}>
                                    <span>Users</span>
                                </div>
                            </div>
                            <Spacer height={10} />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div style={{ flex: 3 }}>
                                    <span style={{ fontSize: 25 }}>
                                        ${parseFloat(total).toFixed(2)}
                                        <span style={{ fontSize: 18, fontFamily: "RubikLight" }}>
                                            /{totalPerText}
                                        </span>
                                    </span>
                                </div>
                                <AppButton
                                    className="primaryButton"
                                    onClick={handleSubmit}
                                    value="Let's Go"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                    >
                        <AppButton
                            className="primaryButton"
                            style={{ minWidth: 150, minHeight: 50 }}
                            onClick={onPlanClick}
                            value="Subscribe"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export default PlanCard;

const FeatureItem = ({ text, hideLine = false }) => {
    return (
        <div
            style={{
                borderBottom: hideLine ? "" : "1px var(--lightGrey) solid",
                marginBottom: 5,
                paddingBottom: 5,
            }}
        >
            <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 10, marginLeft: 5 }} />
            <span>{text}</span>
        </div>
    );
};
