import { faHeadset, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createTicket } from "../../api/services/TicketsServices";
import AppSelect from "../../components/general/app-select/AppSelect";
import { ERROR } from "../../components/general/app-toast/AppToast";
import Label from "../../components/general/label/Label";
import Title from "../../components/general/title/Title";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { getUserFromLS } from "../../helpers/Storage";
import styles from "./HelpForm.module.css";
import AttachmentIcon from "../analytics/action-plan-new/action-plan-list/components/AttachmentIcon/AttachmentIcon";
import { LANGUAGE } from "../../helpers/constants/StaticKeys";
import { extractErrorMessage } from "../../helpers/Common";

const HelpForm = () => {
    const { addToast } = UseAppToast();
    const { t } = useTranslation();

    // language
    const l = localStorage.getItem(LANGUAGE);

    const typeOptions = [
        {
            value: "support",
            label: "Ticket",
        },
        {
            value: "inquiry",
            label: "Inquiry",
        },
    ];
    const [type, setType] = useState({});
    const [sendLoading, setSendLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false); // State to toggle form visibility
    const user = getUserFromLS();

    const handleAttachmentChange = (e) => {
        const files = Array.from(e.target.files);
        // log file
        console.log(files);
        setAttachments((prev) => [...prev, ...files]);
    };

    const removeAttachment = (indexToRemove) => {
        setAttachments(attachments.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        const formData = new FormData();
        formData.append("type", type.value);
        formData.append("name", user?.name);
        formData.append("description", description);
        // formData.append("ticket_activity", JSON.stringify([{ message: description }]));
        attachments.forEach((attachment, index) => {
            formData.append(`attachments[]`, attachment);
        });
        console.log("FormData Contents:");
        for (const pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }
        setSendLoading(true);
        createTicket(
            (res) => {
                console.log(res.data.response);
                addToast({
                    type: "success",
                    description: "Message sent successfully",
                    title: "Success",
                });
                setIsFormOpen(false);
            },
            (error) => {
                console.log(error);
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                setSendLoading(false);
                if (description.trim() || attachments.length > 0) {
                    setDescription("");
                    setType("");
                    setAttachments([]);
                    setIsFormOpen(false);
                }
            },
            formData
        );
    };

    const toggleFormVisibility = () => {
        setIsFormOpen(!isFormOpen);
    };
    const isImage = (file) => file.type?.startsWith("image/");

    const getAttachmentURL = (attachment) => {
        if (attachment instanceof File || attachment instanceof Blob) {
            return URL.createObjectURL(attachment);
        }
        return attachment.url;
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };

    return (
        <div>
            {/* Floating Action Button (FAB) */}
            <button
                className={styles.fab}
                style={
                    l === "ar" ? { left: "20px", right: "unset" } : { right: "20px", left: "unset" }
                }
                onClick={toggleFormVisibility}
            >
                <FontAwesomeIcon icon={faHeadset} />
            </button>

            {/* Help Form Popup */}
            {isFormOpen && (
                <div
                    className={styles.helpFormPopup}
                    style={
                        l === "ar"
                            ? { left: "20px", right: "unset" }
                            : { right: "20px", left: "unset" }
                    }
                >
                    <div className={styles.helpFormContainer}>
                        <Title
                            title={t("howCanWeHelp?")}
                            border={true}
                            style={{
                                color: "var(--primary)",
                                fontSize: "16px",
                                textAlign: l === "ar" ? "right" : "left",
                                // text direction
                                direction: l === "ar" ? "rtl" : "ltr",
                            }}
                        />
                        <br />
                        <form onSubmit={handleKeyPress}>
                            <div className={styles.formGroup}>
                                <Label name={t("type")} className={styles.label}>
                                    <AppSelect
                                        options={typeOptions}
                                        // styles={filterSelectStyles}
                                        value={type}
                                        onChange={(e) => {
                                            setType(e);
                                            console.log(e?.target?.value);
                                            console.log(e);
                                        }}
                                    />
                                </Label>
                            </div>
                            <div className={styles.formGroup}>
                                <Label name={t("description")} className={styles.label}>
                                    <textarea
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        className={styles.formControl}
                                        rows="5"
                                    ></textarea>
                                </Label>
                            </div>
                            <div className={styles.formGroup}>
                                <div className={styles.attachmentsContainer}>
                                    {attachments.length > 0 && (
                                        <>
                                            <Label name={t("attachments")} className={styles.label}>
                                                <div className={styles.attachmentsPreview}>
                                                    {attachments.map((attachment, index) => (
                                                        <div
                                                            key={index}
                                                            className={styles.attachmentPreview}
                                                        >
                                                            <AttachmentIcon
                                                                onDelete={() => {
                                                                    removeAttachment(index);
                                                                }}
                                                                attachment={attachment}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </Label>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={styles.attachmentDiv}>
                                <button
                                    type="submit"
                                    className={styles.saveButton}
                                    onClick={handleSubmit}
                                    disabled={!(description && type)}
                                >
                                    {sendLoading ? t("loading") : t("send")}
                                </button>

                                <>
                                    <label
                                        htmlFor="attachment-input"
                                        className={styles.attachmentIcon}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPaperclip}
                                            style={{
                                                // margin depending on language
                                                marginLeft: l === "ar" ? "0" : "10px",
                                                marginRight: l === "ar" ? "10px" : "0",
                                            }}
                                        />
                                    </label>
                                    <input
                                        id="attachment-input"
                                        type="file"
                                        // accept=".png"
                                        accept=".doc,.docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png"
                                        multiple
                                        onChange={handleAttachmentChange}
                                        style={{ display: "none" }}
                                    />
                                </>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HelpForm;
