import { useEffect, useState } from "react";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import AppButton from "../app-button/AppButton";
import Card from "../card/Card";
import Title from "../title/Title";
import styles from "./TabsLayout.module.css";

const TabsLayout = ({
    tabsList,
    showTitle = true,
    title,
    horizontalTabs = false,
    pillsClassname,
    contentClassname,
    renderButton,
    setActiveLabel = (f) => {},
    renderContent,
    unmountOnExit = true,
}) => {
    const [key, setKey] = useState(tabsList[0]?.key);
    const renderTabs = (
        <div
            className={`${styles.pillsContainer} ${
                pillsClassname != undefined ? pillsClassname : ""
            }`}
            id={styles.pillsContainer}
        >
            <Nav variant="pills" className={horizontalTabs && "flex-column"}>
                {tabsList.map((item) => (
                    <Nav.Item key={item.key}>
                        <Nav.Link eventKey={item.key} className={styles.tabButtonContainer}>
                            <p
                                className={`${styles.tabButtonText} ${
                                    item.error ? styles.errorBadge : ""
                                }`}
                            >
                                {item.required && <label className={styles.errorBadge}>* </label>}
                                {item.label}
                            </p>
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
        </div>
    );
    return (
        <Tab.Container
            id="left-tabs-example"
            activeKey={key}
            onSelect={(k) => {
                setKey(k);
                setActiveLabel(k);
            }}
            unmountOnExit={unmountOnExit}
        >
            {tabsList.length && (
                <div className={horizontalTabs ? styles.container : ""}>
                    {showTitle ? (
                        <div className={styles.title}>
                            <Title title={title} border={true} renderProp={renderTabs}>
                                {/* <AppButton
                  className={buttonClassName}
                  style={buttonStyle}
                  onClick={buttonOnClick}
                  value={"Add"}
                  icon={buttonIcon}
                /> */}
                                {renderButton}
                            </Title>
                        </div>
                    ) : (
                        renderTabs
                    )}

                    <div className={contentClassname != undefined ? contentClassname : ""}>
                        <Tab.Content>
                            {tabsList.map((item) => (
                                <Tab.Pane
                                    eventKey={item.key}
                                    key={item.key}
                                    unmountOnExit={unmountOnExit}
                                >
                                    {item.renderContent ? item.renderContent : renderContent}
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </div>
                </div>
            )}
        </Tab.Container>
    );
};

export default TabsLayout;
