import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { useTranslation } from "react-i18next";
import ConfirmModal from "../../../components/general/confirm-modal/ConfrimModal";
import TableLayout from "../../../components/general/table-layout/TableLayout";
import useLogic from "../../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";

const Users = () => {
  const {
    state,
    handleDelete,
    handleCloseDelete,
    setData,
    navigate,
    columns,
    data,
    error,
  } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  const { t } = useTranslation();
  const filterData = [
    {
      key: "name",
      label: "Name",
      sign: [{ value: "contains", label: t("contains") }],
    },
    {
      key: "email",
      label: "Email",
      sign: [{ value: "contains", label: t("contains") }],
    },

    // ... other filters
  ];

  const filterKeys = {
    name: {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: t("contains") },
        { value: "is_equal", label: t("equals") },
        // ... other signs
      ],
    },
    email: {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: t("contains") },
        { value: "is_equal", label: t("equals") },
        // ... other signs
      ],
    },
  };
  return (
    <>
      {/* {console.log(data)} */}
      <TableLayout
        tableColumns={columns}
        title={`${t("users")} (${data.total})`}
        showButton={true}
        data={data}
        error={error}
        buttonValue={t("new")}
        buttonIcon={
          <FontAwesomeIcon icon={faPlus} style={{ margin: "0px 5px" }} />
        }
        buttonOnClick={() => navigate("/portal/add-user")}
        buttonStyle={{ color: "white" }}
        buttonClassName="primaryButton"
        showFilter={true}
        filterData={{ filterData }}
        filterKeys={{ filterKeys }}
        setData={setData}
      />
      {state.showDeleteAlert && (
        <ConfirmModal
          show={state.showDeleteAlert}
          onHide={() => handleCloseDelete()}
          onSave={() => handleDelete()}
          isLoading={state.deleteLoading}
        />
      )}
    </>
  );
};
export default Users;
