import AppButton from "../../../../../../components/general/app-button/AppButton";
import AppModal from "../../../../../../components/general/app-modal/AppModal";
import Label from "../../../../../../components/general/label/Label";
import Title from "../../../../../../components/general/title/Title";
import Styles from "./AddPermissionModal.module.css";
import Logic, { INITIAL_STATE } from "./logic";
import useLogic from "../../../../../../helpers/hooks/use-logic";
import AppSelect from "../../../../../../components/general/app-select/AppSelect";
import { useTranslation } from "react-i18next";


const AddPermissionModal = ({
  show,
  set,
  permissions,
  addPermissions,
  selectedPermission,
}) => {
  const { state, handleMultiChange } = useLogic({
    INITIAL_STATE,
    Logic,
    prop: { selectedPermission },
  });
  const { t } = useTranslation();

  return (
    <AppModal
      show={show}
      onHide={() => {
        set();
      }}
      className="modelContent"
      bodyStyle={{ paddingTop: 0 }}
      footerStyle={{ border: 0, paddingTop: 0 }}
      headerStyle={{ border: 0, paddingBottom: 0 }}
    >
      <div className={Styles.sectionContainer}>
        <Title title={`${t("add")} ${t("permission")}`} border={true} />
        <div className={Styles.groupInputs}>
          <div className={Styles.groupControl}>
            <Label name={t("permissions")} className={Styles.label}>
              <AppSelect
                options={permissions.map((element) => ({
                  value: element.id,
                  id: element.id,
                  name: element.name,
                  label: element.name,
                  type: element.type,
                  is_admin: element.is_admin,
                }))}
                styles={{
                  dropdownIndicator: (provided, state) => {
                    return {
                      ...provided,
                      color: "var(--primary)",
                      fontSize: 20,
                    };
                  },
                }}
                value={state.selectedPermission}
                onChange={handleMultiChange}
                isMulti={true}
              />
            </Label>
          </div>

          <div className={Styles.actionButton}>
            <AppButton
              className="primaryButton"
              onClick={() => {
                addPermissions(state.selectedPermission);
                set();
              }}
              value={t("save")}
              style={{ marginRight: "10px" }}
            />
            <AppButton
              className="whiteButton"
              onClick={() => {
                set();
              }}
              value={t("cancel")}
              style={{ marginRight: "10px" }}
            />
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default AddPermissionModal;
