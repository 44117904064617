import React from "react";
import AppButton from "../../components/general/app-button/AppButton";
import Logo from "../../components/logo/Logo";
import useLogic from "../../helpers/hooks/use-logic";
import OTPInput from "./components/otp-input/OTPInput";
import Logic, { INITIAL_STATE } from "./logic";
import styles from "./VerifyEmail.module.css";

const VerifyEmail = () => {
    const { state, updateState, handleVerify, handleResend } = useLogic({
        INITIAL_STATE,
        Logic,
    });

    return (
        <div className={styles.pageContainer}>
            <div className={styles.container}>
                <div className={styles.verifyContainer}>
                    <div className={styles.header}>
                        <Logo classes={styles.logoSize} />
                        <div className={styles.slogan}>Unleash Your Potential</div>
                    </div>
                    <div className={styles.verifySubContainer}>
                        <OTPInput
                            isNumberInput={true}
                            length={4}
                            className={styles.otpContainer}
                            inputClassName={styles.otpInput}
                            onChangeOTP={(otp) =>
                                updateState({
                                    prop: "otpValues",
                                    value: otp,
                                })
                            }
                        />
                        <div className={styles.actionRow}>
                            <AppButton
                                value="Resend OTP"
                                style={{
                                    color: "white",
                                    fontSize: 18,
                                    textDecoration: "underline",
                                }}
                                disabled={!state.resend}
                                onClick={() => handleResend()}
                            />

                            {state.timer > 0 && (
                                <div className={styles.countDown}>(remaining {state.timer})</div>
                            )}
                        </div>
                        <div className={styles.actionRow}>
                            <AppButton
                                value="Submit"
                                onClick={() => handleVerify()}
                                className="rounded"
                                disabled={state.incompleteOtpValue}
                                isLoading={state.loading}
                                style={{
                                    backgroundColor: "var(--darkPurble)",
                                    color: "white",
                                    // width: "30%",
                                    height: 50,
                                    fontSize: 22,
                                    marginBottom: 20,
                                    minWidth: 200,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default VerifyEmail;
