import styles from "./NotFound.module.css";

const NotFound = ({
  message = "Page Not Found",
  errorCode = 404,
  icon,
  showErrorCode = true,
  messegeStyle,
  containerStyle,
}) => {
  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.notFoundContent}>
        {showErrorCode && <p>{errorCode}</p>}
        {icon && icon}
      </div>

      <p className={styles.notFoundMessage} style={messegeStyle}>
        {message}
      </p>
    </div>
  );
};
export default NotFound;
