import AppButton from "../../components/general/app-button/AppButton";
import AppInput from "../../components/general/app-input/AppInput";
import { SlidingForm } from "../../components/general/sliding-from/SlidingForm";
import ValidationProvider from "../../components/general/validation-provider/ValidationProvider";
import Logo from "../../components/logo/Logo";
import useLogic from "../../helpers/hooks/use-logic";
import useValidator from "../../helpers/hooks/use-validator";
import Logic, { INITIAL_STATE } from "./logic";
import styles from "./Register.module.css";

const RegistrationInput = ({ label, children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 12,
        paddingRight: 12,
        paddingLeft: 12,
        minWidth: 250,
        width: "100%",
        position: "relative",
        marginTop: 20,
      }}
    >
      <div
        style={{
          position: "absolute",
          backgroundColor: "white",
          color: "var(--darkPurble)",
          top: -15,
          borderRadius: 10,
          paddingRight: 8,
          paddingLeft: 8,
          boxShadow: "0px 0px 10px 0px var(--darkPurble)",
          // left: -8,
        }}
      >
        {label}
      </div>
      {children}
    </div>
  );
};

const Register = ({ navigation }) => {
  const containerStyle = {
    paddingLeft: 4,
    fontSize: 18,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 3,
    height: 50,
    borderRadius: 0,
    width: "100%",
  };
  const { state, updateState, registerHandler } = useLogic({
    INITIAL_STATE,
    Logic,
  });

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      state.loading = true;
      handlers[currentStepIndex](event);
      state.loading = false;
    }
  };

  const {
    errors: errors1,
    onBlur: onBlur1,
    onChange: onChange1,
    handleSubmit: handleSubmit1,
  } = useValidator({
    defaultValues: {
      ...state.form,
    },
    setValue: (nextFormState) => {
      updateState({
        prop: "form",
        value: nextFormState,
      });
    },

    onSubmit: () => {
      nextStep();
    },

    validations: {
      company_name: { required: true, validation_name: "Company Name" },
      first_name: { required: true, validation_name: "First Name" },
      last_name: { required: true, validation_name: "Last Name" },
      email: { required: true, validation_name: "Email", isEmail: true },
    },
  });
  const {
    errors: errors2,
    onBlur: onBlur2,
    onChange: onChange2,
    handleSubmit: handleSubmit2,
  } = useValidator({
    defaultValues: {
      ...state.form,
    },
    setValue: (nextFormState) => {
      updateState({
        prop: "form",
        value: nextFormState,
      });
    },
    onSubmit: registerHandler,
    validations: {
      contact_person_name: {
        required: true,
        validation_name: "Contact Person Name",
      },
      contact_person_mobile: {
        required: true,
        validation_name: "Contact Person Mobile",
      },
      contact_person_whatsapp: {
        required: true,
        validation_name: "Contact Person Whatsapp",
      },
      /* MAKING ADMIN ACCOUNT DEPENDS ON CONTACT PERSON EMAIL  */
      contact_person_email: {
        required: true,
        validation_name: "Contact Person Email",
        isEmail: true,
      },
      // job_title: { required: true, validation_name: "Job Title" },
    },
  });

  const {
    steps,
    step: currentStepIndex,
    nextStep,
    prevStep,
  } = SlidingForm([
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <div className={styles.inputContainer}>
        <ValidationProvider
          error={errors1.company_name}
          render={({ requiredTitle }) => (
            <RegistrationInput
              label="Company name"
              children={
                <AppInput
                  type="text"
                  placeholder="ACME Inc."
                  containerStyle={containerStyle}
                  value={state.form.company_name}
                  onChange={(e) => onChange1("company_name", e.target.value)}
                  onBlur={(e) => onBlur1("company_name")}
                  onKeyDown={keyDownHandler}
                />
              }
            />
          )}
        />
      </div>
      <div className={styles.inputContainer}>
        <ValidationProvider
          error={errors1.first_name}
          render={({ requiredTitle }) => (
            <RegistrationInput
              label="First name"
              children={
                <AppInput
                  type="text"
                  placeholder="John"
                  containerStyle={containerStyle}
                  value={state.form.first_name}
                  onChange={(e) => {
                    onChange1("first_name", e.target.value);
                    updateState({
                      prop: "form.contact_person_name",
                      value: e.target.value + " " + state.form.last_name,
                    });
                  }}
                  onBlur={(e) => onBlur1("name")}
                  onKeyDown={keyDownHandler}
                />
              }
            />
          )}
        />
      </div>
      <div className={styles.inputContainer}>
        <ValidationProvider
          error={errors1.last_name}
          render={({ requiredTitle }) => (
            <RegistrationInput
              label="Last name"
              children={
                <AppInput
                  type="text"
                  placeholder="Doe"
                  containerStyle={containerStyle}
                  value={state.form.last_name}
                  onChange={(e) => {
                    onChange1("last_name", e.target.value);
                    updateState({
                      prop: "form.contact_person_name",
                      value: state.form.first_name + " " + e.target.value,
                    });
                  }}
                  onBlur={(e) => onBlur1("name")}
                  onKeyDown={keyDownHandler}
                />
              }
            />
          )}
        />
      </div>
      <div className={styles.inputContainer}>
        <ValidationProvider
          error={errors1.email}
          render={({ requiredTitle }) => (
            <RegistrationInput
              label="Email"
              children={
                <AppInput
                  type="text"
                  placeholder="John@email.com"
                  containerStyle={containerStyle}
                  value={state.form.email}
                  onChange={(e) => {
                    // debugger;
                    onChange1("email", e.target.value);
                    updateState({
                      prop: "form.contact_person_email",
                      value: e.target.value,
                    });
                  }}
                  onBlur={(e) => onBlur1("email")}
                  onKeyDown={keyDownHandler}
                />
              }
            />
          )}
        />
      </div>
    </div>,

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div className={styles.inputContainer}>
        <ValidationProvider
          error={errors2.contact_person_name}
          render={({ requiredTitle }) => (
            <RegistrationInput
              label="Contact Person Name"
              children={
                <AppInput
                  type="text"
                  placeholder="Contact Person Name"
                  containerStyle={containerStyle}
                  value={state.form.contact_person_name}
                  onChange={(e) =>
                    onChange2("contact_person_name", e.target.value)
                  }
                  onBlur={(e) => onBlur2("contact_person_name")}
                  onKeyDown={keyDownHandler}
                />
              }
            />
          )}
        />
      </div>
      {/* MAKING ADMIN ACCOUNT DEPENDS ON CONTACT PERSON EMAIL  */}
      <div className={styles.inputContainer}>
        <ValidationProvider
          error={errors2.contact_person_email}
          render={({ requiredTitle }) => (
            <RegistrationInput
              label="Contact Person Email"
              children={
                <AppInput
                  type="text"
                  placeholder="Contact Person Email"
                  containerStyle={containerStyle}
                  value={state.form.contact_person_email}
                  onChange={(e) =>
                    onChange2("contact_person_email", e.target.value)
                  }
                  onBlur={(e) => onBlur2("contact_person_email")}
                  onKeyDown={keyDownHandler}
                />
              }
            />
          )}
        />
      </div>

      <div className={styles.inputContainer}>
        <ValidationProvider
          error={errors2.contact_person_mobile}
          render={({ requiredTitle }) => (
            <RegistrationInput
              label="Contact Person Mobile"
              children={
                <AppInput
                  type="number"
                  placeholder="+20xxxxxxxxxx"
                  containerStyle={containerStyle}
                  value={state.form.contact_person_mobile}
                  onChange={(e) => {
                    onChange2("contact_person_mobile", e.target.value);
                  }}
                  onBlur={(e) => {
                    onBlur2("contact_person_mobile");
                    console.log("onBlur2", e);
                  }}
                  onKeyDown={keyDownHandler}
                />
              }
            />
          )}
        />
      </div>
      <div className={styles.inputContainer}>
        <ValidationProvider
          error={errors2.contact_person_whatsapp}
          render={({ requiredTitle }) => (
            <RegistrationInput
              label="Contact Person Whatsapp"
              children={
                <AppInput
                  type="number"
                  placeholder="+20xxxxxxxxxx"
                  containerStyle={containerStyle}
                  value={state.form.contact_person_whatsapp}
                  onChange={(e) =>
                    onChange2("contact_person_whatsapp", e.target.value)
                  }
                  onBlur={(e) => onBlur2("contact_person_whatsapp")}
                  onKeyDown={keyDownHandler}
                  onFocus={(e) => {
                    if (
                      !state.form.contact_person_whatsapp &&
                      state.form.contact_person_mobile
                    ) {
                      updateState({
                        prop: "form.contact_person_whatsapp",
                        value: state.form.contact_person_mobile,
                      });
                    }
                  }}
                />
              }
            />
          )}
        />
      </div>
    </div>,
  ]);
  const handlers = [handleSubmit1, handleSubmit2];

  return (
    // <>
    <div className={styles.container}>
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: 50,
        }}
      >
        <Logo classes={styles.logoSize} />
        <div className={styles.header}>
          <div className={styles.slogan}>Unleash Your Potential</div>
        </div>
      </div>
      <div className={styles.loginForm}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "90%",
          }}
        >
          {steps[currentStepIndex]}
        </div>
        {currentStepIndex !== steps.length - 1 && (
          <AppButton
            value="Next"
            className="rounded"
            style={{
              color: "var(--darkPurble)",
              backgroundColor: "white",
              height: 50,
              fontSize: 22,
              paddingLeft: 25,
              paddingRight: 25,
              paddingTop: 0,
              paddingBottom: 0,
              borderRadius: 10,
              marginRight: "5%",
              alignSelf: "flex-end",
              display: "inline-block",
            }}
            onClick={handlers[currentStepIndex]}
          />
        )}

        {currentStepIndex !== 0 && (
          <AppButton
            value="Back"
            className="rounded"
            style={{
              color: "var(--darkPurble)",
              backgroundColor: "white",
              height: 50,
              fontSize: 22,
              paddingLeft: 25,
              paddingRight: 25,
              paddingTop: 0,
              paddingBottom: 0,
              marginLeft: "5%",
              borderRadius: 10,
              alignSelf: "flex-start",
              display: "inline-block",
            }}
            onClick={prevStep}
          />
        )}
        {currentStepIndex == steps.length - 1 && (
          <AppButton
            value="Register"
            className="rounded"
            style={{
              backgroundColor: "var(--darkPurble)",
              color: "white",
              width: "30%",
              height: 50,
              fontSize: 22,
              margin: "20px auto",
              alignSelf: "center",
              display: "inline-block",
              minWidth: 200,
            }}
            onClick={handleSubmit2}
            isLoading={state.loading}
          />
        )}
      </div>
    </div>
    // </div>
  );
};
export default Register;
