import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import {
  deleteSelectedLocation,
  fetchLocations,
} from "../../../../api/services/LocationServices";
import UseAppTable from "../../../../helpers/hooks/use-app-table";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import { LocationColumn } from "./LocationColumn";
import {
  ERROR,
  SUCCESS,
} from "../../../../components/general/app-toast/AppToast";
import { useTranslation } from "react-i18next";
import { UPDATE_PROP } from "../../../../store/ActionTypes";

export const INITIAL_STATE = {};
const Logic = (dispatch, state) => {
  const { t, i18n } = useTranslation();
  const { addToast } = UseAppToast();
  let navigate = useNavigate();
  const { data, setData, error } = UseAppTable({
    fetchTableData: fetchLocations,
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const HandleCloseDelete = () => {
    setShowDeleteAlert(false);
  };

  const bulkDelete = (selectedRow) => {
    setShowDeleteAlert(true);

    setData((prevState) => {
      return {
        ...prevState,
        selectedRows: selectedRow,
      };
    });
  };

  const HandelDelete = () => {
    const selectedArray = data.selectedRows.map((d) => d.id);
    // update modal loading
    dispatch({
      payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: true }],
    });
    deleteSelectedLocation(
      (res) => {
        let arrResults = [...data.results];
        selectedArray.map((d) => {
          const index = arrResults.findIndex((x) => {
            console.log(x.id);
            return x.id === d;
          });
          arrResults.splice(index, 1);
        });
        setData((prevState) => {
          let newData = {
            results: [...arrResults],
            total: prevState.total - 1,
          };
          if (arrResults.length == 0) {
            newData = {
              ...newData,
              params: {
                ...prevState.params,
                page: prevState.params.page - 1,
              },
            };
          }
          return {
            ...prevState,
            ...newData,
          };
        });
        HandleCloseDelete();

        addToast({
          type: SUCCESS,
          description: "Deleted successfully",
          title: "Success",
        });
      },
      (error) => {
        HandleCloseDelete();

        addToast({
          type: ERROR,
          description: "Something went wrong",
          title: "Error",
        });
      },
      () => {
        // update modal loading
        dispatch({
          payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: false }],
        });
      },
      { ids: selectedArray }
    );
  };
  const actions = () => {
    return { navigate: navigate, bulkDelete: bulkDelete };
  };
  let columns = useMemo(() => LocationColumn(actions(), t), [i18n.language]);
  return {
    actions: {
      setData,
      navigate,
      setShowDeleteAlert,
      HandleCloseDelete,
      HandelDelete,
    },
    columns,
    data,
    showDeleteAlert,
    error,
  };
};

export default Logic;
