import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppButton from "../../components/general/app-button/AppButton";
import AppInput from "../../components/general/app-input/AppInput";
import LanguageToggler from "../../components/general/language-toggler/LanguageToggler";
import ValidationProvider from "../../components/general/validation-provider/ValidationProvider";
import Logo from "../../components/logo/Logo";
import useLogic from "../../helpers/hooks/use-logic";
import useValidator from "../../helpers/hooks/use-validator";
import Logic, { INITIAL_STATE } from "./logic";
import styles from "./Login.module.css";
import { useEffect } from "react";
import { LANGUAGE } from "../../helpers/constants/StaticKeys";
const Login = ({ navigation }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { state, updateState, loginHandler } = useLogic({
        INITIAL_STATE,
        Logic,
    });

    const keyDownHandler = (event) => {
        if (event.key === "Enter") {
            state.loading = true;
            handleSubmit(event);
            state.loading = false;
        }
    };

    const { errors, onBlur, onChange, handleSubmit } = useValidator({
        defaultValues: {
            ...state.form,
        },
        setValue: (nextFormState) => {
            updateState({
                prop: "form",
                value: nextFormState,
            });
        },
        onSubmit: loginHandler,
        validations: {
            username: { required: true, validation_name: "Username", isEmail: true },
            password: {
                required: true,
                validation_name: "Password",
            },
        },
    });

    useEffect(() => {
        let l = localStorage.getItem(LANGUAGE);
        if (!l) {
            localStorage.setItem(LANGUAGE, "en");
        }
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <div className={styles.header}>
                    <Logo classes={styles.logoSize} />
                    {/* <div className={styles.logo}>GAWDA</div> */}
                    <div className={styles.slogan}>{t("slogan")}</div>
                </div>

                <div className={styles.loginForm}>
                    <div className={styles.inputContainer}>
                        <ValidationProvider
                            error={errors.username}
                            render={({ requiredTitle }) => (
                                <AppInput
                                    type="text"
                                    placeholder={t("username")}
                                    containerStyle={{
                                        paddingLeft: 15,
                                        paddingRight: 20,
                                        height: 50,
                                    }}
                                    inputStyle={{ margin: "0px 7px" }}
                                    value={state.form.username}
                                    onChange={(e) => onChange("username", e.target.value.trim())}
                                    onBlur={(e) => onBlur("username")}
                                    onKeyDown={keyDownHandler}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <ValidationProvider
                            error={errors.password}
                            render={({ requiredTitle }) => (
                                <AppInput
                                    type="password"
                                    placeholder={t("password")}
                                    containerStyle={{
                                        paddingLeft: 15,
                                        paddingRight: 20,
                                        height: 50,
                                    }}
                                    inputStyle={{ margin: "0px 7px" }}
                                    value={state.form.password}
                                    onChange={(e) => onChange("password", e.target.value)}
                                    onBlur={(e) => onBlur("password")}
                                    onKeyDown={keyDownHandler}
                                />
                            )}
                        />
                    </div>

                    <AppButton
                        value={t("login")}
                        className="rounded"
                        style={{
                            backgroundColor: "var(--darkPurble)",
                            color: "white",
                            // width: "30%",
                            height: 50,
                            fontSize: 22,
                            marginBottom: 20,
                            minWidth: 200,
                        }}
                        onClick={handleSubmit}
                        isLoading={state.loading}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            // background: "red",
                            width: "50vw",
                            minWidth: 350,
                            maxWidth: 500,
                            margin: "0",
                        }}
                    >
                        <AppButton
                            value={t("forgotPassword")}
                            style={{
                                color: "white",
                                fontSize: 18,
                                minWidth: 150,
                            }}
                            onClick={() => navigate("/forget-password")}
                        />
                        {/* <span style={{ width: 100 }} /> */}
                        <AppButton
                            value={t("register")}
                            style={{
                                color: "white",
                                fontSize: 18,
                                minWidth: 150,
                            }}
                            onClick={() => navigate("/register")}
                        />
                    </div>
                    <div
                        style={{
                            height: 25,
                        }}
                    />
                    <LanguageToggler login />
                </div>
            </div>
        </div>
    );
};
export default Login;
