import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";

export const HeaderNav = ({ items, activeClass = "", defaultClass = "" }) => {
  const location = useLocation();
  const { pathname } = location;

  const navItem = (item, index) => {
    const { name, to } = item;
    return (
      <Nav.Item key={`${name}-${index}`}>
        <Nav.Link
          as={NavLink}
          to={to}
          className={`${defaultClass} ${
            pathname === to ? activeClass : undefined
          }`}
        >
          {name}
        </Nav.Link>
      </Nav.Item>
    );
  };
  const navGroupItem = (item, index) => {
    const { name, to } = item;
    return (
      <NavDropdown.Item
        as={NavLink}
        to={to}
        key={`${name}-${index}`}
        className={`${defaultClass} ${
          pathname === to ? activeClass : undefined
        }`}
      >
        {name}
      </NavDropdown.Item>
    );
  };
  const navGroup = (item, index) => {
    const { name, component, align } = item;
    return (
      <NavDropdown
        title={name ? name : component}
        id="collasible-nav-dropdown"
        key={`${name}-${index}`}
        align={align}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navGroupItem(item, index)
        )}
      </NavDropdown>
    );
  };

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
    </React.Fragment>
  );
};
