import axios from "axios";
import { authBaseURL, baseURL } from "../config";
import { AUTHENTICATION_TOKEN } from "../helpers/constants/StaticKeys";
// import Storage from "../helpers/Storage";
// export let source = axios.CancelToken.source();
// export const config = { cancelToken: source.token };
export const CancelToken = axios.CancelToken;

export function createCancelTokenHandler(apiObject) {
    // initializing the cancel token handler object
    const cancelTokenHandler = {};
    // console.log(apiObject);
    // for each property in apiObject, i.e. for each request
    Object.getOwnPropertyNames(apiObject).forEach((propertyName) => {
        // initializing the cancel token of the request
        const cancelTokenRequestHandler = {
            cancelToken: undefined,
        };
        // console.log(propertyName);
        // associating the cancel token handler to the request name
        cancelTokenHandler[propertyName] = {
            handleRequestCancellation: () => {
                // if a previous cancel token exists,
                // cancel the request
                // console.log(cancelTokenRequestHandler);
                cancelTokenRequestHandler.cancelToken &&
                    cancelTokenRequestHandler.cancelToken.cancel(`${propertyName} canceled`);

                // creating a new cancel token
                cancelTokenRequestHandler.cancelToken = CancelToken.source();

                // returning the new cancel token
                return cancelTokenRequestHandler.cancelToken;
            },
        };
    });
    // console.log(cancelTokenHandler);
    return cancelTokenHandler;
}

export const $axios = axios.create({
    baseURL: baseURL,
    headers: {
        common: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    },
});
export const $securedAxios = axios.create({
    baseURL: baseURL,
    headers: {
        common: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    },
});
export const $authAxios = axios.create({
    baseURL: authBaseURL,
    headers: {
        common: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    },
});

const requestHandler = (request) => {
    const authToken = Storage.getItem(AUTHENTICATION_TOKEN);
    // const authToken =
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiN2M4MWE4MWRkYWRhZTk5MzJjMjE0Zjc3NWMwY2YxYjQwZjY3MWQ3MjliODA0NWQxOGYxMDg5MDM0Y2RmMGRiM2M4NjE1NzVhNTk5MTg5ZTciLCJpYXQiOjE2NTg0MDU5MzAuNzQwMjg0LCJuYmYiOjE2NTg0MDU5MzAuNzQwMjg2LCJleHAiOjE2ODk5NDE5MzAuNTg2NDEsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.UwqFWZuA9BQmXWMoALBR6ZOnHfU2DR3PAu08ccHcSR3hfTdudBfzOtg4XLd0ZBaV6bDjQgc3DWa6k63XvIYuAwxSUYubnNLgf1AHThhw5DYUHBHwE5HD2nxSLy-M6_DYKKiDxQnaSUTPlDj78oGMFBaUiqgIAjBaWJpwzIDjxgO9Lz47uklF3wbHild6ejOchvuELTH42Tz8sPU2s09oe3-_0oQiZlL9UeGMAB2Vgs86PFORGQzgdNMOP79WyStv9y7nfowcjBcLsQ9wDgkr_fX4FudM2oHDTtuz0ABoGr3B1aYrwgP5K8KZc1metaEG3IV2hWer9jZkYJhKOxkPcur5zLT_kK8J6y5WY1zdrsf7W2gyhcxpGVh90pYECANY4chNJZ3eOlL4Dqq9NdaqDMo7Ysaldxy7RzR4oFi2pmRxHcKSdKtL7Fy2B4WWnkQeuif_B484_9bXbyKyRxuc0CEUvBO0sfPWsIMQkTQWEhcyz5nb--WJZxf_oKGSwewAs363pULk4xuXttUgVKAEtHQE6KK346GvFvf7_2BQnZ-HFy1we3WAPO-1tCw2kUtWPegtVeLzGXj_maoLfXBMRqi-8vksTgukkKGtQdsLyfcDR1sgawjSwvA0RBMhJmoYw4N09mv8XVCOiGDascQPQB9vBLE90vzc0a6joEeAC7g";
    let headers = {};
    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
    }
    request.headers.common = {
        ...request.headers.common,
        ...headers,
    };

    return request;
};

const responseHandler = (response) => {
    return response;
};

const errorHandler = (error) => {
    // alert(JSON.stringify(error));

    if (error.response.status === 401) {
        localStorage.clear();
        window.location = "/login";
    }
    return Promise.reject(error);
};

// UNSECURED RESPONSE HANDLER
$axios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

// SECURED REQUEST HANDLER
$securedAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

// SECURED RESPONSE HANDLER
$securedAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);


// SECURED REQUEST HANDLER
$authAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

// SECURED RESPONSE HANDLER
$authAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);