import { useEffect } from "react";
import { UPDATE_PROP } from "../../store/ActionTypes";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../api/services/UserServices";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { ERROR, SUCCESS } from "../../components/general/app-toast/AppToast";
import { encrypted, userDecryption, userEncryption } from "../../helpers/Common";
import CryptoJS from "react-native-crypto-js";
import { ENCRYPT_KEY } from "../../helpers/constants/StaticKeys";
import base64 from "react-native-base64";
export const INITIAL_STATE = {
    email: "",
    token: "",
    invalid: false,
    form: {
        password: "",
        confirm_password: "",
        saveLoading: false,
    },
};

const Logic = (dispatch, state) => {
    const { addToast } = UseAppToast();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { state: propsInput } = useLocation();
    useEffect(() => {
        // The follwoing code works if We have navigated from OTP verify page.
        if (propsInput) {
            dispatch({
                payload: [
                    { type: UPDATE_PROP, prop: "email", value: propsInput.email },
                    { type: UPDATE_PROP, prop: "token", value: propsInput.token },
                ],
            });
        }
        if (searchParams.get("t") && searchParams.get("e")) {
            let token = searchParams.get("t");
            let encryptedEmail = searchParams.get("e");

            /* For some reason email is encrypted ina way that we are unable to decryot using existing methods in Common.js
               So we are using a SandBox craeted ages ago and copied code from there.
            */
            let a = JSON.parse(base64.decode(encryptedEmail));
            let dec = CryptoJS.AES.decrypt(a.value, CryptoJS.enc.Base64.parse(ENCRYPT_KEY), {
                iv: CryptoJS.enc.Base64.parse(a.iv),
            });
            let email = dec.toString(CryptoJS.enc.Utf8);
            email = email.substring(email.indexOf('"') + 1, email.lastIndexOf('"'));
            console.log(email);
            dispatch({
                payload: [
                    { type: UPDATE_PROP, prop: "email", value: email },
                    { type: UPDATE_PROP, prop: "token", value: token },
                ],
            });
        }
    }, []);
    const resetHandler = () => {
        const { token, email, form } = state;
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `saveLoading`,
                    value: true,
                },
            ],
        });
        resetPassword(
            (res) => {
                addToast({
                    type: SUCCESS,
                    description: res.message || "Completed successfully",
                    title: "Success",
                });
                navigate("/login");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: error?.response?.data?.message || "Something went wrong!",
                    title: "Error",
                });
            },
            (f) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `saveLoading`,
                            value: false,
                        },
                    ],
                });
            },
            { token, email, password: encrypted(form.password), password_reset_token: token }
        );
    };
    return { actions: { resetHandler } };
};

export default Logic;
