import { useLocation, useNavigate } from "react-router-dom";
import { login } from "../../api/services/UserServices";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../store/ActionTypes";
import { postLoginLogic } from "../login/common";

export const INITIAL_STATE = {
  form: {
    selectedCompany: null,
  },
  loading: false,
};
const Logic = (dispatch, state) => {
  const navigate = useNavigate();
  const { addToast } = UseAppToast();
  const location = useLocation();
  // Login logic duplicate in Login Page
  const loginHandler = () => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `loading`,
          value: true,
        },
      ],
    });
    login(
      async (res) => {
        await postLoginLogic(res, navigate, addToast);
      },
      (e) => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `loading`,
              value: false,
            },
          ],
        });
        addToast({
          type: "error",
          description: "username or password is incorrect",
          title: "Error",
        });
      },
      () => {},
      {
        username: location.state.username,
        password: location.state.password,
        company_name: state.form.selectedCompany.label,
      }
    );
  };
  return { actions: { loginHandler }, location };
};

export default Logic;
