import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../../components/general/app-button/AppButton";

export const UserColumn = (
    actions,
    email = "",
    translate = (term) => {
        return term;
    }
) => {
    const adminEmail = localStorage.getItem("admin");
    return [
        {
            Header: `${translate("name")}`,
            accessor: "name",
        },
        {
            Header: `${translate("email")}`,
            accessor: "email",
        },
        // {
        //   Header: "App Access",
        //   style: {
        //     textAlign: "center",
        //   },
        //   Cell: ({ row: { values } }) => (
        //     <div style={{ display: "flex", justifyContent: "center" }}>
        //       <SwitchButton
        //         checked={true}
        //         onChange={() => {
        //           console.log("test");
        //         }}
        //       />
        //     </div>
        //   ),
        // },
        // {
        //   Header: "Admin Access",
        //   style: {
        //     textAlign: "center",
        //   },
        //   Cell: ({ row: { values } }) => (
        //     <div style={{ display: "flex", justifyContent: "center" }}>
        //       <SwitchButton
        //         checked={true}
        //         onChange={() => {
        //           console.log("test");
        //         }}
        //       />
        //     </div>
        //   ),
        // },

        // {
        //   Header: "Analytics Access",
        //   // accessor: "ana_access",
        //   style: {
        //     textAlign: "center",
        //   },
        //   Cell: ({ row: { values } }) => (
        //     <div style={{ display: "flex", justifyContent: "center" }}>
        //       <SwitchButton
        //         checked={true}
        //         onChange={() => {
        //           console.log("test");
        //         }}
        //       />
        //     </div>
        //   ),
        // },
        {
            Header: `${translate("actions")}`,
            // className: "frozen",
            style: {
                textAlign: "center",
            },
            maxWidth: 150,
            minWidth: 150,
            width: 150,
            Cell: ({ row: { values, original } }) => (
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <AppButton
                        classes="buttonIcon"
                        onClick={() => actions.navigate(`/portal/add-user/${original.id}`)}
                        value ={`${translate("edit")}`}

                        style={{
                            color: "#2e5bff",
                        }}
                        icon={<FontAwesomeIcon icon={faEdit} style={{ margin: "0px 5px"}} />}
                    />

                    {original.is_admin == 0 && (
                        <AppButton
                            classes="buttonIcon"
                            style={{
                                color: original.is_admin ? "grey" : "#fb3737",
                            }}
                            onClick={() => (original.is_admin ? null : actions.delete(original.id))}
                            value ={`${translate("delete")}`}
                            icon={
                                <FontAwesomeIcon icon={faTrashAlt} style={{ margin: "0px 5px"}} />
                            }
                        />
                    )}
                </div>
            ),
        },
    ];
};
