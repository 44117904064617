import AppButton from "../../../../../../components/general/app-button/AppButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import SwitchButton from "../../../../../../components/general/switch-button/SwitchButton";
import Logic from "../../globalLogic";
import { UPDATE_PROP } from "../../../../../../store/ActionTypes";

export const AssigneeColumn = (
    actions,
    translate = (term) => {
        return term;
    }
) => {
    return [
        {
            Header: translate("name"),
            accessor: "name",
        },
        {
            Header: translate("email"),
            accessor: "email",
        },
        {
            Header: translate("department"),
            accessor: "department.label",
        },
        {
            Header: "Approval req",
            style: {
                textAlign: "center",
            },
            Cell: ({ row: { values, original } }) => {
                console.log(original);
                return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <SwitchButton
                            checked={original.approval_req}
                            onChange={() => {
                                actions.actionUpdateApprovalReq(original.approval_req, original.id);
                            }}
                        />
                    </div>
                );
            },
        },
        {
            Header: translate("actions"),
            // className: "frozen",
            style: {
                textAlign: "center",
            },
            Cell: ({ row: { values, original } }) => (
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <AppButton
                        classes="buttonIcon"
                        onClick={() => {
                            actions.update(original);
                        }}
                        value={translate("edit")}
                        style={{
                            color: "#2e5bff",
                        }}
                        icon={<FontAwesomeIcon icon={faEdit} style={{ marginRight: "5px" }} />}
                    />
                    <AppButton
                        classes="buttonIcon"
                        style={{ color: "#fb3737" }}
                        onClick={() => {
                            actions.delete(original.id);
                        }}
                        value={translate("delete")}
                        icon={<FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: "5px" }} />}
                    />
                </div>
            ),
        },
    ];
};
