import { useEffect, useState } from "react";
import { ERROR } from "../../components/general/app-toast/AppToast";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { extractErrorMessage, useEffectSkipFirst } from "../Common";
import { INITIAL_STATE } from "../RtCommon";

const UseAppTable = ({ fetchTableData, payload, shouldCall = true, onSuccess = (f) => {} }) => {
    const [data, setData] = useState(INITIAL_STATE);
    const [error, setError] = useState(null);
    const { addToast } = UseAppToast();
    const fetchData = async (params) => {
        setData((prevState) => {
            return {
                ...prevState,
                loading: true,
            };
        });
        const updatedPageParam = params?.page
            ? {}
            : {
                  page: 1,
              };

        return fetchTableData(
            (res) => {
                setData((prevState) => {
                    return {
                        ...prevState,
                        results: res.data,
                        total: res.total,
                        params: {
                            ...prevState.params,
                            page: res.current_page - 1,
                        },
                        perPage: res.per_page,
                    };
                });
                // console.log(res.data);

                onSuccess && onSuccess(res);
            },
            (error) => {
                addToast &&
                    addToast({
                        type: ERROR,
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                setError(extractErrorMessage(error) || "Something went wrong!");
            },
            () => {
                setData((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                    };
                });
            },
            {
                ...data.params,
                ...params,
                ...updatedPageParam,
            }
        );
    };
    useEffectSkipFirst(() => {
        if (shouldCall) {
            // console.log("page");
            fetchData({
                ...payload,
                page: data.params.page + 1,
            });
        }
    }, [data.params.page]);

    useEffectSkipFirst(() => {
        if (shouldCall) {
            // console.log("filters");
            fetchData({
                ...payload,
                filters: data.params.filters,
            });
        }
    }, [data.params.filters]);

    useEffect(() => {
        if (shouldCall) {
            // console.log("fetch data");
            fetchData(payload);
        }
    }, []);

    return {
        data,
        setData,
        error,
    };
};

export default UseAppTable;
