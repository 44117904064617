import { useEffect, useState } from "react";
import { getAllDepartments } from "../../../../../../../../api/services/DepartmentServices";
import { ERROR } from "../../../../../../../../components/general/app-toast/AppToast";
import UseAppToast from "../../../../../../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../../../../../../store/ActionTypes";
import { INITIAL_STATE as inital } from "../../../../../../../../helpers/RtCommon";
import { useDispatch, useSelector } from "react-redux";
import { extractErrorMessage } from "../../../../../../../../helpers/Common";

export const INITIAL_STATE = {
    assignee: {
        name: "",
        email: "",
        department: {},
        approval_req: false,
        default_assign: false,
    },
    departments: [],
};
const Logic = (dispatch, state, prop) => {
    const { addToast } = UseAppToast();
    const reduxDispatch = useDispatch();
    const reduxState = useSelector((state) => state.survey);
    const addAssignee = () => {
        if (state.assignee.id) {
            const index = reduxState.surveyDetails.assignees.findIndex((x) => {
                return x.id === state.assignee.id;
            });
            reduxDispatch({
                type: UPDATE_PROP,
                prop: `survey.surveyDetails.assignees.${index}`,
                value: { ...state.assignee },
            });
        } else {
            prop.updateAssignees((v) => {
                return [...v, { id: Math.floor(Math.random() * Date.now()), ...state.assignee }];
            });
        }
    };
    console.log("prop.assigneeValue");
    console.log(prop.assigneeValue);
    useEffect(() => {
        if (prop.assigneeValue) {
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: "assignee",
                        value: prop.assigneeValue,
                    },
                ],
            });
        }
    }, []);
    useEffect(() => {
        getAllDepartments(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: "departments",
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            (f) => {}
        );
    }, []);
    return {
        actions: {
            addAssignee,
        },
    };
};

export default Logic;
