import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import AppButton from "../../../../../../components/general/app-button/AppButton";
import AppInput from "../../../../../../components/general/app-input/AppInput";
import AppSelect from "../../../../../../components/general/app-select/AppSelect";
import Table from "../../../../../../components/general/app-table/AppTable";
import ConfirmModal from "../../../../../../components/general/confirm-modal/ConfrimModal";
import Label from "../../../../../../components/general/label/Label";
import SwitchButton from "../../../../../../components/general/switch-button/SwitchButton";
import Title from "../../../../../../components/general/title/Title";
import ValidationProvider from "../../../../../../components/general/validation-provider/ValidationProvider";
import useLogic from "../../../../../../helpers/hooks/use-logic";
import { UPDATE_PROP } from "../../../../../../store/ActionTypes";
import { filterSelectStyles } from "../structure/Constants";
import Styles from "./Basic.module.css";
import AddAssigneeModal from "./components/add-assignee-modal/AddAssigneeModal";
import Logic, { INITIAL_STATE } from "./logic";

const Basic = ({ id, onChange, onBlur, errors }) => {
    const { t } = useTranslation();
    const {
        state,
        reduxState,
        updateState,
        handleClick,
        HandleCloseDelete,
        HandelDelete,
        generateSrc,
        hiddenFileInput,
        columns,
        // saveAuditTemplate,
        updateAuditDetails,
        assignees,
        navigate,
        onchange,
    } = useLogic({
        INITIAL_STATE,
        Logic,
        prop: { id },
    });
    console.log("reduxState.surveyDetails");
    console.log(typeof reduxState.surveyDetails.image);
    return (
        <div>
            <div className={Styles.formContainer}>
                <div className={Styles.sectionGroup}>
                    <div className={Styles.sectionContainer} style={{ paddingTop: "5px" }}>
                        <div className={Styles.groupControl}>
                            <ValidationProvider
                                error={errors.name}
                                render={({ requiredTitle }) => (
                                    <Label name={requiredTitle(t("name"))} className={Styles.label}>
                                        <AppInput
                                            type="text"
                                            className={Styles.input}
                                            onChange={(e) => onChange("name", e.target.value)}
                                            onBlur={(e) => onBlur("name")}
                                            value={reduxState.surveyDetails.name}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                        <div className={Styles.groupControl}>
                            <ValidationProvider
                                error={errors.short_name}
                                render={({ requiredTitle }) => (
                                    <Label
                                        name={requiredTitle(t("shortName"))}
                                        className={Styles.label}
                                    >
                                        <AppInput
                                            type="text"
                                            className={Styles.input}
                                            onChange={(e) => onChange("short_name", e.target.value)}
                                            onBlur={(e) => onBlur("short_name")}
                                            value={reduxState.surveyDetails.short_name}
                                        />
                                    </Label>
                                )}
                            />
                        </div>
                        <div className={Styles.groupControl}>
                            {/* <ValidationProvider
                error={errors.department_id}
                render={({ requiredTitle }) => ( */}
                            <Label name={t("department")} className={Styles.label}>
                                <AppSelect
                                    options={reduxState.masterData.departments.map((element) => ({
                                        value: element.id,
                                        label: element.name,
                                    }))}
                                    value={reduxState.surveyDetails.department_id}
                                    styles={filterSelectStyles}
                                    onChange={(e) => {
                                        console.log(e);
                                        onChange("department_id", e);
                                    }}
                                    onBlur={(e) => onBlur("department_id")}
                                />
                            </Label>
                            {/* )}
              /> */}
                        </div>
                    </div>
                    <div className={Styles.sectionContainer}>
                        <ValidationProvider
                            error={errors.image}
                            render={({ requiredTitle }) => (
                                <>
                                    <Title
                                        title={t("logo")}
                                        style={{ color: "black", fontSize: "12px" }}
                                    />

                                    <div
                                        style={{ display: "flex", alignItems: "center" }}
                                        onBlur={() => onBlur("image")}
                                    >
                                        <AppButton
                                            classes="buttonIcon"
                                            style={{
                                                color: "white",
                                                background: "#b8b8b8",
                                                margin: "5px 10px 5px 0px",
                                                padding: "26px 27px",
                                                fontSize: "22px",
                                                borderRadius: "6px",
                                            }}
                                            onClick={handleClick}
                                            icon={<FontAwesomeIcon icon={faPlus} />}
                                        />
                                        {reduxState.surveyDetails.image && (
                                            <img
                                                src={generateSrc(reduxState.surveyDetails.image)}
                                                width={150}
                                                height={150}
                                            />
                                        )}

                                        <AppInput
                                            ref={hiddenFileInput}
                                            type="file"
                                            onChange={(e) => {
                                                onChange("image", e.target.files[0]);
                                            }}
                                            inputStyle={{ minHeight: "30px", display: "none" }}
                                        />
                                    </div>
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className={Styles.sectionGroup}>
                    <div className={Styles.sectionContainer}>
                        <Title
                            title={t("auditorSign")}
                            border={true}
                            style={{ color: "black", fontSize: "14px" }}
                        />
                        <div
                            style={{
                                padding: "10px 5px",
                                padding: "0px 5px",
                                background: "#f7f7f7",
                            }}
                        >
                            <div className={Styles.switchGroup}>
                                <div className={Styles.switchControl}>
                                    <SwitchButton
                                        checked={!!reduxState.surveyDetails.show_sign}
                                        onChange={() => {
                                            updateAuditDetails([
                                                {
                                                    type: UPDATE_PROP,
                                                    prop: `surveyDetails.show_sign`,
                                                    value: !reduxState.surveyDetails.show_sign,
                                                },
                                            ]);
                                        }}
                                        label={t("showInApp")}
                                    />
                                </div>
                                {!!reduxState.surveyDetails.show_sign && (
                                    <div className={Styles.switchControl}>
                                        <SwitchButton
                                            checked={!!reduxState.surveyDetails.sign_required}
                                            onChange={() => {
                                                updateAuditDetails([
                                                    {
                                                        type: UPDATE_PROP,
                                                        prop: `surveyDetails.sign_required`,
                                                        value: !reduxState.surveyDetails
                                                            .sign_required,
                                                    },
                                                ]);
                                            }}
                                            label={t("mandatory")}
                                        />
                                    </div>
                                )}
                            </div>
                            {!!reduxState.surveyDetails.show_sign && (
                                <div className={Styles.groupControl}>
                                    <ValidationProvider
                                        error={errors.sign_label}
                                        render={({ requiredTitle }) => (
                                            <Label
                                                name={requiredTitle(t("fieldLabel"))}
                                                className={Styles.label}
                                            >
                                                <AppInput
                                                    type="text"
                                                    className={Styles.input}
                                                    onChange={(e) =>
                                                        onChange("sign_label", e.target.value)
                                                    }
                                                    onBlur={(e) => onBlur("sign_label")}
                                                    value={reduxState.surveyDetails.sign_label}
                                                />
                                            </Label>
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={Styles.sectionContainer}>
                        <Title
                            title={t("inChargeSign")}
                            border={true}
                            style={{ color: "black", fontSize: "14px" }}
                        />
                        <div
                            style={{
                                padding: "10px 5px",
                                padding: "0px 5px",
                                background: "#f7f7f7",
                            }}
                        >
                            <div className={Styles.switchGroup}>
                                <div className={Styles.switchControl}>
                                    <SwitchButton
                                        checked={!!reduxState.surveyDetails.show_incharge_sign}
                                        onChange={() => {
                                            updateAuditDetails([
                                                {
                                                    type: UPDATE_PROP,
                                                    prop: `surveyDetails.show_incharge_sign`,
                                                    value: !reduxState.surveyDetails
                                                        .show_incharge_sign,
                                                },
                                            ]);
                                        }}
                                        label={t("showInApp")}
                                    />
                                </div>
                                {!!reduxState.surveyDetails.show_incharge_sign && (
                                    <div className={Styles.switchControl}>
                                        <SwitchButton
                                            checked={
                                                !!reduxState.surveyDetails.incharge_sign_required
                                            }
                                            onChange={() => {
                                                updateAuditDetails([
                                                    {
                                                        type: UPDATE_PROP,
                                                        prop: `surveyDetails.incharge_sign_required`,
                                                        value: !reduxState.surveyDetails
                                                            .incharge_sign_required,
                                                    },
                                                ]);
                                            }}
                                            label={t("mandatory")}
                                        />
                                    </div>
                                )}
                            </div>
                            {!!reduxState.surveyDetails.show_incharge_sign && (
                                <div className={Styles.groupControl}>
                                    <ValidationProvider
                                        error={errors.incharge_sign_label}
                                        render={({ requiredTitle }) => (
                                            <Label
                                                name={requiredTitle(t("fieldLabel"))}
                                                className={Styles.label}
                                            >
                                                <AppInput
                                                    type="text"
                                                    className={Styles.input}
                                                    onChange={(e) =>
                                                        onChange(
                                                            "incharge_sign_label",
                                                            e.target.value
                                                        )
                                                    }
                                                    onBlur={(e) => onBlur("incharge_sign_label")}
                                                    value={
                                                        reduxState.surveyDetails.incharge_sign_label
                                                    }
                                                />
                                            </Label>
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={Styles.sectionContainer} style={{ paddingTop: "20px" }}>
                    <div className={Styles.title}>
                        <Title
                            title={t("responsibleUsers")}
                            border={true}
                            style={{ color: "black", fontSize: "15px" }}
                        >
                            <AppButton
                                className="primaryButton"
                                style={{
                                    borderRadius: 0,
                                    borderTopRightRadius: "6px",
                                    borderTopLeftRadius: "6px",
                                    padding: "2px 10px",
                                    borderBottom: "none",
                                    cursor: "pointer",
                                    lineHeight: 1,
                                    fontSize: "13px",
                                }}
                                onClick={() => {
                                    updateState({
                                        type: UPDATE_PROP,
                                        prop: "addAssigneeModal",
                                        value: true,
                                    });
                                }}
                                value={t("add")}
                                icon={<FontAwesomeIcon icon={faPlus} style={{ margin: "0 5px" }} />}
                            />
                        </Title>
                    </div>
                    <div
                        style={{
                            padding: "0px 5px",
                        }}
                    >
                        {/* <ValidationProvider
                            error={errors.assignees}
                            render={() => ( */}
                        <Table columns={columns} data={assignees} showPagination={false} />
                        {/* )}
                        /> */}
                    </div>
                </div>
            </div>
            <div className={Styles.actionButton}>
                {/* <AppButton
          className="primaryButton"
          onClick={handleSubmit}
          value="Save"
          isLoading={state.saveLoading}
        />
        <AppButton
          className="whiteButton"
          onClick={() => {
            navigate("/forms-checklists");
          }}
          value="Cancel"
          style={{ margin: "0px 15px" }}
        /> */}
            </div>
            {state.addAssigneeModal && (
                <AddAssigneeModal
                    show={state.addAssigneeModal}
                    set={() => {
                        updateState({
                            type: UPDATE_PROP,
                            prop: "addAssigneeModal",
                            value: false,
                        });
                    }}
                    assigneeValue={state.assigneeValue}
                    setAssigneeValue={() => {
                        updateState({
                            type: UPDATE_PROP,
                            prop: "assigneeValue",
                            value: null,
                        });
                    }}
                    updateAssignees={(value) => onChange("assignees", value)}
                />
            )}

            {state.showDeleteAlert && (
                <ConfirmModal
                    show={state.showDeleteAlert}
                    onHide={() => HandleCloseDelete()}
                    onSave={() => HandelDelete()}
                />
            )}
        </div>
    );
};

export default Basic;
