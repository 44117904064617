// import Select, { components } from "react-select";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactSelect, { components } from "react-select";
import { defaultSelectStyles } from "./Constants";

const AppSelect = React.forwardRef(
  ({ onChange, options, defaultValue, ...props }, ref) => {
    const { styles, ...rest } = props;

    const getdefaultValue = (value) => {
      if (value !== "") {
        const entry = options.filter(function (option) {
          return option.value === value;
        });
        return entry[0];
      }
    };
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <FontAwesomeIcon icon={faChevronDown} />
          </components.DropdownIndicator>
        )
      );
    };
    return (
      <ReactSelect
        styles={
          props.styles != undefined
            ? { ...defaultSelectStyles, ...props.styles }
            : defaultSelectStyles
        }
        onChange={onChange}
        options={options}
        value={options.value}
        defaultValue={getdefaultValue(defaultValue)}
        components={{ DropdownIndicator }}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default AppSelect;
