import AppButton from "../../../components/general/app-button/AppButton";
import Card from "../../../components/general/card/Card";
import Title from "../../../components/general/title/Title";
import AppInput from "../../../components/general/app-input/AppInput";
import Label from "../../../components/general/label/Label";
import React, { useEffect } from "react";
import Styles from "./ChangePassword.module.css";
import Logic, { INITIAL_STATE } from "./logic";
import useLogic from "../../../helpers/hooks/use-logic";
import LoadingSpinner from "../../../components/general/loading-spinner/LoadingSpinner";
import ValidationProvider from "../../../components/general/validation-provider/ValidationProvider";
import useValidator from "../../../helpers/hooks/use-validator";

const ChangePassword = () => {
    const { state, updateState, changepassword, navigate } = useLogic({
        INITIAL_STATE,
        Logic,
    });
    const { form } = state;

    const { errors, onBlur, onChange, handleSubmit, useWatch } = useValidator({
        defaultValues: {
            ...state.form,
        },
        setValue: (nextFormState) => {
            updateState({
                prop: "form",
                value: nextFormState,
            });
        },
        onSubmit: changepassword,
        validations: {
            password: {
                required: true,
                validation_name: "Password",
                minLength: 6,
            },
            password_confirmation: {
                required: true,
                exact: "password",
                validation_name: "Confirm password",
            },
        },
        watch: ["password"],
    });

    return (
        <div className={Styles.container}>
            <div className={Styles.title}>
                <Title title="Change Password"></Title>
            </div>
            {/* <button onClick={showList}>Show List</button> */}

            <Card style={{ padding: "20px 10px" }}>
                <LoadingSpinner isLoading={state.pageLoading} error={state.error}>
                    <div className={Styles.groupControl}>
                        <div className={Styles.sectionContainer}>
                            <div style={{ width: "50%" }}>
                                <div className={Styles.groupControl}>
                                    <ValidationProvider
                                        error={errors.password}
                                        render={({ requiredTitle }) => (
                                            <Label
                                                name={requiredTitle("Password")}
                                                className={Styles.label}
                                            >
                                                <AppInput
                                                    type="password"
                                                    className={Styles.input}
                                                    value={form.password}
                                                    onChange={(e) => {
                                                        onChange("password", e.target.value);
                                                        // showList();
                                                    }}
                                                    onBlur={(e) => onBlur("password")}
                                                />
                                            </Label>
                                        )}
                                    />
                                </div>
                                <div className={Styles.groupControl}>
                                    <ValidationProvider
                                        error={errors.password_confirmation}
                                        render={({ requiredTitle }) => (
                                            <Label
                                                name={requiredTitle("Confirm Password")}
                                                className={Styles.label}
                                            >
                                                <AppInput
                                                    type="password"
                                                    className={Styles.input}
                                                    value={form.password_confirmation}
                                                    onChange={(e) =>
                                                        onChange(
                                                            "password_confirmation",
                                                            e.target.value
                                                        )
                                                    }
                                                    onBlur={(e) => onBlur("password_confirmation")}
                                                />
                                            </Label>
                                        )}
                                    />
                                    {/* <Label name="Confirm Password" className={Styles.label}>
                    <AppInput
                      type="password"
                      className={Styles.input}
                      value={form.password_confirmation}
                      onChange={(e) =>
                        updateState({
                          prop: "form.password_confirmation",
                          value: e.target.value,
                        })
                      }
                    />
                  </Label> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={Styles.actionButton}>
                        <AppButton
                            className="primaryButton"
                            onClick={handleSubmit}
                            value="Save"
                            isLoading={state.saveLoading}
                        />
                        <AppButton
                            className="whiteButton"
                            onClick={() => navigate("/portal/users")}
                            value="Cancel"
                            style={{ margin: "0px 15px" }}
                        />
                    </div>
                    {/* </form> */}
                </LoadingSpinner>
            </Card>
        </div>
    );
};
export default ChangePassword;
