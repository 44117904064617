import { faCircleNotch, faPlus, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import AppButton from "../../../../../../../../components/general/app-button/AppButton";
import styles from "./Options.module.css";
import { filterSelectStyles } from "../../Constants";
import AppInput from "../../../../../../../../components/general/app-input/AppInput";
import Label from "../../../../../../../../components/general/label/Label";
import Option from "../../components/option/Option";
import CheckboxInput from "../../../../../../../../components/general/checkbox-input/CheckboxInput";
import Card from "../../../../../../../../components/general/card/Card";
import AppSelect from "../../../../../../../../components/general/app-select/AppSelect";
import SwitchButton from "../../../../../../../../components/general/switch-button/SwitchButton";
import ResponseOption from "../response-option/ResponseOption";
import { DELETE_PROP, UPDATE_PROP } from "../../../../../../../../store/ActionTypes";
import { IMAGES_BASE_URL } from "../../../../../../../../config";
import MultiEmail from "../../../../../../../../components/general/multi-email/MultiEmail";
import CreatableSelect from "react-select/creatable";
import { ERROR } from "../../../../../../../../components/general/app-toast/AppToast";
import { storeTag, updateAuditTemplate } from "../../../../../../../../api/services/AuditServices";
import UseAppToast from "../../../../../../../../helpers/hooks/use-app-toast";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { sampleResponses } from "./constants";
import { useTranslation } from "react-i18next";
import { extractErrorMessage } from "../../../../../../../../helpers/Common";
const Options = ({
    question,
    updateQuestion,
    masterData,
    handleCheck,
    allUsers,
    selectedQuestion,
    setQuestionsImages,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        type,
        questionHelp,
        response,
        mandatory,
        enabled,
        showImage,
        printSize,
        selectEmailAddress,
        notificationEmailRequired,
        actionNotificationEmailRequired,
        actionMandatory,
        actionPlanAssignee,
        selectedTriggerPlan,
        selectedTriggerNotification,
        tags,
        image,
        name,
    } = question || {};
    // debugger;
    const {
        questionTypes,
        dataShowImage,
        dataPrintSize,
        dataSelectEmailAddress,
        triggerNotification,
        triggerPlan,
        dataTags,
    } = masterData;

    // console.log("masterData in options");
    // console.log(response);
    const hiddenFileInput = useRef(null);
    const { addToast } = UseAppToast();
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    const [loading, setloading] = useState(false);
    const generateSrc = (file) => {
        if (!file) return "";
        if (typeof file === "string") return IMAGES_BASE_URL + file;
        console.log("url : ", URL.createObjectURL(file));
        return URL.createObjectURL(file);
    };
    console.log(tags);
    const saveTags = (selectedTags) => {
        const newTags = selectedTags.filter((item) => item.__isNew__ == true);
        console.log(newTags);
        if (newTags.length > 0) {
            storeTag(
                (res) => {
                    addToast({
                        type: "success",
                        description: "saved tag Successfully",
                        title: "Success",
                    });

                    updateQuestion([
                        {
                            type: UPDATE_PROP,
                            prop: `tags`,
                            value: (v) => {
                                console.log("tagsv");
                                console.log(v);
                                return v.map((item) => {
                                    const { __isNew__, ...rest } = item;
                                    return rest;
                                });
                            },
                        },
                    ]);

                    dispatch({
                        type: UPDATE_PROP,
                        prop: "survey.masterData.dataTags",
                        value: (v) => {
                            return [...v, res.data.response];
                        },
                    });
                },
                (error) => {
                    addToast({
                        type: ERROR,
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                },

                (f) => {},
                { tag: newTags[0].value }
            );
        }
    };

    return (
        <>
            {question && (
                <Card className={styles.options}>
                    <div className={styles.option}>
                        <h5>{name}</h5>
                    </div>
                    {/* Question Type */}
                    <div className={styles.option}>
                        <p className={styles.optionText}>{t("questionType")}</p>
                        <AppSelect
                            options={questionTypes.map((element) => ({
                                value: element,
                                label: t(element),
                            }))}
                            styles={filterSelectStyles}
                            value={{
                                value: type,
                                label: t(type),
                            }}
                            onChange={(e) => {
                                console.log(e);
                                updateQuestion([
                                    { type: UPDATE_PROP, prop: "type", value: e.value },
                                ]);
                            }}
                        />
                    </div>
                    {/* Mandatory & Enabled */}
                    <div className={styles.option}>
                        <SwitchButton
                            checked={mandatory}
                            onChange={() => {
                                updateQuestion([
                                    {
                                        type: UPDATE_PROP,
                                        prop: `mandatory`,
                                        value: !mandatory,
                                    },
                                ]);
                            }}
                            label={t("mandatory")}
                        />
                        <SwitchButton
                            checked={enabled}
                            onChange={() => {
                                updateQuestion([
                                    {
                                        type: UPDATE_PROP,
                                        prop: `enabled`,
                                        value: !enabled,
                                    },
                                ]);
                            }}
                            label={t("enabled")}
                        />
                    </div>
                    {/* Response Options if Checkbox or Radio */}
                    {(type === "checkbox" || type === "radio") && (
                        <>
                            <Option title={t("response")}>
                                {response.map((item, index) => {
                                    return (
                                        // <div>
                                        <ResponseOption
                                            response={item}
                                            key={item.id}
                                            index={index}
                                            updateQuestion={updateQuestion}
                                        />
                                        // </div>
                                    );
                                })}
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "10px",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <AppButton
                                        className="primarySmallButton"
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                style={{ marginRight: "5px" }}
                                            />
                                        }
                                        style={{
                                            marginRight: "10px",
                                            fontSize: "13px",
                                            marginBottom: "4px",
                                        }}
                                        onClick={() =>
                                            updateQuestion([
                                                {
                                                    type: UPDATE_PROP,
                                                    prop: `response`,
                                                    value: (v) => [
                                                        ...v,
                                                        {
                                                            id: Math.floor(
                                                                Math.random() * Date.now()
                                                            ),
                                                            label: "",
                                                            score: 0,
                                                            hasCamera: false,
                                                            hasComment: 1,
                                                            color: "black",
                                                            isChecked: false,
                                                        },
                                                    ],
                                                },
                                            ])
                                        }
                                        value={t("addResponse")}
                                    />
                                    <AppButton
                                        className="whiteButton"
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                style={{ marginRight: "5px" }}
                                            />
                                        }
                                        style={{
                                            padding: "2px 4px",
                                            fontSize: "13px",
                                            lineHeight: 1,
                                            marginBottom: "4px",
                                            borderRadius: "8px",
                                        }}
                                        onClick={() =>
                                            /**
                                             * SAMPLE RESPONSES IS NEEDED HARDCODED DATA
                                             * DO NOT DELETE UNLESS YOU'RE MODIFYING THE LOGIC/DATA
                                             */
                                            updateQuestion([
                                                {
                                                    type: UPDATE_PROP,
                                                    prop: `response`,
                                                    value: (v) => {
                                                        return [...v, ...sampleResponses];
                                                    },
                                                },
                                            ])
                                        }
                                        value={t("yesNo")}
                                    />
                                </div>
                            </Option>
                            <Option title={t("defaultChoice")}>
                                <Label
                                    name={t("selectDefault")}
                                    className={styles.label}
                                    style={{ marginTop: "10px" }}
                                >
                                    <AppSelect
                                        options={[
                                            {
                                                value: "select_default",
                                                label: t("selectDefault"),
                                            },
                                            ...response.map((element) => ({
                                                value: element.label,
                                                label: element.label,
                                            })),
                                        ]}
                                        styles={filterSelectStyles}
                                        value={
                                            response.filter((item) => item.isChecked == true)[0]
                                                ? {
                                                      value: response.filter(
                                                          (item) => item.isChecked == true
                                                      )[0]?.label,
                                                      label: response.filter(
                                                          (item) => item.isChecked == true
                                                      )[0]?.label,
                                                  }
                                                : {
                                                      value: "select_default",
                                                      label: t("selectDefault"),
                                                  }
                                        }
                                        onChange={(e) => {
                                            response.map((element, index) => {
                                                if (element.value == "select_default") {
                                                    updateQuestion([
                                                        {
                                                            type: UPDATE_PROP,
                                                            prop: `response.${index}.isChecked`,
                                                            value: false,
                                                        },
                                                    ]);
                                                }
                                                if (element.label == e.value) {
                                                    console.log("true");
                                                    updateQuestion([
                                                        {
                                                            type: UPDATE_PROP,
                                                            prop: `response.${index}.isChecked`,
                                                            value: true,
                                                        },
                                                    ]);
                                                } else {
                                                    console.log("false");
                                                    updateQuestion([
                                                        {
                                                            type: UPDATE_PROP,
                                                            prop: `response.${index}.isChecked`,
                                                            value: false,
                                                        },
                                                    ]);
                                                }
                                            });
                                        }}
                                    />
                                </Label>
                            </Option>
                        </>
                    )}
                    {/* Question Help */}
                    <Option title={t("questionHelp")}>
                        <Label className={styles.label}>
                            <AppInput
                                className={styles.input}
                                onChange={(e) => {
                                    updateQuestion([
                                        {
                                            type: UPDATE_PROP,
                                            prop: `questionHelp`,
                                            value: e.target.value,
                                        },
                                    ]);
                                }}
                                value={questionHelp}
                                inputStyle={{ minHeight: "30px" }}
                                textarea
                            />
                        </Label>
                    </Option>
                    {/* Question Help Image */}
                    <Option title={t("addEditPicture")}>
                        <AppInput
                            ref={hiddenFileInput}
                            type="file"
                            onChange={(e) => {
                                const myNewFile = new File(
                                    [e.target.files[0]],
                                    `${selectedQuestion.sectionindex}-${selectedQuestion.subsectionindex}-${selectedQuestion.questionIndex}-${e.target.files[0].name}`,
                                    { type: e.target.files[0].type }
                                );
                                updateQuestion([
                                    {
                                        type: UPDATE_PROP,
                                        prop: `image`,
                                        value: myNewFile,
                                    },
                                ]);
                                setQuestionsImages(
                                    myNewFile,
                                    `${selectedQuestion.sectionindex}-${selectedQuestion.subsectionindex}-${selectedQuestion.questionIndex}`
                                );
                            }}
                            inputStyle={{ minHeight: "30px", display: "none" }}
                        />
                        <AppButton
                            classes="buttonIcon"
                            style={{
                                color: "white",
                                background: "#b8b8b8",
                                padding: "13px 10px",
                                fontSize: "13px",
                                borderRadius: "6px",
                                marginBottom: "20px",
                                flexDirection: "column",
                            }}
                            value={t("upload")}
                            onClick={handleClick}
                            icon={<FontAwesomeIcon icon={faPlus} />}
                        />
                        {/* {images.map((element, index) => ( */}
                        {image && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <img
                                    src={generateSrc(image)}
                                    width={250}
                                    // on click, open image in modal
                                    onClick={() => {
                                        window.open(generateSrc(image), "_blank");
                                    }}
                                />

                                <FontAwesomeIcon
                                    icon={faTimes}
                                    color="grey"
                                    style={{
                                        fontSize: "15px",
                                        marginLeft: "5px",
                                    }}
                                    onClick={() => {
                                        updateQuestion([
                                            {
                                                type: UPDATE_PROP,
                                                prop: `image`,
                                                value: null,
                                            },
                                        ]);
                                    }}
                                />
                            </div>
                        )}
                        <Label
                            name={t("showImage")}
                            className={styles.label}
                            style={{ marginTop: "20px" }}
                        >
                            <AppSelect
                                options={dataShowImage.map((element) => ({
                                    value: element,
                                    label: element,
                                }))}
                                styles={filterSelectStyles}
                                value={{ value: showImage, label: showImage }}
                                onChange={(e) => {
                                    console.log(e);
                                    updateQuestion([
                                        {
                                            type: UPDATE_PROP,
                                            prop: `showImage`,
                                            value: e.value,
                                        },
                                    ]);
                                }}
                            />
                        </Label>
                        <Label
                            name={t("printSize")}
                            className={styles.label}
                            style={{ marginTop: "20px" }}
                        >
                            <AppSelect
                                options={dataPrintSize.map((element) => ({
                                    value: element,
                                    label: element,
                                }))}
                                styles={filterSelectStyles}
                                value={{ value: printSize, label: printSize }}
                                onChange={(e) => {
                                    console.log(e);
                                    updateQuestion([
                                        {
                                            type: UPDATE_PROP,
                                            prop: `printSize`,
                                            value: e.value,
                                        },
                                    ]);
                                }}
                            />
                        </Label>
                    </Option>
                    {/* Question Notification nad Actionplan */}
                    {!(type === "text") && (
                        <>
                            <Option title={t("notification")}>
                                <SwitchButton
                                    checked={notificationEmailRequired}
                                    onChange={() => {
                                        updateQuestion([
                                            {
                                                type: UPDATE_PROP,
                                                prop: `notificationEmailRequired`,
                                                value: !notificationEmailRequired,
                                            },
                                        ]);
                                    }}
                                    label={t("notificationEmailRequired")}
                                />
                                {notificationEmailRequired && (
                                    <>
                                        <Label
                                            name={t("emails")}
                                            className={styles.label}
                                            style={{ marginTop: "20px", padding: "2px" }}
                                        >
                                            <MultiEmail
                                                emails={selectEmailAddress}
                                                setEmails={(value) => {
                                                    updateQuestion([
                                                        {
                                                            type: UPDATE_PROP,
                                                            prop: `selectEmailAddress`,
                                                            value: value,
                                                        },
                                                    ]);
                                                }}
                                            />
                                            {/* <AppSelect
                                    options={dataSelectEmailAddress.map((element) => ({
                                    value: element,
                                    label: element,
                                    }))}
                                    styles={filterSelectStyles}
                                    value={selectEmailAddress}
                                    onChange={(e) => {
                                    console.log(e);

                                    updateQuestion([
                                        {
                                        type: UPDATE_PROP,
                                        prop: `selectEmailAddress`,
                                        value: e,
                                        },
                                    ]);
                                    }}
                                    isMulti={true}
                                /> */}
                                        </Label>
                                        {response.length > 0 && (
                                            <>
                                                <div className={styles.text}>
                                                    {t("whichResponsesTriggerNotification")}
                                                </div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {response.map(
                                                        (element, index) =>
                                                            element.label && (
                                                                <CheckboxInput
                                                                    key={index}
                                                                    name={
                                                                        element.label +
                                                                        "notification"
                                                                    }
                                                                    label={element.label}
                                                                    isChecked={selectedTriggerNotification.includes(
                                                                        `${element.id}`
                                                                    )}
                                                                    onChange={(e) => {
                                                                        handleCheck(
                                                                            e,
                                                                            `selectedTriggerNotification`,
                                                                            selectedTriggerNotification
                                                                        );
                                                                    }}
                                                                    value={element.id}
                                                                    className={styles.singleInput}
                                                                />
                                                            )
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </Option>
                            <Option title={t("actionPlan")}>
                                {/* *** NOTE *** Removing this as email sending is required by default */}
                                {/* <SwitchButton
                                    checked={actionNotificationEmailRequired}
                                    onChange={() => {
                                        updateQuestion([
                                            {
                                                type: UPDATE_PROP,
                                                prop: `actionNotificationEmailRequired`,
                                                value: !actionNotificationEmailRequired,
                                            },
                                        ]);
                                    }}
                                    label="Notification Email Required"
                                /> */}
                                <SwitchButton
                                    checked={actionMandatory}
                                    onChange={() => {
                                        updateQuestion([
                                            {
                                                type: UPDATE_PROP,
                                                prop: `actionMandatory`,
                                                value: !actionMandatory,
                                            },
                                        ]);
                                    }}
                                    label={t("actionPlanRequired")}
                                />
                                {response.length > 0 && actionMandatory && (
                                    <>
                                        <div className={styles.text}>
                                            {t("whichResponsesTriggerPlan")}
                                        </div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                            {response.map((element, index) => (
                                                <CheckboxInput
                                                    key={index}
                                                    name={element.label + "plan"}
                                                    label={element.label}
                                                    isChecked={selectedTriggerPlan.includes(
                                                        `${element.id}`
                                                    )}
                                                    onChange={(e) => {
                                                        // console.warn('qqqqq')
                                                        console.error(selectedTriggerPlan, "plan");
                                                        handleCheck(
                                                            e,
                                                            "selectedTriggerPlan",
                                                            selectedTriggerPlan
                                                        );
                                                    }}
                                                    value={element.id}
                                                    className={styles.singleInput}
                                                />
                                            ))}
                                        </div>
                                    </>
                                )}
                                <Label
                                    name={t("assignee")}
                                    className={styles.label}
                                    style={{ marginTop: "20px" }}
                                >
                                    <AppSelect
                                        options={allUsers?.data?.map((element) => ({
                                            value: element.id,
                                            label: element.name,
                                        }))}
                                        styles={filterSelectStyles}
                                        value={actionPlanAssignee ?? ""}
                                        onChange={(e) => {
                                            console.log(e);
                                            debugger;
                                            updateQuestion([
                                                {
                                                    type: UPDATE_PROP,
                                                    prop: `actionPlanAssignee`,
                                                    value: e,
                                                },
                                            ]);
                                        }}
                                    />
                                </Label>
                            </Option>
                        </>
                    )}
                    {/* Question Tags */}
                    <Option title="Tags">
                        <Label className={styles.label}>
                            <CreatableSelect
                                options={dataTags.map((element) => ({
                                    value: element.tag,
                                    label: element.tag,
                                    id: element.id,
                                }))}
                                isMulti
                                isSearchable={true}
                                styles={filterSelectStyles}
                                value={tags}
                                // onInputChange={(v) => {
                                //     console.log("onchangeinputvalue");
                                //     console.log(v);
                                // }}
                                onChange={(e) => {
                                    console.log(e);
                                    updateQuestion([
                                        {
                                            type: UPDATE_PROP,
                                            prop: `tags`,
                                            value: e,
                                        },
                                    ]);
                                    saveTags(e);
                                }}
                            />
                        </Label>
                    </Option>
                    {/* <div className={styles.actionButton}>
                        <AppButton
                            className="primaryButton"
                            onClick={saveOptions}
                            value="Save"
                            isLoading={loading}
                        />
                       
                    </div> */}
                </Card>
            )}
        </>
    );
};

export default Options;
