import React from "react";
import classes from "./HeaderCenter.module.css";
import { Form, FormControl, Button } from "react-bootstrap";
import { HeaderNav } from "../HeaderNav";
import { Nav } from "react-bootstrap";
import { CenterNavLinks } from "../NavLinks";

const HeaderCenter = () => {
  return (
    <Nav style={{ flex: 4.2 }}>
      <HeaderNav
        items={CenterNavLinks()}
        activeClass={classes.activeLink}
        defaultClass={classes.NavLink}
      />
    </Nav>
  );
};

export default HeaderCenter;
