import { useEffect } from "react";
import { UPDATE_PROP } from "../../../../../../store/ActionTypes";

export const INITIAL_STATE = {
  selectedPermission: [],
};
const Logic = (dispatch, state, prop) => {
  const handleMultiChange = (option) => {
    console.log("option");
    console.log(option);
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `selectedPermission`,
          value: option,
        },
      ],
    });
  };
  useEffect(() => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `selectedPermission`,
          value: prop.selectedPermission,
        },
      ],
    });
  }, []);
  return {
    actions: { handleMultiChange },
  };
};

export default Logic;
