import AppButton from "../../../../components/general/app-button/AppButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
export const DepartmentColumn = (
    actions,
    translate = (term) => {
        return term;
    }
) => {
    return [
        {
            Header: `${translate("name")}`,
            accessor: "name",
        },
        {
            Header: `${translate("shortName")}`,
            accessor: "short_name",
        },
        {
            Header: `${translate("actions")}`,
            // className: "frozen",
            style: {
                textAlign: "center",
            },
            Cell: ({ row: { values, original } }) => (
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <AppButton
                        classes="buttonIcon"
                        onClick={() => actions.navigate(`/add-department?id=${original.id}`)}
                        value={translate("edit")}
                        style={{
                            color: "#2e5bff",
                        }}
                        icon={
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ marginRight: "5px", marginLeft: "5px" }}
                            />
                        }
                    />
                    <AppButton
                        classes="buttonIcon"
                        style={{ color: "#fb3737" }}
                        onClick={() => actions.bulkDelete([original])}
                        value={translate("delete")}
                        icon={
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                style={{ marginRight: "5px", marginLeft: "5px" }}
                            />
                        }
                    />
                </div>
            ),
        },
    ];
};
