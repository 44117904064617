import React from "react";
import { Navbar, Nav } from "react-bootstrap";

const Header = ({ left, center, right, classes }) => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className={classes}>
        <Navbar.Collapse id="responsive-navbar-nav">
          {left && left}
          {center && center}
          {right && right}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
