import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UseAppTable from "../../helpers/hooks/use-app-table";
import {
  fetchSubscription,
  fetchTicketsSummery,
  updateContactPeson,
  fetchAllSettingsData,
} from "../../api/services/SettingsServices";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../store/ActionTypes";
import { ERROR, SUCCESS } from "../../components/general/app-toast/AppToast";
import { subscriptionColumns } from "./constants";
import { useTranslation } from "react-i18next";
// import UseAppToast from "../../../helpers/hooks/use-app-toast";

export const INITIAL_STATE = {
  form: {
    contact_person_name: "",
    job_title: "",
    contact_person_mobile: "",
    contact_person_whatsapp: "",
    contact_person_email: "",
  },
  customer: {
    // companyDetails: {
    //     name: "company",
    //     commReg: "comment",
    //     taxCard: "test",
    //     numberOfEmployee: "test",
    //     sector: "test",
    //     industry: "test",
    //     activeUsers: "test",
    //     addresses: "address",
    // },
    // contactPerson: {
    //     name: "mohamed",
    //     position: "2",
    //     mobile: "0133233",
    //     email: "email@email.com",
    //     whatsapp: "0321313",
    //     website: "website.com",
    // },
  },
  settingsData: {},
  // inquiriesAndtickets: [
  //   {
  //       text: "inquiries",
  //       status: [
  //           { label: "open", number: 150 },
  //           { label: "in progress", number: 150 },
  //           { label: "open", number: 150 },
  //           { label: "open", number: 150 },
  //           { label: "open", number: 150 },
  //       ],
  //   },
  //   {
  //       text: "tickets",
  //       status: [
  //           { label: "open", number: 150 },
  //           { label: "in progress", number: 150 },
  //           { label: "open", number: 150 },
  //           { label: "open", number: 150 },
  //           { label: "open", number: 150 },
  //       ],
  //   },
  // ],
  inquiriesAndtickets: {},
  allTicketsTotal: "",
  saveLoading: false,
  pageLoading: false,
  customerLoading: false,
  ticketsLoading: false,
  error: null,
};
const Logic = (dispatch, state) => {
  const { data, setData, error } = UseAppTable({
    fetchTableData: fetchSubscription,
  });
  const navigate = useNavigate();
  const { addToast } = UseAppToast();
  const { t, i18n } = useTranslation();

  useEffect(() => {}, []);
  let columns = useMemo(
    () => subscriptionColumns({ navigate }, t),
    [i18n.language]
  );
  // const transformatedInquiriesAndtickets = transformApiResponse(state?.inquiriesAndtickets);
  // console.log(allTicketsTotal);
  return {
    actions: { navigate },
    columns,
    data,
    error,
    setData,
  };
};

export default Logic;
