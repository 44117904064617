import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteArea, fetchAreas } from "../../../../api/services/AreaServices";
import {
  ERROR,
  SUCCESS,
} from "../../../../components/general/app-toast/AppToast";
import UseAppTable from "../../../../helpers/hooks/use-app-table";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
import { AreasColumn } from "./AreasColumn";
import { useTranslation } from "react-i18next";

export const INITIAL_STATE = { showDeleteAlert: false, deletedID: null };
const Logic = (dispatch, state) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { addToast } = UseAppToast();
  const { data, setData, error } = UseAppTable({
    fetchTableData: fetchAreas,
  });

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const HandleCloseDelete = () => {
    setShowDeleteAlert(false);
  };

  const bulkDelete = (selectedRow) => {
    setShowDeleteAlert(true);

    setData((prevState) => {
      return {
        ...prevState,
        selectedRows: selectedRow,
      };
    });
  };
  const HandelDelete = () => {
    // update modal loading
    dispatch({
      payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: true }],
    });
    const selectedArray = data.selectedRows.map((d) => d.id);
    deleteArea(
      (res) => {
        let arrResults = [...data.results];
        selectedArray.map((d) => {
          const index = arrResults.findIndex((x) => {
            console.log(x.id);
            return x.id === d;
          });
          arrResults.splice(index, 1);
        });
        setData((prevState) => {
          let newData = {
            results: [...arrResults],
            total: prevState.total - 1,
          };
          if (arrResults.length == 0) {
            newData = {
              ...newData,
              params: {
                ...prevState.params,
                page: prevState.params.page - 1,
              },
            };
          }
          return {
            ...prevState,
            ...newData,
          };
        });
        HandleCloseDelete();

        addToast({
          type: SUCCESS,
          description: "Deleted successfully",
          title: "Success",
        });
      },
      (error) => {
        HandleCloseDelete();

        addToast({
          type: ERROR,
          description: "Something went wrong",
          title: "Error",
        });
      },
      () => {
        dispatch({
          payload: [{ type: UPDATE_PROP, prop: "deleteLoading", value: false }],
        });
      },
      { ids: selectedArray }
    );
  };

  const actions = () => {
    return { bulkDelete, navigate };
  };
  let columns = useMemo(() => AreasColumn(actions(), t), [i18n.language]);

  return {
    actions: {
      setData,
      navigate,
      setShowDeleteAlert,
      HandleCloseDelete,
      HandelDelete,
    },
    columns,
    data,
    error,
    showDeleteAlert,
  };
};

export default Logic;
