import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import AppButton from "../../../../components/general/app-button/AppButton";
import Card from "../../../../components/general/card/Card";
import styles from "./Layout.module.css";
import { useTranslation } from "react-i18next";


const Layout = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

// Declare Layout.Filter as a standalone component
const LayoutFilter = ({
  disabled = false,
  onClick,
  isLoading,
  filters = [],
  ...props
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={styles.leftSideContainer}>
      <div className={styles.filterContainer}>
        <Card
          style={{
            padding: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <AppButton
              onClick={toggleCollapse}
              value={
                isCollapsed ? (
                  // icons
                  <FontAwesomeIcon
                    icon={faChevronCircleDown}
                    color={"var(--primary)"}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronCircleUp}
                    color={"var(--primary)"}
                  />
                )
              }
            />
            <p className={styles.filterTitle}>{t("filters")}</p>
            {
              // show filters as bullets if collapsed
              isCollapsed && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    // marginTop: "10px",
                    flexWrap: "wrap",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  {/* filters is an array of objects with keys and values */}
                  {filters.map(
                    (filter) =>
                      filter.value && (
                        <div
                          key={filter.key}
                          style={{
                            backgroundColor: "var(--primary)",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "15px",
                          }}
                        >
                          {/* Capitalize first character of key and remove underscores */}
                          {filter.key.charAt(0).toUpperCase() +
                            filter.key.slice(1).replace("_", " ")}
                          :{" "}
                          {
                            // if value is an array, the inside objects are keys and values, join the values inside with a comma
                            Array.isArray(filter.value)
                              ? filter.value.map((val) => val.label).join(", ")
                              : filter.value
                          }
                        </div>
                      )
                  )}
                </div>
              )
            }
          </div>

          {!isCollapsed && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                marginTop: "10px",
              }}
            >
              {props.children}
            </div>
          )}
          {!isCollapsed && (
            <AppButton
              className="primaryButton"
              onClick={onClick}
              disabled={disabled}
              value={t("apply")}
              style={{ padding: "3px 35px", marginTop: 20 }}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

// Assign the standalone component to Layout.Filter
Layout.Filter = LayoutFilter;

Layout.Body = (props) => (
  <div className={styles.rightSideContainer} {...props}>
    {props.children}
  </div>
);

export default Layout;
