import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { currentassessments } from "../../api/services/AuditServices";
import ConfirmModal from "../../components/general/confirm-modal/ConfrimModal";
import TableLayout from "../../components/general/table-layout/TableLayout";
import useLogic from "../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";
// import Parent from "./parent";

const Audits = () => {
  // request  data
  const {
    state,
    updateState,
    data,
    setData,
    error,
    showDeleteAlert,
    setShowDeleteAlert,
    HandleCloseDelete,
    HandelDelete,
    columns,
  } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  useEffect(() => {
    currentassessments(
      (res) => {
        // console.log(typeof res.data);
      },
      (error) => {},
      () => {},
      { filters: [], page: 1 }
    );
  }, []);
  const { t } = useTranslation();
  const filterData = [
    {
      key: "audit_templates.name",
      label: t("audit"),
      sign: [{ value: "contains", label: t("contains") }],
    },
    {
      key: "audits.date",
      label: t("date"),
      sign: [{ value: "is_between", label: t("isBetween") }],
    },
    {
      key: "departments.name",
      label: t("department"),
      sign: [{ value: "contains", label: t("contains") }],
    },
    {
      key: "locations.name",
      label: t("location"),
      sign: [{ value: "contains", label: t("contains") }],
    },
    {
      key: "users.short_name",
      label: t("auditor"),
      sign: [{ value: "contains", label: t("contains") }],
    },
    {
      key: "audits.status",
      label: t("status"),
      sign: [{ value: "is_equal", label: t("isEqual") }],
    },

    // ... other filters
  ];

  const filterKeys = {
    "audit_templates.name": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: t("contains") },
        { value: "is_equal", label: t("equals") },
        // ... other signs
      ],
    },
    "departments.name": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: t("contains") },
        { value: "is_equal", label: t("equals") },
        // ... other signs
      ],
    },
    "locations.name": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: t("contains") },
        { value: "is_equal", label: t("equals") },
        // ... other signs
      ],
    },
    "users.short_name": {
      type: "string",
      dropdown: false,
      sign: [
        { value: "contains", label: t("contains") },
        { value: "is_equal", label: t("equals") },
        // ... other signs
      ],
    },
    "audits.date": {
      type: "date",
      dropdown: false,
      sign: [
        { value: "is_equal", label: t("equals") },
        { value: "is_between", label: t("isBetween") },
        { value: "greater_than", label: t("greaterThan") },
        { value: "less_than", label: t("lessThan") },
        { value: "greater_than_or_equal_to", label: t("greaterThanOrEqual") },
        { value: "less_than_or_equal_to", label: t("lessThanOrEqual") },

        // ... other signs
      ],
    },
    "audits.status": {
      type: "dropdown",
      dropdown: true,
      sign: [
        { value: "is_equal", label: t("equals") },
        // ... other signs
      ],
      dropdown: [
        { value: "0", label: t("Pending") },
        { value: 1, label: t("Completed") },
        { value: 2, label: t("Missed") },
      ],
    },
    // "users.short_name": {
    //     type: "string",
    //     dropdown: false,
    //     sign: [
    //         { value: "contains", label: t("contains") },
    //         { value: "is_equal", label: t("equals") },
    //         // ... other signs
    //     ],
    // },
  };
  return (
    <>
      {console.log("data", data)}
      <TableLayout
        tableColumns={columns}
        setData={setData}
        title={`${t("audits")} (${data.total})`}
        showButton={data.selectedRows.length !== 0}
        data={data}
        error={error}
        buttonValue={`Delete Selected (${data.selectedRows.length})`}
        buttonOnClick={() => {
          setShowDeleteAlert(true);
        }}
        buttonStyle={{ color: "white" }}
        buttonClassName="primaryButton"
        filterData={{ filterData }}
        filterKeys={{ filterKeys }}
        showFilter={true}
        showCheckbox
      />
      {showDeleteAlert && (
        <ConfirmModal
          show={showDeleteAlert}
          onHide={() => HandleCloseDelete()}
          onSave={() => HandelDelete()}
          isLoading={state.deleteLoading}
        />
      )}
    </>
  );
};
export default Audits;
