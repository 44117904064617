import ReactDOM from "react-dom";
import AppToast from "./AppToast";
import UseAppToast from "../../../helpers/hooks/use-app-toast";

const ToastPortal = ({ autoClose = false, autoCloseTime = 5000 }) => {
  const { toasts, removeToast } = UseAppToast();
  return ReactDOM.createPortal(
    <>
      {toasts.map((t) => (
        <AppToast
          key={t.id}
          autoClose={autoClose}
          autoCloseTime={autoCloseTime}
          onClose={() => removeToast(t.id)}
          toast={t}
        />
      ))}
    </>,

    document.getElementById("toast-container")
  );
};

export default ToastPortal;
