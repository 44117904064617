import NumberWithLabel from "../number-with-label/NumberWithLabel";
import styles from "./TotalContainer.module.css";

const TotalContainer = ({ data, textStyle, containerTotalStyle = {}, ...props }) => {
    const { className, ...rest } = props;
    const allstyles = `${styles.container} ${className != undefined ? className : ""}`;
    const textstyles = `${styles.verticalText} ${textStyle != undefined ? textStyle : ""}`;
    return (
        <>
            {data.map((item, index) => (
                <div className={allstyles} key={index}>
                    {item.text && <p className={textstyles}>{item.text}</p>}
                    {item.status.map((status, statusIndex) => (
                        <NumberWithLabel
                            key={statusIndex}
                            number={status.number}
                            label={status.label}
                            containerStyle={{
                                padding: "10px 15px",
                                fontSize: "15px",
                                ...containerTotalStyle,
                            }}
                        />
                    ))}
                </div>
            ))}
        </>
    );
};

export default TotalContainer;
