import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import AppButton from "../../../../components/general/app-button/AppButton";
import AppInput from "../../../../components/general/app-input/AppInput";
import AppTable from "../../../../components/general/app-table/AppTable";
import Card from "../../../../components/general/card/Card";
import ConfirmModal from "../../../../components/general/confirm-modal/ConfrimModal";
import Label from "../../../../components/general/label/Label";
import LoadingSpinner from "../../../../components/general/loading-spinner/LoadingSpinner";
import Title from "../../../../components/general/title/Title";
import ValidationProvider from "../../../../components/general/validation-provider/ValidationProvider";
import useLogic from "../../../../helpers/hooks/use-logic";
import useValidator from "../../../../helpers/hooks/use-validator";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
import styles from "./AddRole.module.css";
import AddPermissionModal from "./components/add-permissions/AddPermissionModal";
import Logic, { INITIAL_STATE } from "./logic";

const AddRole = () => {
  const {
    state,
    navigate,
    AddRole,
    updateState,
    columns,
    permissions,
    HandleCloseDelete,
    HandelDelete,
  } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  const { t } = useTranslation();
  const { errors, onBlur, onChange, handleSubmit } = useValidator({
    defaultValues: {
      ...state.form,
    },
    setValue: (nextFormState) => {
      updateState({
        prop: "form",
        value: nextFormState,
      });
    },
    onSubmit: AddRole,
    validations: {
      name: {
        required: true,
        validation_name: "Name",
      },
      description: {
        required: false,
        validation_name: "Description",
      },
      selectedPermission: {
        required: true,
        validation_name: "Permissions",
      },
    },
  });
  return (
    <>
      <div className={styles.title}>
        <Title title={t("newRole")}></Title>
      </div>
      <Card style={{ padding: 20 }}>
        <LoadingSpinner isLoading={state.loading}>
          <div className={styles.container}>
            <div className={styles.sectionContainer}>
              <div className={styles.groupControl}>
                <ValidationProvider
                  error={errors.name}
                  render={({ requiredTitle }) => (
                    <Label
                      name={requiredTitle(t("name"))}
                      className={styles.label}
                    >
                      <div className={styles.inputContainer}>
                        <AppInput
                          type="text"
                          onChange={(e) => onChange("name", e.target.value)}
                          onBlur={(e) => onBlur("name")}
                          value={state.form.name}
                          disabled={state.form.is_admin === 1}
                        />
                      </div>
                    </Label>
                  )}
                />
              </div>
              <div className={styles.groupControl}>
                <ValidationProvider
                  error={errors.description}
                  render={({ requiredTitle }) => (
                    <Label name={t("description")} className={styles.label}>
                      <div className={styles.inputContainer}>
                        <AppInput
                          type="text"
                          onChange={(e) =>
                            onChange("description", e.target.value)
                          }
                          onBlur={(e) => onBlur("description")}
                          value={state.form.description}
                          disabled={state.form.is_admin === 1}
                        />
                      </div>
                    </Label>
                  )}
                />
              </div>
            </div>
            <div className={styles.sectionContainer}>
              <ValidationProvider
                error={errors.selectedPermission}
                render={({ requiredTitle }) => (
                  <>
                    <Title
                      title={requiredTitle(t("permissions"))}
                      border={true}
                      style={{ color: "black", fontSize: "15px" }}
                    >
                      {state.form.is_admin !== 1 && (
                        <AppButton
                          className="primaryButton"
                          style={{
                            borderRadius: 0,
                            borderTopRightRadius: "6px",
                            borderTopLeftRadius: "6px",
                            padding: "2px 10px",
                            borderBottom: "none",
                            cursor: "pointer",
                            lineHeight: 1,
                            fontSize: "13px",
                          }}
                          onClick={() => {
                            updateState({
                              prop: "addPermissionModal",
                              value: true,
                            });
                          }}
                          value={t("add")}
                          icon={
                            <FontAwesomeIcon
                              icon={faPlus}
                              style={{ margin: "0 5px" }}
                            />
                          }
                        />
                      )}
                    </Title>
                    <div
                      className={styles.permissionContainer}
                      style={{ padding: "5px 0" }}
                    >
                      <AppTable
                        columns={columns}
                        data={permissions}
                        showPagination={false}
                      />
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          {state.addPermissionModal && (
            <AddPermissionModal
              show={state.addPermissionModal}
              set={() => {
                updateState({
                  type: UPDATE_PROP,
                  prop: "addPermissionModal",
                  value: false,
                });
              }}
              permissions={state.permissions}
              selectedPermission={state.form.selectedPermission.map((p) => {
                return {
                  value: p.id,
                  label: p.name,
                  ...p,
                };
              })}
              addPermissions={(value) => onChange("selectedPermission", value)}
            />
          )}
          {state.showDeleteAlert && (
            <ConfirmModal
              show={state.showDeleteAlert}
              onHide={() => HandleCloseDelete()}
              onSave={() => HandelDelete()}
              isLoading={state.deleteLoading}
            />
          )}
          <div className={styles.actionButton}>
            <AppButton
              className="primaryButton"
              onClick={handleSubmit}
              value={t("save")}
              isLoading={state.saveLoading}
              type="button"
            />
            <AppButton
              className="whiteButton"
              onClick={() => navigate("/portal/roles")}
              value={t("cancel")}
              style={{ margin: "0px 15px" }}
            />
          </div>
        </LoadingSpinner>
      </Card>
    </>
  );
};

export default AddRole;
