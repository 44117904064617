import { useTranslation } from "react-i18next";
import AppButton from "../app-button/AppButton";
import AppModal from "../app-modal/AppModal";

const ConfirmModal = ({ show, onHide, onSave, message, isLoading = false }) => {
  const { t } = useTranslation();
  return (
    <AppModal
      show={show}
      onHide={onHide}
      size="sm"
      closeBtn="true"
      footer={
        <>
          <AppButton
            classes="defaultButton"
            style={{ padding: "1px 12px" }}
            onClick={onHide}
            value={t("cancel")}
          />
          <AppButton
            classes="defaultButton"
            style={{ padding: "1px 12px" }}
            onClick={onSave}
            value={t("delete")}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </>
      }
      bodyStyle={{ paddingTop: 0 }}
      footerStyle={{ border: 0, paddingTop: 0 }}
      headerStyle={{ border: 0, paddingBottom: 0 }}
    >
      <p>{message ? message : t("deleteConfirmationMsg")}</p>
    </AppModal>
  );
};

export default ConfirmModal;
