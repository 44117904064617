import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../components/general/card/Card";
import ConfirmModal from "../../../components/general/confirm-modal/ConfrimModal";
import NumberWithLabel from "../../../components/general/number-with-label/NumberWithLabel";
import TableLayout from "../../../components/general/table-layout/TableLayout";
import TotalContainer from "../../../components/general/total-container/TotalContainer";
import useLogic from "../../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";
import Styles from "./Tickets.module.css";
import Title from "../../../components/general/title/Title";

const Tickets = () => {
    // request  data
    const {
        state,
        data,
        error,
        setData,
        columns,
        HandleCloseDelete,
        HandelDelete,
        showDeleteAlert,
    } = useLogic({
        INITIAL_STATE,
        Logic,
    });
    const { t } = useTranslation();
    const filterData = [
        {
            key: "tickets.ticket_number",
            label: "Ticket Number",
            sign: [{ value: "contains", label: t("Contains") }],
        },
        {
            key: "tickets.type",
            label: "Type",
            sign: [{ value: "contains", label: t("Contains") }],
        },
        {
            key: "tickets.updated_at",
            label: "Last Activity",
            sign: [{ value: "is_equal", label: t("Equals") }],
        },
        {
            key: "tickets.status",
            label: "Status",
            sign: [{ value: "contains", label: t("Contains") }],
        },
        // {
        //   key: "companies.contact_person_name",
        //   label: "Contact Person",
        //   sign: [{ value: "contains", label: "contains" }],
        // },
        // {
        //   key: "companies.contact_person_mobile",
        //   label: "Mobile",
        //   sign: [{ value: "contains", label: "contains" }],
        // },
        // {
        //   key: "companies.contact_person_email",
        //   label: "Email",
        //   sign: [{ value: "is_between", label: "isBetween" }],
        // },

        // ... other filters
    ];

    const filterKeys = {
        "tickets.ticket_number": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("Contains") },
                { value: "is_equal", label: t("Equals") },
                // ... other signs
            ],
        },
        "tickets.type": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("Contains") },
                { value: "is_equal", label: t("Equals") },
                // ... other signs
            ],
        },
        // "companies.contact_person_name": {
        //   type: "string",
        //   dropdown: false,
        //   sign: [
        //     { value: "contains", label: "contains" },
        //     { value: "is_equal", label: "equals" },
        //     // ... other signs
        //   ],
        // },
        // "companies.contact_person_email": {
        //   type: "string",
        //   dropdown: false,
        //   sign: [
        //     { value: "contains", label: "contains" },
        //     { value: "is_equal", label: "equals" },
        //     // ... other signs
        //   ],
        // },
        // "companies.contact_person_mobile": {
        //   type: "string",
        //   dropdown: false,
        //   sign: [
        //     { value: "contains", label: "contains" },
        //     { value: "is_equal", label: "equals" },
        //     // ... other signs
        //   ],
        // },
        "tickets.status": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("Contains") },
                { value: "is_equal", label: t("Equals") },
                // ... other signs
            ],
        },
        "tickets.updated_at": {
            type: "date",
            dropdown: false,
            sign: [
                { value: "is_equal", label: t("Equals") },
                { value: "is_not_equal", label: t("Not Equals") },
                { value: "is_between", label: t("Is Between") },
                { value: "greater_than", label: t("Greater Than") },
                { value: "less_than", label: t("Less Than") },
                {
                    value: "greater_than_or_equal_to",
                    label: t("Greater Than Or Equal"),
                },
                { value: "less_than_or_equal_to", label: t("Less Than Or Equal") },

                // ... other signs
            ],
        },
    };

    return (
        <>
            {/* <div className={Styles.title}>
                <Title title={`${"Tickets"}(${data.total})`}></Title>
            </div> */}
            <Card style={{ padding: "10px 10px", marginBottom: "20px", display: "flex" }}>
                {/* <NumberWithLabel
          className={Styles.totaltickets}
          number={state.allTicketsTotal}
          label="Inquiries/Tickets"
          textConatinerClassname={Styles.textConatiner}
          contentClassname={Styles.content}
        >
          <TotalContainer
            data={
              Array.isArray(state?.inquiriesAndtickets)
                ? state.inquiriesAndtickets
                : []
            }
          />
        </NumberWithLabel> */}

                {/* <Title title="Inquiries & Tickets" border={true} /> */}
                <div
                    style={{
                        flex: 1,
                        padding: "8px",
                        background: "#f7f7f7",
                    }}
                >
                    <NumberWithLabel
                        number={state.allTicketsTotal}
                        label={t("Inquiries/Tickets")}
                        textConatinerClassname={Styles.textConatiner}
                        contentClassname={Styles.content}
                    >
                        <TotalContainer
                            data={
                                Array.isArray(state?.inquiriesAndtickets)
                                    ? state.inquiriesAndtickets
                                    : []
                            }
                        />
                    </NumberWithLabel>
                </div>
            </Card>

            <TableLayout
                title={`${t("Tickets")}(${data?.total})`}
                tableColumns={columns}
                setData={setData}
                data={data}
                error={error}
                filterData={{ filterData }}
                filterKeys={{ filterKeys }}
                showFilter={true}
                showPagination={true}
                buttonStyle={{ color: "white" }}
                buttonClassName="primaryButton"
            />

            {showDeleteAlert && (
                <ConfirmModal
                    show={showDeleteAlert}
                    onHide={() => HandleCloseDelete()}
                    onSave={() => HandelDelete()}
                    message={"Are you sure you want to delete?"}
                    isLoading={state.deleteLoading}
                />
            )}
        </>
    );
};
export default Tickets;
