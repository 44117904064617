import dotProp from "dot-prop-immutable";
import { UPDATE_PROP, DELETE_PROP, MERGE_PROP } from "./ActionTypes";

// REDUCER FOR LOCAL STATE
export const reducer = (state, action) => {
  // const { UPDATE_PROP, DELETE_PROP, MERGE_PROP } = ACTION_TYPES;
  let newState = state;
  for (let i = 0; i < action?.payload?.length; i++) {
    switch (action.payload[i].type) {
      case UPDATE_PROP: {
        newState = dotProp.set(
          newState,
          action.payload[i].prop,
          action.payload[i].value
        );
        break;
      }
      case DELETE_PROP: {
        newState = dotProp.delete(newState, action.payload[i].prop);
        break;
      }
      case MERGE_PROP: {
        newState = dotProp.merge(
          newState,
          action.payload[i].prop,
          action.payload[i].value
        );
        break;
      }
    }
  }
  return newState;
};

// REDUCER FOR REDUX
// IT DUPLICATES WE KNOW BUT REDUX DOES NOT ALLOW DISPATCH ARRAY OF ACTIONS
export const reduxReducer = (state, action) => {
  let newState = state;
  switch (action.type) {
    case UPDATE_PROP:
      newState = dotProp.set(newState, action.prop, action.value);
      break;
    case DELETE_PROP:
      newState = dotProp.delete(newState, action.prop);
      break;
    case MERGE_PROP: {
      newState = dotProp.merge(newState, action.prop, action.value);
      break;
    }
  }
  return newState;
};
