import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../../../components/general/app-button/AppButton";

export const PermissionColumn = (
  actions,
  role_admin = false,
  translate = (term) => {
    return term;
  }
) => {
  return [
    {
      Header: translate("name"),
      accessor: "name",
    },
    {
      Header: translate("type"),
      accessor: "type",
    },
    {
      Header: translate("actions"),
      // className: "frozen",
      style: {
        textAlign: "center",
      },
      Cell: ({ row: { values, original } }) => (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <AppButton
            classes="buttonIcon"
            style={{
              color: role_admin ? "#c7c3c3" : "red",
            }}
            onClick={() => {
              actions.delete(original.id);
            }}
            value={translate("delete")}
            icon={
              <FontAwesomeIcon
                icon={faTrashAlt}
                style={{ marginRight: "5px" }}
              />
            }
            disabled={role_admin}
          />
        </div>
      ),
    },
  ];
};
