export const filterSelectStyles = {
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected && "var(--primary)",
      padding: 10,
      display: "flex",
      flex: 1,
      color: state.isSelected ? "white" : "var(--primary)",
    };
  },
  control: () => ({
    display: "flex",
    alignItems: "center",
    // border: "1px solid var(--primary)",
    height: "100%",
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    overflow: "hidden",
    flex: 1,
  }),
  container: (provided, state) => {
    return { ...provided, display: "flex", flex: 1, height: "100%" };
  },
  placeholder: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided, state) => {
    return { ...provided, color: "var(--primary)", padding: 5 };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  singleValue: (provided, state) => {
    return { ...provided, color: "var(--primary)" };
  },
};
