import { faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../../components/general/app-button/AppButton";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
export const TicketsColumn = (actions,  translate = (term) => {
    return term;
}) => {
    return [
        {
            Header: `${translate("ticket number")}`,
            accessor: "ticket_number",
        },
        {
            Header: `${translate("id")}`,
            accessor: "id",
            show: false,
        },
        {
            Header: `${translate("type")}`,
            accessor: "type",
        },
        {
            Header: `${translate("last activity")}`,
            accessor: "updated_at",
        },
        {
            Header: `${translate("status")}`,
            accessor: "status",
            maxWidth: 70,
            minWidth: 70,
        },
        // {
        //   Header: "Contact Person",
        //   accessor: "contact_person_name",
        // },
        // {
        //   Header: "Email",
        //   accessor: "contact_person_email",
        // },
        // {
        //   Header: "Mobile",
        //   accessor: "contact_person_mobile",
        // },
        {
            Header: `${translate("actions")}`,
            // className: "frozen",
            style: {
                textAlign: "center",
            },
            Cell: ({ row: { original, values } }) => (
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <AppButton
                        classes="buttonIcon"
                        style={{ color: "#fb3737" }}
                        onClick={() => actions.bulkDelete([original])}
                        value ={`${translate("delete")}`}
                        icon={<FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: "5px",
                            marginLeft: "5px",

                         }} />}
                    />
                    <AppButton
                        classes="buttonIcon"
                        onClick={() =>actions.navigate(`/portal/ticket-details/${values.id}`)}
                        value={`${translate("view")}`}
                        style={{
                            color: "#17c62e",
                        }}
                        icon={<FontAwesomeIcon icon={faEye} style={{ marginRight: "5px",
                            marginLeft: "5px",
                            
                            
                         }} />}
                    />
                </div>
            ),
        },
    ];
};
