import { Navigate } from "react-router-dom";
import { AUTHENTICATION_TOKEN } from "./constants/StaticKeys";
import { getUserFromLS } from "./Storage";

const ProtectedRoute = ({ children }) => {
    // let isAuthenticated = false;
    let token = localStorage.getItem(AUTHENTICATION_TOKEN);
    const user = getUserFromLS();
    // it's necessary to check the permissions of the user since not all routes are accessible to everyone
    /**
     * 4 => admin routes
     * 1 => portal
     * 2 => app
     * 3 => analytics
     */

    if (!token) {
        return <Navigate to="/login" />;
    }
    // if (user?.subscription?.days_left < 0) {
    //     return <Navigate to="/" />;
    // }
    return children;
    // if (location.pathname == "/") {
    //   // return <Navigate to="/" />;
    //   return (
    //     <Navigate
    //       to={
    //         permissions.includes(1) || permissions.includes(4)
    //           ? "audits"
    //           : permissions.includes(3)
    //           ? "analytics/overview"
    //           : "login"
    //       }
    //     />
    //   );
    // }
    // if (permissions.length != 0) {
    //   // admin permissions
    //   if (permissions.includes(4)) {
    //     return children;
    //   } else {
    //     let pathname = location.pathname;

    //     // analytics permissions
    //     if (permissions.includes(3)) {
    //       if (pathname.includes("analytics")) {
    //         return children;
    //       }
    //     }
    //     // portal permissions
    //     if (permissions.includes(1)) {
    //       let allowedRoutes = [
    //         "audits",
    //         "departments",
    //         "locations",
    //         "schedule",
    //         "forms",
    //         "checklists",
    //       ];
    //       let isAllowed = false;
    //       allowedRoutes.forEach((route) => {
    //         if (pathname.includes(route)) {
    //           isAllowed = true;
    //         }
    //       });
    //       if (isAllowed) {
    //         return children;
    //       }
    //     }
    //     else if(localStorage.getItem('features') == 3)
    //     return <Navigate to="/analytics/overview" />;

    //     return <Navigate to="/404" />;
    //   }
    // }
    //   return token ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
