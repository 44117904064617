import React, { useState, useRef, useEffect } from "react";
import "./popup.css";
const Popup = ({ popupContainerStyle = {}, buttonStyle = {}, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef();

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    return (
        <div ref={menuRef} style={{ position: "relative" }}>
            <button className="popup-button" style={buttonStyle} onClick={() => setIsOpen(!isOpen)}>
                {props.buttonContent ? props.buttonContent : "Columns"}
            </button>
            {isOpen && (
                <div className="popup-container" style={popupContainerStyle}>
                    {" "}
                    {props.children}
                </div>
            )}
        </div>
    );
};

export default Popup;
