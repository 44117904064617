import { useTranslation } from "react-i18next";
import AppButton from "../../../../../components/general/app-button/AppButton";
import AppModal from "../../../../../components/general/app-modal/AppModal";
import styles from "./ActionPlanPopup.module.css";
import Card from "../../../../../components/general/card/Card";
import Label from "../../../../../components/general/label/Label";
import AppInput from "../../../../../components/general/app-input/AppInput";
import Spacer from "../../../../../components/general/spacer/Spacer";
import SimpleTable from "../../../../../components/general/simple-table/SimpleTable";
import AppSelect from "../../../../../components/general/app-select/AppSelect";
import { UPDATE_PROP } from "../../../../../store/ActionTypes";

const NewProgressModal = ({
    show,
    onHide,
    onSave = () => {
        console.log("Default Save Pressed");
    },
    data,
    allUsers,
    updateProps,
    loading,
}) => {
    const { t } = useTranslation();
    return (
        <AppModal
            show={show}
            onHide={onHide}
            fullscreen={false}
            closeBtn="true"
            size={"md"}
            footer={
                <>
                    <AppButton
                        style={{ padding: "1px 12px" }}
                        onClick={onHide}
                        value={t("cancel")}
                        disabled={loading}
                    />
                    <AppButton
                        className="primaryButton"
                        style={{ padding: "1px 12px" }}
                        onClick={onSave}
                        value={t("save")}
                        disabled={loading}
                        isLoading={loading}
                    />
                </>
            }
            bodyStyle={{ paddingTop: 0 }}
            footerStyle={{ border: 0, paddingTop: 0 }}
            headerStyle={{ border: 0, paddingBottom: 0 }}
        >
            <div style={{}}>
                <div className={styles.container}>
                    <Card className={styles.defaultCard} style={{ overflow: "visible" }}>
                        <div className={styles.rowSection}>
                            <Label
                                name="Description"
                                className={styles.label}
                                classText={styles.labelText}
                            >
                                <AppInput
                                    type="text"
                                    className={styles.input}
                                    textarea={true}
                                    value={data?.description}
                                    onChange={(e) => {
                                        updateProps([
                                            {
                                                type: UPDATE_PROP,
                                                prop: `newAPProg.description`,
                                                value: e.target.value,
                                            },
                                        ]);
                                    }}
                                    // disabled={true}
                                    // onBlur={(e) => onBlur("first_name")}
                                />
                            </Label>
                        </div>
                        <Spacer height={25} />
                        <div className={styles.rowSection}>
                            <Label
                                name="Assigned"
                                className={styles.label}
                                classText={styles.labelText}
                            >
                                <AppSelect
                                    options={allUsers?.data?.map((element) => ({
                                        value: element.id,
                                        label: element.name,
                                    }))}
                                    // styles={filterSelectStyles}
                                    value={data?.assignee_id}
                                    onChange={(e) => {
                                        console.log(e);
                                        updateProps([
                                            {
                                                type: UPDATE_PROP,
                                                prop: `newAPProg.assignee_id`,
                                                value: e,
                                            },
                                        ]);
                                    }}
                                />
                            </Label>
                        </div>
                        <Spacer height={25} />
                    </Card>
                </div>
            </div>
        </AppModal>
    );
};

export default NewProgressModal;
