import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect, useRef } from "react";
import { UPDATE_PROP } from "../../../../../../store/ActionTypes";
import { updateOrCreateAuditTemplates } from "../../../../../../api/services/AuditServices";
import UseAppToast from "../../../../../../helpers/hooks/use-app-toast";
import { ERROR } from "../../../../../../components/general/app-toast/AppToast";
import { INITIAL_STATE as initial_table_state } from "../../../../../../helpers/RtCommon";
import { INITIAL_STATE as initalSurvey } from "../../../../../../store/Constants";
import { fetchAllUsers } from "../../../../../../api/services/UserServices";

export const INITIAL_STATE = {
    saveLoading: false,
};
const Logic = (dispatch, state, prop) => {
    let navigate = useNavigate();
    const hiddenFileInput = useRef(null);
    const assignees = useSelector((state) => {
        return {
            ...initial_table_state,
            results: state.survey.surveyDetails.assignees,
        };
    });
    const { addToast } = UseAppToast();
    const reduxDispatch = useDispatch();
    const reduxState = useSelector((state) => state.survey);
    // Removing this function (saveAuditTemplate) as I think its not needed - Murtaza Eliyas 2024-07-29
    // const saveAuditTemplate = () => {
    //     const formData = new FormData();
    //     formData.append("name", reduxState.surveyDetails.name);
    //     formData.append("short_name", reduxState.surveyDetails.short_name);
    //     formData.append(
    //         "json_structure",
    //         JSON.stringify({
    //             assignees: reduxState.surveyDetails.assignees,
    //             sections: reduxState.sections,
    //         })
    //     );
    //     formData.append("sign_label", reduxState.surveyDetails.sign_label);
    //     formData.append("sign_required", reduxState.surveyDetails.sign_required);
    //     formData.append("show_sign", reduxState.surveyDetails.show_sign);
    //     formData.append("show_incharge_sign", reduxState.surveyDetails.show_incharge_sign);
    //     formData.append("incharge_sign_required", reduxState.surveyDetails.incharge_sign_required);
    //     formData.append("incharge_sign_label", reduxState.surveyDetails.incharge_sign_label);
    //     formData.append("image", reduxState.surveyDetails.image);
    //     formData.append("department_id", 1);
    //     if (prop.id) {
    //         formData.append("id", prop.id);
    //     }
    //     dispatch({
    //         payload: [
    //             {
    //                 type: UPDATE_PROP,
    //                 prop: "saveLoading",
    //                 value: true,
    //             },
    //         ],
    //     });
    //     updateOrCreateAuditTemplates(
    //         (res) => {
    //             addToast({
    //                 type: "success",
    //                 description: `${prop.id ? "Update" : "Add"} Template Successfully`,
    //                 title: "Success",
    //             });

    //             reduxDispatch({
    //                 type: UPDATE_PROP,
    //                 prop: "survey",
    //                 value: (v) => {
    //                     return {
    //                         ...v,
    //                         surveyDetails: {
    //                             ...initalSurvey.survey.surveyDetails,
    //                         },
    //                         sections: [],
    //                         selectedQuestion: null,
    //                     };
    //                 },
    //             });
    //             navigate("/forms-checklists");
    //             console.log(res);
    //         },
    //         (error) => {
    //             addToast({
    //                 type: ERROR,
    //                 description: error.message,
    //                 title: "Error",
    //             });
    //         },

    //         (f) => {
    //             dispatch({
    //                 payload: [
    //                     {
    //                         type: UPDATE_PROP,
    //                         prop: "saveLoading",
    //                         value: false,
    //                     },
    //                 ],
    //             });
    //         },
    //         { formData }
    //     );
    // };

    return {
        actions: {
            onchange,
            // saveAuditTemplate,
        },
        reduxState,
        navigate,
    };
};

export default Logic;
