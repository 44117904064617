import { $axios, $securedAxios } from "../axios";
export const fetchRoles = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/role/get?page=${payload.page}`, {
      filters: payload.filters,
    })
    .then((response) => {
      onSuccess({ ...response.data.response });

      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const fetchSpecificRole = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .get(`/role/get/${payload.id}`)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const fetchAllRoles = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .get(`/role/get-all`)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const deleteRole = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/role/delete/${payload.id}`)
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
export const updateOrCreateRole = (onSuccess, onError, onFinally, payload) => {
  $securedAxios
    .post(`/role/store`, {
      ...payload,
    })
    .then((response) => {
      onSuccess(response);
      // console.log(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
