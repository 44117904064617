export const defaultSelectStyles = {
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: state.isSelected && "lightgrey",
            color: "black",
        };
    },
    control: () => ({
        display: "flex",
        alignItems: "center",
        height: "100%",
        overflow: "hidden",
        flex: 1,
    }),
    container: (provided, state) => {
        return { ...provided, display: "flex", flex: 1, height: "100%" };
    },
    placeholder: () => ({
        display: "none",
    }),
    dropdownIndicator: (provided, state) => {
        return { ...provided, color: "black", fontSize: 14 };
    },
    indicatorSeparator: () => ({
        display: "none",
    }),
    singleValue: (provided, state) => {
        return {
            ...provided,
            justifyContent: "center",
            display: "flex",
            fontWeight: 900,
            color: "black",
        };
    },
    // menuPortal: (provided) => ({
    //   ...provided,
    //   zIndex: 9999,
    // }),
};
