import styles from "./ScoreCard.module.css";
import AppButton from "../../../../components/general/app-button/AppButton";
import LoadingSpinner from "../../../../components/general/loading-spinner/LoadingSpinner";

const ScoreCard = ({
    text,
    count,
    className = "defaultButton",
    loading = false,
    onClick,
    containerClassname,
    highlighted = false,
}) => {
    const allstyles = `${styles["defaultButton"]} ${styles[className]} ${
        containerClassname != undefined ? containerClassname : ""
    }`;
    return (
        <div className={containerClassname}>
            <AppButton
                className={`${className}`}
                onClick={onClick}
                value={
                    <>
                        {loading ? (
                            <LoadingSpinner isLoading={loading} />
                        ) : (
                            <p className={` ${styles.count}`}>{count}</p>
                        )}
                        <p className={`${styles[`${className}_text`]} ${styles.text}`}>{text}</p>
                    </>
                }
                style={{
                    width: 100,
                    height: 100,
                    flexDirection: "column",
                    backgroundColor: highlighted ? "var(--primary)" : "",
                    color: highlighted ? "white" : "black",
                    padding: "2px",
                }}
            />
        </div>
    );
};
export default ScoreCard;
