import { faPaperclip, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import AppButton from "../../../../../../components/general/app-button/AppButton";
import AppInput from "../../../../../../components/general/app-input/AppInput";
import AttachmentIcon from "../AttachmentIcon/AttachmentIcon";
import CustomMessage from "../CustomMessage/CustomMessage";
import styles from "./Chat.module.css";

const Chat = ({ actionPlanMessages, submitMessage, actionPlanId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const formData = new FormData();

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [actionPlanMessages]);

  const removeAttachment = (indexToRemove) => {
    setAttachments(attachments.filter((_, index) => index !== indexToRemove));
  };

  const handleSendMessage = () => {
    if (inputValue.trim() || attachments.length > 0) {
      setIsLoading(true);

      console.log(attachments);

      formData.append("checklist_actions_id", actionPlanId);
      formData.append("message", inputValue);

      attachments.forEach((file) => {
        formData.append("attachments[]", file); // Directly append the file
      });

      console.log("FormData Contents:");
      for (const pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      submitMessage({ formData, setInputValue, setAttachments, setIsLoading });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments((prev) => [...prev, ...files]); // Store files directly
  };

  const handleAttachClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteAttachment = (id) => {
    setAttachments(attachments.filter((attachment) => attachment.id !== id));
  };

  return (
    <div className={styles.chatBox}>
      <div className={styles.messagesContainer}>
        {actionPlanMessages?.map((msg) => (
          <div key={msg.id} className={styles.messageContainer}>
            <CustomMessage
              senderName={msg.created_by?.name}
              message={msg.message}
              timestamp={new Date(msg.created_at).toLocaleTimeString()}
              attachments={
                Array.isArray(msg.attachments)
                  ? msg.attachments.map((attachment, index) => ({
                      id: `${msg.id}-${index}`,
                      url: attachment,
                    }))
                  : []
              }
            />
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div>
        {attachments.length > 0 && (
          <div className={styles.attachmentsPreview}>
            {attachments.map((attachment, index) => (
              <div key={index} className={styles.attachmentPreview}>
                <AttachmentIcon
                  onDelete={() => {
                    removeAttachment(index);
                  }}
                  attachment={attachment}
                />
              </div>
            ))}
          </div>
        )}
        <div className={styles.inputContainer}>
          <AppInput
            type="text"
            className={styles.input}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type a message..."
          />
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faSpinner}
                style={{
                  color: "var(--primary)",
                  fontSize: "45px",
                }}
                spin
              />
            </div>
          ) : (
            <>
              <AppButton
                className="primaryButton"
                style={
                  isLoading
                    ? {
                        marginLeft: "10px",
                        backgroundColor: "#d1c9c9",
                        color: "#000",
                        border: "0px",
                      }
                    : { marginLeft: "10px" }
                }
                onClick={handleSendMessage}
                value="Send"
              />
              <input
                type="file"
                accept=".doc,.docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <AppButton
                className="attachmentButton"
                style={
                  !isLoading
                    ? {
                        marginLeft: "10px",
                        border: "0px",
                        cursor: "pointer",
                        fontSize: "16px",
                        padding: "6px 10px",
                        borderRadius: "4px",
                        color: "#fff",
                        backgroundColor: "#663bc9",
                      }
                    : { marginLeft: "10px" }
                }
                onClick={handleAttachClick}
                value={<FontAwesomeIcon icon={faPaperclip} />}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
