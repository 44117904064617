import AppButton from "../app-button/AppButton";
import Table from "../app-table/AppTable";
import styles from "./TableLayout.module.css";
import AppFilters from "../app-filters/AppFilters";
import Card from "../card/Card";
import Title from "../title/Title";

const TableLayout = ({
  tableColumns,
  title,
  showButton = false,
  buttonValue = "",
  buttonIcon = "",
  buttonOnClick,
  buttonClassName,
  buttonStyle,
  data,
  setData,
  error,
  filterData,
  filterKeys,
  showFilter = false,
  showCheckbox,
}) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <Title
            title={title}
            renderProp={
              showFilter && (
                <AppFilters
                  filterData={filterData.filterData}
                  filterKeys={filterKeys.filterKeys}
                  setData={setData}
                  filters={data.params.filters}
                />
              )
            }
          >
            {showButton && (
              <>
                
                  <AppButton
                    className={buttonClassName}
                    style={buttonStyle}
                    onClick={buttonOnClick}
                    value={buttonValue}
                    icon={buttonIcon}
                  />
                
              </>
            )}
          </Title>
        </div>

        <Card>
          <Table
            columns={tableColumns}
            data={data}
            setData={setData}
            error={error}
            showCheckbox={showCheckbox}
          />
        </Card>
      </div>
      {/* <div className={styles.titleContainer}>
        <p className={styles.title}>{title}</p>
        {showButton && (
          <AppButton
            value={buttonValue}
            onClick={buttonOnClick}
            className={buttonClassName}
            style={buttonStyle}
          />
        )}

        {showFilter && (
          <AppFilters
            filterData={filterData.filterData}
            filterKeys={filterData.filterKeys}
            setData={setData}
            filters={data.params.filters}
          />
        )}
      </div>
      <Table
        columns={tableColumns}
        data={data}
        setData={setData}
        error={error}
      /> */}
    </>
  );
};

export default TableLayout;
