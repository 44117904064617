import Switch from "react-switch";
import { Constants } from "./Constants";
import Styles from "./SwitchButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
const SwitchButton = ({ onChange, label, className, ...rest }) => {
    return (
        <div id={Styles.switch} className={className}>
            {label && <span>{label}</span>}
            <Switch
                onChange={onChange}
                onColor={`${Constants.onColor}`}
                onHandleColor={`${Constants.onHandleColor}`}
                handleDiameter={Constants.handleDiameter}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow={Constants.boxShadow}
                activeBoxShadow={Constants.activeBoxShadow}
                height={Constants.height}
                width={Constants.width}
                className="react-switch"
                id="material-switch"
                {...rest}
            />
        </div>
    );
};

export default SwitchButton;
