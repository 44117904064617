import AppButton from "../../../../../../../../components/general/app-button/AppButton";
import Styles from "./AddAssigneeModal.module.css";
import AppModal from "../../../../../../../../components/general/app-modal/AppModal";
import Label from "../../../../../../../../components/general/label/Label";
import AppInput from "../../../../../../../../components/general/app-input/AppInput";
import SwitchButton from "../../../../../../../../components/general/switch-button/SwitchButton";
import Title from "../../../../../../../../components/general/title/Title";
import globalLogic from "../../../../globalLogic";
import { useEffect } from "react";
import useLogic from "../../../../../../../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";
import AppSelect from "../../../../../../../../components/general/app-select/AppSelect";
import { filterSelectStyles } from "./Constants";
import useValidator from "../../../../../../../../helpers/hooks/use-validator";
import ValidationProvider from "../../../../../../../../components/general/validation-provider/ValidationProvider";
import { useTranslation } from "react-i18next";

const AddAssigneeModal = ({ show, set, assigneeValue, setAssigneeValue, updateAssignees }) => {
    const { state, updateState, addAssignee } = useLogic({
        INITIAL_STATE,
        Logic,
        prop: { assigneeValue, updateAssignees },
    });
    const { t } = useTranslation();

    const { errors, onBlur, onChange, handleSubmit } = useValidator({
        defaultValues: {
            ...state.assignee,
        },
        setValue: (nextFormState) => {
            updateState({
                prop: "assignee",
                value: nextFormState,
            });
        },
        onSubmit: () => {
            addAssignee();
            set();
            setAssigneeValue();
        },
        validations: {
            name: {
                required: true,
                validation_name: "Name",
            },
            email: { required: true, validation_name: "Email", isEmail: true },
            // department: {
            //     required: true,
            //     validation_name: "Department",
            // },
        },
    });
    return (
        <AppModal
            show={show}
            onHide={() => {
                set();
                setAssigneeValue();
            }}
            className="modelContent"
            bodyStyle={{ paddingTop: 0 }}
            footerStyle={{ border: 0, paddingTop: 0 }}
            headerStyle={{ border: 0, paddingBottom: 0 }}
        >
            <div className={Styles.sectionContainer}>
                <Title title={t("addAssignee")} border={true} />
                <div className={Styles.groupInputs}>
                    <div className={Styles.groupControl}>
                        <ValidationProvider
                            error={errors.name}
                            render={({ requiredTitle }) => (
                                <Label name={requiredTitle(t("name"))} className={Styles.label}>
                                    <AppInput
                                        type="text"
                                        className={Styles.input}
                                        onChange={(e) => onChange("name", e.target.value)}
                                        onBlur={(e) => onBlur("name")}
                                        value={state.assignee.name}
                                    />
                                </Label>
                            )}
                        />
                    </div>
                    <div className={Styles.groupControl}>
                        <ValidationProvider
                            error={errors.email}
                            render={({ requiredTitle }) => (
                                <Label name={requiredTitle(t("email"))} className={Styles.label}>
                                    <AppInput
                                        type="text"
                                        className={Styles.input}
                                        onChange={(e) => onChange("email", e.target.value)}
                                        onBlur={(e) => onBlur("email")}
                                        value={state.assignee.email}
                                    />
                                </Label>
                            )}
                        />
                    </div>
                    <div className={Styles.groupControl}>
                        {/* <ValidationProvider
                            error={errors.department}
                            render={() => ( */}
                        <Label name={t("department")} className={Styles.label}>
                            <AppSelect
                                options={state.departments.map((element) => ({
                                    value: element.id,
                                    label: element.name,
                                }))}
                                value={state.assignee.department}
                                styles={filterSelectStyles}
                                onChange={(e) => {
                                    console.log(e);
                                    onChange("department", e);
                                }}
                                onBlur={(e) => onBlur("department")}
                            />
                        </Label>
                        {/* )}
                        /> */}
                    </div>
                </div>
                {/* !!!!!!!!!!!!!!  TEMPORARY !!!!!!!!!!!!!!!!!! */}
                {/* <div className={Styles.switchControl}>
                    <SwitchButton
                        checked={state.assignee.approval_req}
                        onChange={() => {
                            updateState({
                                prop: `assignee.approval_req`,
                                value: !state.assignee.approval_req,
                            });
                        }}
                        label={t("approvalRequired")}
                        className={Styles.switchButtonLabel}
                    />
                    <SwitchButton
                        checked={state.assignee.default_assign}
                        onChange={() => {
                            updateState({
                                prop: `assignee.default_assign`,
                                value: !state.assignee.default_assign,
                            });
                        }}
                        label={`${t("default")} ${t("assignee")}`}
                        className={Styles.switchButtonLabel}
                    />
                </div> */}
                <div className={Styles.actionButton}>
                    <AppButton
                        className="primaryButton"
                        onClick={handleSubmit}
                        value={t("save")}
                        style={{ marginRight: "10px" }}
                    />
                    <AppButton
                        className="whiteButton"
                        onClick={() => {
                            set();
                            setAssigneeValue();
                        }}
                        value={t("cancel")}
                        style={{ marginRight: "10px" }}
                    />
                </div>
            </div>
        </AppModal>
    );
};

export default AddAssigneeModal;
