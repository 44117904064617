import { $securedAxios } from "../axios";
export const fetchPermissions = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/permission/get?page=${payload.page}`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data.response });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const fetchSpecificPermission = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .get(`/permission/get/${payload.id}`)
        .then((response) => {
            onSuccess(response);
            // console.log(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const updateOrCreatePermission = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/permission/store`, {
            ...payload,
        })
        .then((response) => {
            onSuccess(response);
            // console.log(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const deletePermission = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/permission/delete/${payload.id}`)
        .then((response) => {
            onSuccess(response);
            // console.log(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const getAllPermissions = (onSuccess, onError, onFinally) => {
    $securedAxios
        .get(`/permission/get-all`)
        .then((response) => {
            onSuccess(response);
            // console.log(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

/**
 * The previous endpoint doesn't handle page size, so this needs to be used
 */
export const fetchAllPermissions = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/permission/get`, payload)
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
