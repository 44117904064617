import styles from "./NumberWithLabel.module.css";

const NumberWithLabel = ({
  children,
  containerStyle,
  numberClassname,
  labelClassname,
  number,
  label,
  textConatinerClassname,
  contentClassname,
  ...props
}) => {
  const { className, ...rest } = props;
  const allstyles = `${styles.container} ${
    className != undefined ? className : ""
  }`;
  const textstyles = `${styles.textConatiner} ${
    textConatinerClassname != undefined ? textConatinerClassname : ""
  }`;
  return (
    <div className={allstyles} style={containerStyle}>
      <div className={textstyles}>
        {number && <span className={numberClassname}>{number}</span>}
        {label && <span className={labelClassname}>{label}</span>}
      </div>
      <div className={contentClassname}>{children}</div>
    </div>
  );
};
export default NumberWithLabel;
