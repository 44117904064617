import { $axios, $securedAxios } from "../axios";
const addPageNPageSizeToURL = (url, pageSize, page) => {
  if (pageSize) {
    url = url + `?pageSize=${pageSize}`;
  }
  if (page) {
    url = url.includes("?") ? url + `&page=${page}` : url + `?page=${page}`;
  }
  return url;
};

export const fetchActionPlans = (payload, onSuccess, onError, onFinally) => {
  let url = `/checklist-action/get`;
  url = addPageNPageSizeToURL(url, payload.pageSize, payload.page);
  $securedAxios
    .post(url, {
      filters: payload.filters,
      id: payload.id,
    })
    .then((response) => {
      onSuccess({ ...response.data });
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const updateActionPlans = (onSuccess, onError, onFinally, payload) => {
  let url = `/checklist-action/update`;
  // url = addPageNPageSizeToURL(url, payload.pageSize, payload.page);
  $securedAxios
    .post(url, {
      ...payload.param,
    })
    .then((response) => {
      onSuccess({ ...response.data });
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const fetchActionPlanProgress = (
  payload,
  onSuccess,
  onError,
  onFinally
) => {
  let url = `/checklist-action-progress/get/specific`;
  url = addPageNPageSizeToURL(url, payload.pageSize, payload.page);
  $securedAxios
    .post(url, {
      filters: payload.filters,
      id: payload.id,
    })
    .then((response) => {
      onSuccess({ ...response.data });
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const submitActionPlanProgress = (
  onSuccess,
  onError,
  onFinally,
  payload
) => {
  let url = `/checklist-action-progress/store`;
  // url = addPageNPageSizeToURL(url, payload.pageSize, payload.page);
//   alert(JSON.stringify(payload));
  $securedAxios
    .post(url, payload)
    .then((response) => {
      onSuccess({ ...response.data });
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const fetchMessages = (payload, onSuccess, onError, onFinally) => {
  let url = `/checklist-action-comments/get`;
  url = addPageNPageSizeToURL(url, payload.pageSize, payload.page);
  $securedAxios
    .post(url, {
      filters: payload.filters,
      sort_column: payload.sort_column,
      sort_by: payload.sort_by,
    })
    .then((response) => {
      onSuccess({ ...response.data });
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};

export const addMessages = (payload, onSuccess, onError, onFinally) => {
  let url = `/checklist-action-comments/store`;
  url = addPageNPageSizeToURL(url, payload.pageSize, payload.page);
  $securedAxios
    .post(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      // message: payload.message,
      // attachments: payload.attachments,
      // checklist_actions_id: payload.checklist_actions_id,
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
    .finally(() => {
      onFinally();
    });
};
