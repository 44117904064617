import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { getUserFromLS } from "../../helpers/Storage";
import Classes from "./Header.module.css";

export const LeftNavLinks = [];
export const CenterNavLinks = () => {
  let user = getUserFromLS();
  if (!user.is_admin) {
    return [];
  }
  return [
    // {
    //     name: "Current Subscriptions",
    //     to: "/admin/current-subscriptions",
    // },
  ];
};
export const RightNavLinks = (color = "black") => {
  // const user = useSelector((state) => state.user);
  const user = getUserFromLS();

  const { t } = useTranslation();
  return [
    {
      to: "/base",
      align: "end",
      items: [
        {
          name: "Switch Company",
          to: "/switch-company",
        },
        {
          name: "Logout",
          to: "/logout",
        },
      ],
      component: (
        <div className={Classes.userIcon}>
          <h5
            style={{
              color: color,
            }}
          >
            {t("welcome")}, {user?.name}
          </h5>
          <FontAwesomeIcon icon={faUserCircle} className={Classes.icon} />
        </div>
      ),
    },
  ];
};
