import React, { useEffect } from "react";
import Routes from "./routes";
import "./assets/styles/index.css";
import { LANGUAGE } from "./helpers/constants/StaticKeys";
import { toggleLanguage } from "./locale/i18n";
import ToastPortal from "./components/general/app-toast/ToastPortal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

// simple way to check whether the device support touch (it doesn't check all fallback, it supports only modern browsers)
const isTouchDevice = () => {
    if ("ontouchstart" in window) {
        return true;
    }
    return false;
};
const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

function App() {
    useEffect(() => {
        let lang = localStorage.getItem(LANGUAGE);
        if (lang != null) {
            toggleLanguage(lang);
        }
    }, []);

    return (
        <DndProvider backend={backendForDND}>
            <Routes />
            <ToastPortal autoClose={true} autoCloseTime={10000} />
        </DndProvider>
    );
}

export default App;

// import React from "react";
// import Homepage from "./pages/master-data/form-builder/components/structure/Homepage";
// import { DndProvider } from "react-dnd";
// // import { HTML5Backend } from "react-dnd-html5-backend";
// // import Structure from "./pages/master-data/form-builder/components/structure/Structure";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import { TouchBackend } from "react-dnd-touch-backend";
// import ListItempage from "./pages/master-data/form-builder/components/structure/ListItempage";

// // simple way to check whether the device support touch (it doesn't check all fallback, it supports only modern browsers)
// const isTouchDevice = () => {
//   if ("ontouchstart" in window) {
//     return true;
//   }
//   return false;
// };

// // Assigning backend based on touch support on the device
// const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;
// const App = () => {
//   return (
//     <DndProvider backend={backendForDND}>
//       <div className={"row"}>
//         <p className={"page-header"}>Trello</p>
//       </div>
//       <Homepage />
//       {/* <ListItempage /> */}
//     </DndProvider>
//   );
// };

// export default App;
