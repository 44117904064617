import classes from "./Card.module.css";
import React from "react";
const Card = React.forwardRef(({ children, ...props }, ref) => {
  const { className, ...rest } = props;
  const allstyles = `${classes.card} ${
    className != undefined ? className : ""
  }`;
  return (
    <div className={allstyles} {...rest} ref={ref}>
      {children}
    </div>
  );
});

export default Card;
