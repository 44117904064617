import DisplayFilters from "../../../../components/general/app-filters/display-filters/DisplayFilters";
import Card from "../../../../components/general/card/Card";
import Label from "../../../../components/general/label/Label";
import SimpleTable from "../../../../components/general/simple-table/SimpleTable";
import Spacer from "../../../../components/general/spacer/Spacer";
import useLogic from "../../../../helpers/hooks/use-logic";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
import Layout from "../../components/layout/Layout";
import ScoreCard from "../../components/score-card/ScoreCard";
import styles from "./ActionPlanNew.module.css";
import ActionPlanPopup from "./components/actionPlanPopup";
import CloseAPModal from "./components/closeAPModal";
import NewProgressModal from "./components/newProgressModal";
import { useTranslation } from "react-i18next";


import {
    INITIAL_STATE
} from "./constants";
import Logic from "./logic";

const ActionPlanN = () => {
    const { state, updateProps, actions, columns, progColumns, updateState} = useLogic({
        INITIAL_STATE,
        Logic,
    });
    const {
        setFilters,
        setSorting,
        getActionPlans,
        removeFilter,
        removeSort,
        submitNewProgress,
        getActionPlanProgress,
        closeActionPlan,
        submitMessage, 
        submitAttachments
    } = actions;
    // const [filters, setFilters] = useState([]);
    // const [sorting, setSorting] = useState([]);
  const { t } = useTranslation();

    return (
        <>
            <Layout>
                <Layout.Body style={{ width: "100%" }}>
                    <div className={styles.content}>
                        <Card className={styles.filterButtons}>
                            <Label
                                name={t("general")}
                                className={styles.label}
                                classText={styles.labelText}
                                style={{
                                    minWidth: "10%",
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "auto",
                                }}
                            >
                                <ScoreCard
                                    text={t("total")}
                                    count={71}
                                    className="whiteButton"
                                    highlighted
                                />
                                <Spacer width={7} />
                                <ScoreCard text={t("open")} count={10} className="whiteButton" />
                                <Spacer width={7} />
                                <ScoreCard text={t("overdue")} count={3} className="whiteButton" />
                                <Spacer width={7} />
                                <ScoreCard text={t("closed")} count={58} className="whiteButton" />
                            </Label>
                            <Spacer width={7} />
                            <Label
                                name={`${t("assigned")} ${t("to")} ${t("me")}`}
                                className={styles.label}
                                classText={styles.labelText}
                                style={{
                                    minWidth: "10%",
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "auto",
                                }}
                            >
                                <ScoreCard
                                    text={t("total")}
                                    count={58}
                                    className="whiteButton"
                                    highlighted
                                />
                                <Spacer width={7} />
                                <ScoreCard text={t("open")} count={10} className="whiteButton" />
                                <Spacer width={7} />
                                <ScoreCard text={t("overdue")} count={3} className="whiteButton" />
                            </Label>
                            <Spacer width={7} />
                            <Label
                                name={`${t("involved")} ${t("in")}`}
                                className={styles.label}
                                classText={styles.labelText}
                                style={{
                                    minWidth: "10%",
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "auto",
                                }}
                            >
                                <ScoreCard
                                    text={t("total")}
                                    count={58}
                                    className="whiteButton"
                                    highlighted
                                />
                                <Spacer width={7} />
                                <ScoreCard text={t("open")} count={10} className="whiteButton" />
                                <Spacer width={7} />
                                <ScoreCard text={t("overdue")} count={3} className="whiteButton" />
                            </Label>
                            <Spacer width={7} />
                        </Card>
                        <Spacer height={10} />
                        <Card>
                            {/* <AppButton
                                value="Open modal"
                                onClick={() => {
                                    updateState({ prop: "isAPPopupOpen", value: true });
                                }}
                            /> */}
                            <div style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
                                <DisplayFilters
                                    filters={state.filters}
                                    signLabelAccessor="operator"
                                    handleDelete={removeFilter}
                                />
                                <Spacer width={5} />
                                <DisplayFilters
                                    filters={state.sorting}
                                    signLabelAccessor="order"
                                    handleDelete={removeSort}
                                />
                            </div>
                            <div style={{ display: "flex", marginTop: 5, marginBottom: 5 }}></div>

                            <SimpleTable
                                data={state.actionPlans.data ? state.actionPlans.data : []}
                                columns={columns}
                                total={state.actionPlans?.total}
                                getData={getActionPlans}
                                customFilters={state.filters}
                                setCustomFilters={setFilters}
                                customSorting={state.sorting}
                                setCustomSorting={setSorting}
                                loading={state.loading.actionPlansLoading}
                                pageSizeOptions={[30]}
                                showPageSizeOptions={false}
                                showGoToPage={false}
                                buttonStyle={{
                                    // backgroundColor: "var(--darkGreen)",
                                    color: "white",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    border: "none",
                                    cursor: "pointer",
                                    // marginRight: "10px",
                                    transitionDuration: "0.2s",
                                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                                }}
                            />
                        </Card>
                    </div>
                </Layout.Body>
            </Layout>
            <ActionPlanPopup
                show={state.isAPPopupOpen}
                onHide={() => {
                    // updateState({ prop: "isAPPopupOpen", value: false });
                    updateProps([
                        { type: UPDATE_PROP, prop: "isAPPopupOpen", value: false },
                        { type: UPDATE_PROP, prop: "selectedAP", value: {} },
                        { type: UPDATE_PROP, prop: "progressData", value: [] },
                        { type: UPDATE_PROP, prop: "actionPlanMessages", value: [] },
                    ]);
                }}
                data={state.selectedAP}
                columns={progColumns}
                progData={state.progressData}
                actionPlanMessages={state.actionPlanMessages}
                submitMessage={submitMessage}
                submitAttachments={submitAttachments}
                addMessages={(value)=>{updateState("addMessages",value)}}
                progDataLoading={state.loading.actionPlanProgLoading}
                getActionPlanProgress={getActionPlanProgress}
                closeActionPlan={closeActionPlan}
            />
            <NewProgressModal
                show={state.isNewProgOpen}
                onHide={() => {
                    updateProps([
                        { type: UPDATE_PROP, prop: "isNewProgOpen", value: false },
                        { type: UPDATE_PROP, prop: "forwardingAPProg", value: {} },
                        {
                            type: UPDATE_PROP,
                            prop: "newAPProg",
                            value: { assignee_id: {}, description: "" },
                        },
                    ]);
                }}
                onSave={submitNewProgress}
                data={state.newAPProg}
                allUsers={state.allUsers}
                updateProps={updateProps}
                loading={state.loading.newAPProgLoading}
            />
            <CloseAPModal
                show={state.isConfirmOpen}
                onHide={() => {
                    updateProps([
                        { type: UPDATE_PROP, prop: "isConfirmOpen", value: false },
                        {
                            type: UPDATE_PROP,
                            prop: "closingRemarks",
                            value: "",
                        },
                    ]);
                }}
                onSave={closeActionPlan}
                closingRemarks={state.closingRemarks}
                updateProps={updateProps}
                loading={state.loading.closeAPLoading}
            />
        </>
    );
};
export default ActionPlanN;
