import React, { useMemo, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TableLayout from "../../../../components/general/table-layout/TableLayout";
import Styles from "./Locations.module.css";
import useLogic from "../../../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import ConfirmModal from "../../../../components/general/confirm-modal/ConfrimModal";
import { useTranslation } from "react-i18next";

const Locations = () => {
    const { t } = useTranslation();
    const filterData = [
        {
            key: "locations.name",
            label: t("name"),
            sign: [{ value: "contains", label: t("contains") }],
        },
        {
            key: "address",
            label: t("address"),
            sign: [{ value: "contains", label: t("contains") }],
        },
        ,
        {
            key: "areas.name",
            label: t("area"),
            sign: [{ value: "contains", label: t("contains") }],
        },
        // ... other filters
    ];

    const filterKeys = {
        "locations.name": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("contains") },
                { value: "is_equal", label: t("equals") },
                // ... other signs
            ],
        },
        address: {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("contains") },
                { value: "is_equal", label: t("equals") },
                // ... other signs
            ],
        },
        "areas.name": {
            type: "string",
            dropdown: false,
            sign: [
                { value: "contains", label: t("contains") },
                { value: "is_equal", label: t("equals") },
                // ... other signs
            ],
        },
    };
    const {
        state,
        setData,
        navigate,
        columns,
        data,
        error,
        showDeleteAlert,
        setShowDeleteAlert,
        HandleCloseDelete,
        HandelDelete,
    } = useLogic({
        INITIAL_STATE,
        Logic,
    });

    return (
        <>
            <TableLayout
                tableColumns={columns}
                setData={setData}
                title={`${t("locations")} (${data.total})`}
                showButton={true}
                data={data}
                error={error}
                buttonValue={
                    data.selectedRows.length !== 0
                        ? `${t("deleteSelected")} (${data.selectedRows.length})`
                        : t("new")
                }
                buttonIcon={
                    data.selectedRows.length !== 0 ? (
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            style={{ marginRight: "5px", marginLeft: "5px" }}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faPlus}
                            style={{ marginRight: "5px", marginLeft: "5px" }}
                        />
                    )
                }
                buttonOnClick={() =>
                    data.selectedRows.length !== 0
                        ? setShowDeleteAlert(true)
                        : navigate("/portal/add-location")
                }
                buttonStyle={{ color: "white" }}
                buttonClassName="primaryButton"
                showCheckbox
                showFilter={true}
                filterData={{ filterData }}
                filterKeys={{ filterKeys }}
            />
            {showDeleteAlert && (
                <ConfirmModal
                    show={showDeleteAlert}
                    onHide={() => HandleCloseDelete()}
                    onSave={() => HandelDelete()}
                    isLoading={state.deleteLoading}
                />
            )}
        </>
    );
};
export default Locations;
