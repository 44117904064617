import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// import {
//   fetchSpecificDepartment,
//   updateOrCreateDepartment,
// } from "../../../../api/services/DepartmentServices";
// import {
//   ERROR,
//   SUCCESS,
// } from "../../../../components/general/app-toast/AppToast";
// import UseAppToast from "../../../../helpers/hooks/use-app-toast";
// import { UPDATE_PROP } from "../../../../store/ActionTypes";
import { useParams } from "react-router";
import {
    changePassword,
    fetchAllUsers,
    fetchSpecificUser,
    updateOrCreateUser,
} from "../../../api/services/UserServices";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../store/ActionTypes";
import { ERROR, SUCCESS } from "../../../components/general/app-toast/AppToast";
import { fetchSpecificAudit } from "../../../api/services/AuditServices";
import { fetchAllRoles } from "../../../api/services/RoleServices";
import { extractErrorMessage } from "../../../helpers/Common";
// import UseAppToast from "../../../helpers/hooks/use-app-toast";

export const INITIAL_STATE = {
    form: {
        password: "",
        password_confirmation: "",
    },
    saveLoading: false,
    pageLoading: false,
    error: null,
};
const Logic = (dispatch, state) => {
    const navigate = useNavigate();
    const { addToast } = UseAppToast();
    const { id } = useParams();
    const changepassword = () => {
        console.log("update");
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `saveLoading`,
                    value: true,
                },
            ],
        });
        let payload = {
            ...state.form,
            id,
        };
        changePassword(
            (res) => {
                let message = "password change successfully";

                addToast({
                    type: SUCCESS,
                    description: message,
                    title: "Success",
                });
                navigate("/portal/users");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `saveLoading`,
                            value: false,
                        },
                    ],
                });
            },
            () => {},
            {
                ...payload,
            }
        );
    };

    return { actions: { changepassword, navigate }, id };
};

export default Logic;
