import React from "react";
import Modal from "react-bootstrap/Modal";
import Styles from "./AppModal.module.css";
import { Spinner } from "react-bootstrap";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";

const AppModal = ({
    children,
    headerTitle,
    header,
    headerStyle,
    footer,
    footerStyle,
    bodyStyle,
    closeBtn = false,
    size = "lg",
    fullscreen = false,
    isLoading = false,
    error,
    ...rest
}) => {
    return (
        <Modal
            size={size}
            fullscreen={fullscreen}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            {...rest}
        >
            <LoadingSpinner isLoading={isLoading} error={error}>
                <Modal.Header closeButton={closeBtn} style={headerStyle}>
                    {header}
                    {headerTitle && (
                        <Modal.Title id="contained-modal-title-vcenter">{headerTitle}</Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body style={bodyStyle}>{children}</Modal.Body>
                {footer && <Modal.Footer style={footerStyle}>{footer}</Modal.Footer>}
            </LoadingSpinner>
        </Modal>
    );
};

export default AppModal;
