import React, { useEffect } from "react";
import navigation from "./NavLinks";
import Classes from "./Sidebar.module.css";
import SidebarNav from "./SidebarNav";

import { ProSidebar, SidebarContent, SidebarHeader } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
// import "react-pro-sidebar/dist/css/styles.css";
import { useTranslation } from "react-i18next";
import { getUserFromLS } from "../../helpers/Storage";
import Logo from "../logo/Logo";

const Sidebar = ({ menuCollapse, setMenuCollapse, menuIconClick }) => {
  const { i18n } = useTranslation();
  // const user = useSelector((state) => state.user);
  const user = getUserFromLS();

  useEffect(() => {});
  return (
    <div id="sidebar" className="sidebarContainer">
    
      <ProSidebar
        collapsed={menuCollapse}
        className={Classes.sidebar}
        breakPoint="md"
        rtl={i18n.language == "ar" ? true : false}
      >
        <SidebarHeader>
          {menuCollapse ? (
            <div className={Classes.logo}>G</div>
          ) : (
            <Logo classes={Classes.logoSize} />
          )}
        </SidebarHeader>
        <SidebarContent>
          <SidebarNav
            items={
              // navigation
              navigation.filter((item) => {
                return item.feature
                  ? user.feature_permissions[item.feature]
                  : false;
              })
            }
            activeClass={Classes.activeLink}
          />
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
