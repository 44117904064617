import { useEffect, useState } from "react";
import { LANGUAGE } from "../../../helpers/constants/StaticKeys";
import { toggleLanguage } from "../../../locale/i18n";
import AppSelect from "../app-select/AppSelect";
import classes from "./LanguageToggler.module.css";

const LanguageToggler = ({ color = "white", login = false, ...rest }) => {
  const [lang, setLang] = useState("");
  useEffect(() => {
    let l = localStorage.getItem(LANGUAGE);
    setLang(l);
  }, [lang]);

  const languages = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "ar",
      label: "العربية",
    },
  ];
  let selectedLang = languages.filter((l) => {
    return l.value == lang;
  });
  return (
    <AppSelect
      options={languages}
      value={selectedLang}
      styles={
        login
          ? {
              menu: (provided, state) => ({
                ...provided,
                width: "max-content",
              }),
              singleValue: (provided, state) => ({
                ...provided,
                width: "max-content",
                color: "white", // Change font color to white when login is true
              }),
              dropdownIndicator: (provided, state) => ({
                ...provided,
                color: color,
              }),
              control: (provided, state) => ({
                ...provided,
                border: "none", // Border color to green
                borderRadius: "12px", // Border radius to 0
                backgroundColor: "#8080806F", // Background color to red
              }),
            }
          : {
              menu: (provided, state) => {
                return {
                  ...provided,
                  // backgroundColor: color,
                  width: "max-content",
                };
              },
              singleValue: (provided, state) => {
                return {
                  ...provided,
                  color: color,
                  width: "max-content",
                };
              },
              dropdownIndicator: (provided, state) => {
                return { ...provided, color: color };
              },
            }
      }
      onChange={(e) => {
        toggleLanguage(e.value);
        setLang(e.value);
      }}
    />
  );
};

export default LanguageToggler;
