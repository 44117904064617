import AppButton from "../../components/general/app-button/AppButton";
import AppSelect from "../../components/general/app-select/AppSelect";
import NotFound from "../../components/general/not-found/NotFound";
import ValidationProvider from "../../components/general/validation-provider/ValidationProvider";
import Logo from "../../components/logo/Logo";
import useLogic from "../../helpers/hooks/use-logic";
import useValidator from "../../helpers/hooks/use-validator";
import { filterSelectStyles } from "./Constants";
import Logic, { INITIAL_STATE } from "./logic";
import styles from "./SwitchCompany-login.module.css";
const SwitchCompanyLogin = () => {
    const { state, updateState, loginHandler, location } = useLogic({
        INITIAL_STATE,
        Logic,
    });
    const { errors, onBlur, onChange, handleSubmit } = useValidator({
        defaultValues: {
            ...state.form,
        },
        setValue: (nextFormState) => {
            updateState({
                prop: "form",
                value: nextFormState,
            });
        },
        onSubmit: loginHandler,
        validations: {
            selectedCompany: { required: true, validation_name: "Company" },
        },
    });
    return (
        <>
            {location.state?.companies ? (
                <div className={styles.container}>
                    <div className={styles.subContainer}>
                        <div className={styles.header}>
                            <Logo classes={styles.logoSize} />
                            {/* <div className={styles.logo}>GAWDA</div> */}
                            <div className={styles.slogan}>Unleash Your Potential</div>
                        </div>

                        <div className={styles.loginForm}>
                            <div className={styles.label}>Select a company</div>
                            <div className={styles.groupControl}>
                                <ValidationProvider
                                    error={errors.selectedCompany}
                                    render={({ requiredTitle }) => (
                                        <div className={styles.inputContainer}>
                                            <AppSelect
                                                options={location.state.companies.map(
                                                    (element) => ({
                                                        value: element.id,
                                                        label: element.name,
                                                    })
                                                )}
                                                styles={filterSelectStyles}
                                                value={state.form.selectedCompany}
                                                onChange={(e) => onChange("selectedCompany", e)}
                                                onBlur={(e) => onBlur("selectedCompany")}
                                            />
                                        </div>
                                    )}
                                />
                            </div>

                            <AppButton
                                value="Switch Company"
                                className="rounded"
                                style={{
                                    backgroundColor: "var(--darkPurble)",
                                    color: "white",
                                    width: "auto",
                                    height: 50,
                                    fontSize: 22,
                                    marginBottom: 20,
                                }}
                                onClick={handleSubmit}
                                isLoading={state.loading}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <NotFound message="This is not a valid link" />
            )}
        </>
    );
};
export default SwitchCompanyLogin;
