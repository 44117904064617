import { useDispatch, useSelector } from "react-redux";
import { Await, useNavigate } from "react-router-dom";
import { login, loginCompanies } from "../../api/services/UserServices";
import { encrypted } from "../../helpers/Common";
import {
  AUTHENTICATION_TOKEN,
  FEATURES,
} from "../../helpers/constants/StaticKeys";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { loginAction } from "../../store/Actions";
import { UPDATE_PROP } from "../../store/ActionTypes";
import { useEffect } from "react";
import { getUserFromLS } from "../../helpers/Storage";
// Retrieve the email from localStorage
// const storedEmail = localStorage.getItem("admin") || "";

export const INITIAL_STATE = {
  form: {
    username: "",
    password: "",
  },
  name: "",
  loading: false,
};
const Logic = (dispatch, state) => {
  const navigate = useNavigate();
  const { addToast } = UseAppToast();
  const reduxDispatch = useDispatch();
  // const user = useSelector((state) => state.user);
  const user = getUserFromLS();

  const actualLogin = (company_name) => {
    // Login logic duplicate in switch-company-login Page
    login(
      async (res) => {
        // alert('sss');
        //I made it a string cuz its typeOf is object so I can't do featurePermission.includes
        var featurePermissions = String(res.data.response.feature_permissions);

        featurePermissions = featurePermissions.split(",");

        // alert(featurePermissions)

        /*
        1=>Portal
        2=>App
        3=>Analytics
        4=>Admin
        */
        if (
          featurePermissions.includes("1") ||
          featurePermissions.includes("3") ||
          featurePermissions.includes("4")
        ) {
          await Storage.setItem(
            AUTHENTICATION_TOKEN,
            res.data.response[0].access_token
          );
          localStorage.setItem(FEATURES, featurePermissions);
          addToast({
            type: "success",
            description: "Switch Company done successfully",
            title: "Success",
          });
          reduxDispatch(loginAction(res.data.response));

          // if 1 or 4 => portal or admin, navigate to /audits, if 3 => navigate to analytics
          if (
            featurePermissions.includes("1") ||
            featurePermissions.includes("4")
          ) {
            navigate("/portal/audits");
          } else if (featurePermissions.includes("3")) {
            navigate("/analytics/overview");
          }
        } else {
          localStorage.clear();
          addToast({
            type: "info",
            description: "You're not allowed to access this feature",
            title: "Not allowed ",
          });
          dispatch({
            payload: [
              {
                type: UPDATE_PROP,
                prop: `loading`,
                value: false,
              },
            ],
          });
        }
        // else {
        //   await Storage.setItem(
        //     AUTHENTICATION_TOKEN,
        //     res.data.response[0].access_token,

        //   );
        //   localStorage.setItem("features" ,featurePermissions );
        //   addToast({
        //     type: "success",
        //     description: "Login successfully",
        //     title: "Success",
        //   });
        //   reduxDispatch(loginAction(res.data.response));
        //   navigate("/")
        // }
        // return alert(JSON.stringify(res.data.response.feature_permissions));
      },
      (e) => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `loading`,
              value: false,
            },
          ],
        });

        addToast({
          type: "error",
          description: "username or password is incorrect",
          title: "Error",
        });
      },
      () => {},
      {
        username: state.form.username,
        password: state.form.password,
        company_name: company_name,
      }
    );
  };
  const loginHandler = () => {
    dispatch({
      payload: [
        {
          type: UPDATE_PROP,
          prop: `loading`,
          value: true,
        },
      ],
    });

    loginCompanies(
      (res) => {
        if (res.data.response.length > 1) {
          navigate("/switch-company-login", {
            state: {
              companies: res.data.response,
              username: state.form.username,
              password: state.form.password,
            },

            replace: false,
          });
        } else if (res.data.response.length == 1) {
          actualLogin(res.data.response[0].name);
        }
      },
      (e) => {
        dispatch({
          payload: [
            {
              type: UPDATE_PROP,
              prop: `loading`,
              value: false,
            },
          ],
        });

        addToast({
          type: "error",
          description: "username or password is incorrect",
          title: "Error",
        });
      },
      () => {},
      {
        username: state.form.username,
        password: encrypted(state.form.password),
      }
    );
  };

  useEffect(() => {
    // get user object from context
    if (user) {
      // add user info to state
      dispatch({
        payload: [
          {
            type: UPDATE_PROP,
            prop: `form.username`,
            value: user.email,
          },
          {
            type: UPDATE_PROP,
            prop: `name`,
            value: user.name,
          },
        ],
      });
    }
  });

  return { actions: { loginHandler } };
};

export default Logic;
