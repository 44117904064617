import { $axios, $securedAxios } from "../axios";
import { createCancelTokenHandler } from "../axios";

export const fetchChecklistActions = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(
            `/checklist-action/get?page=${payload.page}`,
            {
                filters: payload.filters,
            },
            {
                cancelToken:
                    cancelTokenHandlerObject[fetchChecklistActions.name].handleRequestCancellation()
                        .token,
            }
        )
        .then((response) => {
            onSuccess({ ...response.data.response });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const fetchChecklistActionsWithoutPages = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(
            `/checklist-action/get`,
            {
                filters: payload.filters,
            },
            {
                cancelToken:
                    cancelTokenHandlerObject[fetchChecklistActions.name].handleRequestCancellation()
                        .token,
            }
        )
        .then((response) => {
            onSuccess({ ...response.data.response });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const updateChecklistActions = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/checklist-action/update`, payload)
        .then((response) => {
            onSuccess({ ...response.data.response });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

// let source;
// export const fetchChecklistActions = (
//   onSuccess,
//   onError,
//   onFinally,
//   payload
// ) => {
//   // if (source) source.cancel("Operation canceled by the user.");
//   // // Assign the source factory to the source variable
//   // source = axios.CancelToken.source();
//   // securePost(
//   //   `/checklist-action/get?page=${payload.page}`,
//   //   {
//   //     filters: payload.filters,
//   //   },
//   //   onSuccess,
//   //   onError,
//   //   onFinally
//   // );

//   $securedAxios
//     .post(
//       `/checklist-action/get?page=${payload.page}`,
//       {
//         filters: payload.filters,
//       }
//       // ,
//       // { cancelToken: source.token }
//     )
//     .then((response) => {
//       onSuccess(response);
//       console.log(response);
//     })
//     .catch((error) => {
//       onError(error);
//       // if (axios.isCancel(error)) {
//       //   console.log("Request canceled", error.message);
//       // } else {
//       //   // handle errors
//       // }
//     })
//     .finally(() => {
//       onFinally();
//     });
// };

export const fetchChecklistCountByStatus = (onSuccess, onError, onFinally, payload) => {
    // securePost(
    //   `/checklist-action/count`,
    //   {
    //     filters: payload.filters,
    //     status: payload.status,
    //   },
    //   onSuccess,
    //   onError,
    //   onFinally
    // );
    $securedAxios
        .post(`/checklist-action/count`, {
            filters: payload.filters,
            status: payload.status,
        })
        .then((response) => {
            onSuccess(response);
            // console.log(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const auditsCount = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/audit/count`, { ...payload })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const locationsCount = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/location/count`, { ...payload })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const formsCount = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/audit-template/count`, { ...payload })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
export const usersCount = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/user/count`, { ...payload })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

/** Overview Pages - Doughnut Graph Status Count */
export const statusCount = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/analytics/overview/status`, { ...payload })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

/** Overview Pages - Bar Chart Audits Count */
export const auditsGroupedBy = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/analytics/overview/audits`, { ...payload })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

/** Performance Page - Timely Performance Bar Chart Audits Score */
export const auditsScoreTimely = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/analytics/performance/timely`, { ...payload })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const auditsScoreLocation = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/analytics/performance/location`, { ...payload })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const auditsScoreMetric = (onSuccess, onError, onFinally, payload) => {
    $securedAxios
        .post(`/analytics/performance/score/metric`, { ...payload })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const auditsSummary = (onSuccess, onError, onFinally, payload) => {
    // console.log("======payload==============================");
    // console.log(payload);
    // console.log("====================================");
    $securedAxios
        .post(`/analytics/summary`, { ...payload })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

// get locations
// creating the cancel token handler object
const cancelTokenHandlerObject = createCancelTokenHandler({
    fetchChecklistActions,
});

/*********************************  N E W   A P I S  ***********************************************/

export const fetchAuditsStats = (payload, onSuccess, onError, onFinally) => {
    $securedAxios
        .post(`/audit/status/overview`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const fetchActiveAuditors = (payload, onSuccess, onError, onFinally) => {
    $securedAxios
        .post(`/audit/active/auditor/overview`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const fetchActiveLocations = (payload, onSuccess, onError, onFinally) => {
    $securedAxios
        .post(`/audit/audited/location/overview`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const fetchActionPlanStats = (payload, onSuccess, onError, onFinally) => {
    $securedAxios
        .post(`/checklist-action/status/overview`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const fetchAuditorsActivity = (payload, onSuccess, onError, onFinally) => {
    $securedAxios
        .post(`/audit/auditor/overview`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const fetchAvgScoresByAudit = (payload, onSuccess, onError, onFinally) => {
    $securedAxios
        .post(`/audit/average`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const fetchAvgScoresByLocation = (payload, onSuccess, onError, onFinally) => {
    $securedAxios
        .post(`/audit/location/average`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const fetchHighScores = (payload, onSuccess, onError, onFinally) => {
    $securedAxios
        .post(`/audit/highest/score`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};

export const fetchLowScores = (payload, onSuccess, onError, onFinally) => {
    $securedAxios
        .post(`/audit/lowest/score`, {
            filters: payload.filters,
        })
        .then((response) => {
            onSuccess({ ...response.data });
        })
        .catch((error) => {
            onError(error);
        })
        .finally(() => {
            onFinally();
        });
};
