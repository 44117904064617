import React from "react";
import AppButton from "../../components/general/app-button/AppButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";

export const AuditColumn = (actions, downloadId, translate = (term) => term) => {
    return [
        {
            Header: `${translate("audit")}`,
            accessor: "template_name",
        },
        {
            Header: `${translate("date")}`,
            accessor: "date",
        },
        {
            Header: `${translate("department")}`,
            accessor: "department_name",
        },
        {
            Header: `${translate("location")}`,
            accessor: "location_name",
        },
        {
            Header: `${translate("auditor")}`,
            accessor: "username",
        },
        {
            Header: `${translate("status")}`,
            accessor: "status",
            Cell: ({ row: { original, values } }) => {
                const currentDate = new Date();
                const auditDate = new Date(original.date);
                let displayStatus = original.status ? "Completed" : "Pending";

                if (!original.status && auditDate < currentDate) {
                    displayStatus = "Missed";
                }

                return (
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        {displayStatus}
                    </div>
                );
            },
        },
        {
            Header: `${translate("actions")}`,
            style: {
                textAlign: "center",
            },
            Cell: ({ row: { values, original } }) => (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {original.status === 1 ? (
                        <>
                            <AppButton
                                className="buttonIcon"
                                style={{ color: "#663897" }}
                                disabled={downloadId === original.id}
                                onClick={() => actions.getPdf(original.id)}
                                value={downloadId !== original.id && "PDF"}
                                isLoading={downloadId === original.id}
                                icon={
                                    <FontAwesomeIcon
                                        icon={faFilePdf}
                                        style={{ margin: "0px 5px" }}
                                    />
                                }
                            />
                        </>
                    ) : (
                        <>
                            <AppButton
                                className="buttonIcon"
                                style={{ color: "grey", visibility: "hidden" }}
                                onClick={() => console.log(original)}
                                value="PDF"
                                icon={
                                    <FontAwesomeIcon
                                        icon={faFilePdf}
                                        style={{ marginRight: "5px" }}
                                    />
                                }
                                disabled={true}
                            />
                        </>
                    )}
                    <AppButton
                        className="buttonIcon"
                        style={{ color: "#fb3737" }}
                        onClick={() => actions.bulkDelete([original])}
                        value={`${translate("delete")}`}
                        icon={<FontAwesomeIcon icon={faTrashAlt} style={{ margin: "0px 5px" }} />}
                    />
                </div>
            ),
        },
    ];
};
