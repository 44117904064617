import { AUTHENTICATION_TOKEN } from "../../helpers/constants/StaticKeys";
import { setUserToLS } from "../../helpers/Storage";

export const postLoginLogic = async (
    res,

    navigate,
    addToast
) => {
    if (res.data.response.subscription.plan_features.PORTAL) {
        await Storage.setItem(AUTHENTICATION_TOKEN, res.data.response.access_token);

        // store the user object in local storage encrypted
        setUserToLS(res.data.response);
        navigate("/portal");
    } else {
        addToast({
            type: "info",
            description: "You're not allowed to access this feature",
            title: "Not allowed ",
        });
    }
};
