import React from "react";
import { Outlet } from "react-router-dom";
import HeaderCenter from "../../components/header/header-center/HeaderCenter";
import HeaderLeft from "../../components/header/header-left/HeaderLeft";
import { AppHeader } from "../../components/index";
import Classes from "./AnalyticsLayout.module.css";
import LanguageToggler from "../../components/general/language-toggler/LanguageToggler";
import HeaderRight from "../../components/header/header-right/HeaderRight";

const AdminLayout = () => {
  return (
    <div style={{ display: "flex", color: "black" }}>
      <div style={{ flex: 4, borderWidth: 1 }}>
        <AppHeader
          center={<HeaderCenter />}
          left={<HeaderLeft />}
          classes={Classes.bgHeader}
          right={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LanguageToggler color={"white"} />
              <HeaderRight color="white" />
            </div>
          }
        />
        <div className="body flex-grow-1 px-3">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
