import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    fetchAllAuditTemplates,
    fetchSpecificAudit,
    storeAudit,
    updateAudit,
} from "../../../api/services/AuditServices";
import { getAllDepartments } from "../../../api/services/DepartmentServices";
import { fetchAllLocations } from "../../../api/services/LocationServices";
import { fetchAllUsersSchedule } from "../../../api/services/UserServices";
import { ERROR, SUCCESS } from "../../../components/general/app-toast/AppToast";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../store/ActionTypes";
import { extractErrorMessage } from "../../../helpers/Common";

export const INITIAL_STATE = {
    departments: [],
    checklist: [],
    asignees: [],
    locations: [],
    weekDay: ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    form: {
        selectedDepartment: null,
        selectedChecklist: null,
        selectedAssignee: null,
        selectedLocation: null,
        selectedInput: null,
        selectedCustomFreqInput: null,
        start_date: "",
        end_date: "",
        selectedTimeframeInput: 1,
        selectedTimeframeNatureInput: 0,
        start_time: "",
        due_time: "",
        selectedWeekDay: null,
        due_on: "",
        custom_selected_dates: [],
    },

    saveLoading: false,
    pageLoading: false,
    error: null,
};

const Logic = (dispatch, state) => {
    const { addToast } = UseAppToast();
    const navigate = useNavigate();
    // let [params, setParams] = useSearchParams();
    const { id } = useParams();

    const minDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so we add 1 and pad with '0' if necessary
        const day = String(today.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    };
    const addSchedule = () => {
        // alert('add')

        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `saveLoading`,
                    value: true,
                },
            ],
        });
        let payload = {
            department_id: state.form.selectedDepartment?.value,
            template_id: state.form.selectedChecklist?.value,
            location_id: state.form.selectedLocation?.map((item) => item.value),
            user_id: state.form.selectedAssignee?.value,
            frequency:
                // state.form.selectedInput == "custom"
                //   ? state.form.selectedCustomFreqInput
                state.form.selectedInput,
            start_date:
                // if custom, get the minimum date
                state.form.selectedInput === "custom"
                    ? new Date(
                          Math.min.apply(
                              null,
                              state.form.custom_selected_dates.map((date) => new Date(date))
                          )
                      )
                    : state.form.start_date,
            end_date:
                state.form.selectedInput === "custom"
                    ? new Date(
                          Math.max.apply(
                              null,
                              state.form.custom_selected_dates.map((date) => new Date(date))
                          )
                      )
                    : state.form.end_date,
            custom_selected_dates: state.form.custom_selected_dates.map((date) => {
                return `${date.year}-${date.month}-${date.day}`;
            }),

            time_frame: state.form.selectedTimeframeInput,
            time_frame_nature: state.form.selectedTimeframeNatureInput,
            start_time: state.form.start_time,
            due_time: state.form.due_time,
            due_on:
                state.form.selectedInput == "weekly" ||
                state.form.selectedCustomFreqInput == "weekly"
                    ? state.form.selectedWeekDay?.value
                    : state.form.due_on,
        };

        // console.log("====================================");
        // console.log(payload);
        // console.log("====================================");

        storeAudit(
            (res) => {
                addToast({
                    type: SUCCESS,
                    description: "Schedule is added successfully.",
                    title: "Success",
                });

                navigate("/portal/schedule");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `saveLoading`,
                            value: false,
                        },
                    ],
                });
            },
            { ...payload }
        );
    };
    const updateSchedule = () => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `saveLoading`,
                    value: true,
                },
            ],
        });
        let payload = {
            department_id: state.selectedDepartment?.value,
            template_id: state.selectedChecklist?.value,
            location_id: state.selectedLocation?.value,
            user_id: state.selectedAssignee?.value,
            id: id,
        };
        updateAudit(
            (res) => {
                addToast({
                    type: SUCCESS,
                    description: "Schedule is updated successfully",
                    title: "Success",
                });
                navigate("/portal/schedule");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `saveLoading`,
                            value: false,
                        },
                    ],
                });
            },
            { ...payload }
        );
    };
    useEffect(() => {
        if (id) {
            dispatch({
                payload: [
                    {
                        type: UPDATE_PROP,
                        prop: `pageLoading`,
                        value: true,
                    },
                ],
            });

            fetchSpecificAudit(
                (res) => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `form.selectedDepartment`,
                                value: {
                                    value: res.data.response.department_id,
                                    label: res.data.response.department_name,
                                },
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.selectedChecklist`,
                                value: {
                                    value: res.data.response.template_id,
                                    label: res.data.response.template_name,
                                },
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.selectedAssignee`,
                                value: {
                                    value: res.data.response.user_id,
                                    label: res.data.response.user_name,
                                },
                            },
                            {
                                type: UPDATE_PROP,
                                prop: `form.selectedLocation`,
                                value: {
                                    value: res.data.response.location_id,
                                    label: res.data.response.location_name,
                                },
                            },
                        ],
                    });
                },
                (error) => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `error`,
                                value: "cannot load this page",
                            },
                        ],
                    });
                    addToast({
                        type: ERROR,
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                },
                () => {
                    dispatch({
                        payload: [
                            {
                                type: UPDATE_PROP,
                                prop: `pageLoading`,
                                value: false,
                            },
                        ],
                    });
                },
                { id: id }
            );
        }
        getAllDepartments(
            (res) => {
                console.log(res.data.response);
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `departments`,
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 100000,
            }
        );
        fetchAllAuditTemplates(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `checklist`,
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 100000,
            }
        );
        fetchAllUsersSchedule(
            (res) => {
                console.log(res.data.response);
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `asignees`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 100000,
            }
        );
        fetchAllLocations(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `locations`,
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 100000,
            }
        );
    }, []);
    useEffect(() => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `selectedWeekDay`,
                    value: null,
                },
                {
                    type: UPDATE_PROP,
                    prop: `selectedTimeframeInput`,
                    value: null,
                },
                {
                    type: UPDATE_PROP,
                    prop: `selectedTimeframeNatureInput`,
                    value: null,
                },
                {
                    type: UPDATE_PROP,
                    prop: `start_time`,
                    value: "",
                },
                {
                    type: UPDATE_PROP,
                    prop: `due_time`,
                    value: "",
                },
                {
                    type: UPDATE_PROP,
                    prop: `start_date`,
                    value: "",
                },
                {
                    type: UPDATE_PROP,
                    prop: `end_date`,
                    value: "",
                },
                {
                    type: UPDATE_PROP,
                    prop: `due_on`,
                    value: "",
                },
            ],
        });
    }, [state.selectedInput, state.selectedCustomFreqInput]);
    return { actions: { addSchedule, navigate, updateSchedule, minDate }, id };
};

export default Logic;
