import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import AppButton from "../../../../components/general/app-button/AppButton";

export const ChecklistsColumn = (
    actions,
    translate = (term) => {
        return term;
    }
) => {
    return [
        {
            Header: `${translate("name")}`,
            accessor: "name",
        },
        {
            Header: `${translate("department")}`,
            accessor: "department_name",
        },
        {
            Header: `${translate("lastModified")}`,
            accessor: "created_at",
        },
        {
            Header: `${translate("actions")}`,
            // className: "frozen",
            // style: {
            //   textAlign: "center",
            // },
            Cell: ({ row: { values, original } }) => (
                <div style={{ display: "flex", justifyContent: "space-evenly", flex: 1 }}>
                    <AppButton
                        classes="buttonIcon"
                        onClick={() => actions.navigate(`/portal/form-builder/${original.id}`)}
                        value={`${translate("edit")}`}
                        style={{
                            color: "#2e5bff",
                        }}
                        icon={<FontAwesomeIcon icon={faEdit} style={{ margin: "0px 5px" }} />}
                    />
                    <AppButton
                        classes="buttonIcon"
                        style={{ color: "#fb3737" }}
                        onClick={() => actions.delete(original.id)}
                        value ={`${translate("delete")}`}
                        icon={<FontAwesomeIcon icon={faTrashAlt} style={{ margin: "0px 5px" }} />}
                    />
                    <AppButton
                        classes="buttonIcon"
                        onClick={() =>
                            actions.navigate(`/portal/form-builder/${original.id}?duplicate=true`)
                        }
                        value ={`${translate("duplicate")}`}
                        style={{
                            color: "#2e5bff",
                        }}
                        icon={<FontAwesomeIcon icon={faClone} style={{ margin: "0px 5px" }} />}
                    />
                </div>
            ),
        },
    ];
};
