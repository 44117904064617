import React from "react";
import styles from "./AppInput.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const AppInput = React.forwardRef(
    (
        {
            onChange,
            containerStyle,
            inputStyle,
            renderProp,
            onClick,
            textarea = false,
            containerClassName,
            ...props
        },
        ref
    ) => {
        const { children, className, ...rest } = props;
        const allstyles = `${styles.input} ${className != undefined ? className : ""} `;
        const [showPassword, setShowPassword] = React.useState(false);

        return (
            <div className={`${styles.container} ${containerClassName}`} style={containerStyle}>
                {children}

                {renderProp ? (
                    renderProp({ onChange })
                ) : textarea ? (
                    <textarea
                        onChange={onChange}
                        {...rest}
                        className={allstyles}
                        style={inputStyle}
                    ></textarea>
                ) : (
                    <>
                        <input
                            ref={ref}
                            onChange={onChange}
                            className={allstyles}
                            style={inputStyle}
                            {...(showPassword ? { ...rest, type: "text" } : { ...rest })}
                            onClick={onClick}
                        />

                        {rest?.type === "password" && (
                            <div onClick={() => setShowPassword(!showPassword)}>
                                <FontAwesomeIcon
                                    icon={showPassword ? faEye : faEyeSlash}
                                    color="gray"
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
);

export default AppInput;
