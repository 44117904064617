import React from "react";
import { NavLink } from "react-router-dom";
import { MenuItem, SubMenu, Menu } from "react-pro-sidebar";
import { useTranslation } from "react-i18next";

const SidebarNav = ({ items, activeClass = "", defaultClass = "" }) => {
    const { t } = useTranslation();
    const navItem = (item, index) => {
        const { name, icon, to } = item;
        return (
            <MenuItem active={true} icon={icon} key={`${name}-${index}`}>
                <NavLink
                    to={to}
                    className={(navData) => (navData.isActive ? activeClass : defaultClass)}
                >
                    {t(name)}
                </NavLink>
            </MenuItem>
        );
    };
    const navGroup = (item, index) => {
        const { name, icon, suffix } = item;
        return (
            <SubMenu title={t(name)} icon={icon} suffix={suffix} key={`${name}-${index}`}>
                {item.items?.map((item, index) =>
                    item.items ? navGroup(item, index) : navItem(item, index)
                )}
            </SubMenu>
        );
    };

    return (
        <Menu>
            {items &&
                items.map((item, index) =>
                    item.items ? navGroup(item, index) : navItem(item, index)
                )}
        </Menu>
    );
};

export default SidebarNav;
