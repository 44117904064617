import styles from "./RadioInput.module.css";
const RadioInput = ({
  name,
  label,
  value,
  isChecked = false,
  handleChange,
  ...props
}) => {
  const { className, ...rest } = props;
  const allstyles = `${styles.container} ${
    className != undefined ? className : ""
  }`;
  const handleRadioChange = (e) => {
    const { value } = e.currentTarget;
    handleChange(value);
  };
  // console.log(value);
  return (
    <div className={allstyles} {...rest}>
      <input
        type="radio"
        className={styles.customRadio}
        name={name}
        checked={isChecked}
        onChange={handleRadioChange}
        value={value}
      />
      <label className={styles.label}>{label}</label>
    </div>
  );
};
export default RadioInput;
