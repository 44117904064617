import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UseAppTable from "../../helpers/hooks/use-app-table";
import {
    fetchSubscription,
    fetchTicketsSummery,
    updateContactPeson,
    fetchAllSettingsData,
} from "../../api/services/SettingsServices";
import UseAppToast from "../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../store/ActionTypes";
import { ERROR, SUCCESS } from "../../components/general/app-toast/AppToast";
import { settingsColumn } from "./settingsColumn";
import { useTranslation } from "react-i18next";
import { extractErrorMessage } from "../../helpers/Common";
// import UseAppToast from "../../../helpers/hooks/use-app-toast";

export const INITIAL_STATE = {
    form: {
        contact_person_name: "",
        job_title: "",
        contact_person_mobile: "",
        contact_person_whatsapp: "",
        contact_person_email: "",
    },
    customer: {
        // companyDetails: {
        //     name: "company",
        //     commReg: "comment",
        //     taxCard: "test",
        //     numberOfEmployee: "test",
        //     sector: "test",
        //     industry: "test",
        //     activeUsers: "test",
        //     addresses: "address",
        // },
        // contactPerson: {
        //     name: "mohamed",
        //     position: "2",
        //     mobile: "0133233",
        //     email: "email@email.com",
        //     whatsapp: "0321313",
        //     website: "website.com",
        // },
    },
    settingsData: {},
    // inquiriesAndtickets: [
    //   {
    //       text: "inquiries",
    //       status: [
    //           { label: "open", number: 150 },
    //           { label: "in progress", number: 150 },
    //           { label: "open", number: 150 },
    //           { label: "open", number: 150 },
    //           { label: "open", number: 150 },
    //       ],
    //   },
    //   {
    //       text: "tickets",
    //       status: [
    //           { label: "open", number: 150 },
    //           { label: "in progress", number: 150 },
    //           { label: "open", number: 150 },
    //           { label: "open", number: 150 },
    //           { label: "open", number: 150 },
    //       ],
    //   },
    // ],
    inquiriesAndtickets: {},
    allTicketsTotal: "",
    saveLoading: false,
    pageLoading: false,
    customerLoading: false,
    ticketsLoading: false,
    error: null,
};
const Logic = (dispatch, state) => {
    const navigate = useNavigate();
    const { addToast } = UseAppToast();
    const { t, i18n } = useTranslation();

    const handleUpdate = () => {
        console.log("update");
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `saveLoading`,
                    value: true,
                },
            ],
        });
        let payload = state.form;

        updateContactPeson(
            (res) => {
                let message = "User is updated successfully";
                addToast({
                    type: SUCCESS,
                    description: message,
                    title: "Success",
                });
                // navigate("/users");
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `saveLoading`,
                            value: false,
                        },
                    ],
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `saveLoading`,
                            value: false,
                        },
                    ],
                });
            },
            {
                ...payload,
            }
        );
    };

    useEffect(() => {
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `customerLoading`,
                    value: true,
                },
            ],
        });
        fetchAllSettingsData(
            (res) => {
                console.log(res);
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `settingsData`,
                            value: res,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: `form.contact_person_name`,
                            value: res?.contact_person_name,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: `form.job_title`,
                            value: res?.job_title,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: `form.contact_person_mobile`,
                            value: res?.contact_person_mobile,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: `form.contact_person_whatsapp`,
                            value: res?.contact_person_whatsapp,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: `form.contact_person_email`,
                            value: res?.contact_person_email,
                        },
                    ],
                });
            },
            (error) => {
                console.log(error);
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `error`,
                            value: "cannot load this page",
                        },
                    ],
                });
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `customerLoading`,
                            value: false,
                        },
                    ],
                });
            }
        );
    }, []);

    return {
        actions: { handleUpdate, navigate },
    };
};

export default Logic;
