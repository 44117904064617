import classes from "./Title.module.css";

const Title = ({
  children,
  title,
  renderProp,
  titleStyle = "",
  border = "",
  ...rest
}) => {
  return (
    <div
      className={`${border && classes.border} ${classes.titleContainer}`}
      {...rest}
    >
      <div className={classes.titleFilter}>
        {title && (
          <div className={`${classes.title} ${titleStyle}`}>{title}</div>
        )}
        {renderProp && renderProp}
      </div>

      {children}
    </div>
  );
};

export default Title;
